import React, { Component } from 'react';
import { Row, Col, Descriptions } from 'antd';

export default class ContentModal extends Component {
  render() {
    const { valueModalEdit } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: 20 }}>
          <Descriptions layout="vertical" size="middle" column={2}>
            <Descriptions.Item label="Course">{valueModalEdit.courseCode} - {valueModalEdit.courseName}</Descriptions.Item>
            <Descriptions.Item label="Category">{valueModalEdit.courseCategory === 'GENERAL_COURSE' ? 'Mata Kuliah Umum' : 'Mata Kuliah Jurusan'}</Descriptions.Item>
            <Descriptions.Item label="SKS">{valueModalEdit.courseSks} SKS</Descriptions.Item>
            <Descriptions.Item label="Semester">{valueModalEdit.courseSemester}</Descriptions.Item>
            <Descriptions.Item label="Lecture">{valueModalEdit.lecturerFrontTitle ? `${valueModalEdit.lecturerFrontTitle} ` : ''}{valueModalEdit.lecturerFirstName} {valueModalEdit.lecturerLastName}{valueModalEdit.lecturerBackTitle ? ` ${valueModalEdit.lecturerBackTitle}` : ''}</Descriptions.Item>
            <Descriptions.Item label="Score">{valueModalEdit.score ? valueModalEdit.score : 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Notes">{valueModalEdit.note ? <div dangerouslySetInnerHTML={{ __html: valueModalEdit.note }}></div> : 'N/A'}</Descriptions.Item>
          </Descriptions>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
