import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 60,
      render: (record) => (
        <Link className="link" to={`/academic/detail/${record.id}`}>
          {record.number}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.firstName} {record.lastName}
        </Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.institutionName}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email}
        </Text>
      )
    },
    {
      title: 'Mobile',
      key: 'mobile',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          +62{record.mobileNumber}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 20,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    }
  ]
}