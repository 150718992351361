const LOAD_DETAIL_FOLLOWED_PROGRAM           = 'LOAD_DETAIL_FOLLOWED_PROGRAM'
const LOAD_DETAIL_FOLLOWED_PROGRAM_SUCCESS   = 'LOAD_DETAIL_FOLLOWED_PROGRAM_SUCCESS'
const LOAD_DETAIL_FOLLOWED_PROGRAM_FAILED    = 'LOAD_DETAIL_FOLLOWED_PROGRAM_FAILED'
const UNMOUNT_DETAIL_FOLLOWED_PROGRAM        = 'UNMOUNT_DETAIL_FOLLOWED_PROGRAM'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const applicantsFollowedProgramReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_FOLLOWED_PROGRAM:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_FOLLOWED_PROGRAM_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_FOLLOWED_PROGRAM_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_FOLLOWED_PROGRAM:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default applicantsFollowedProgramReducer;