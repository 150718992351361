import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Typography } from 'antd';
import { programCategory } from '../../../utils/constant/programCategory';

const { Text } = Typography;

export const columns = (onClickEdit, handleActive, handleInactive, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/academic/programs/detail/${record.id}/information`}>
          {record.programNumber}
        </Link>
      )
    },
    {
      title: 'Title',
      key: 'title',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.title}
        </Text>
      )
    },
    {
      title: 'Category',
      key: 'category',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.programCategory ? programCategory.find(item => item.value === record.programCategory)?.name : '-'}
        </Text>
      )
    },
    {
      title: 'Partner',
      key: 'job Position',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.partnerName ? record.partnerName : '-'}
        </Text>
      )
    },
    {
      title: 'Close Reg. Date',
      key: 'CloseRegDate',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.closeDate ? record.closeDate : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {record.status === 'ACTIVE' ? <Tag color="green"> Active</Tag> : null}
          {record.status === 'INACTIVE' ? <Tag color="orange"> Inactive</Tag> : null}
          {record.status === 'CLOSED' ?  <Tag color="red">Close</Tag> : null}
          {record.status === 'DRAFT' ? <Tag> Draft</Tag> : null}
        </React.Fragment>
      )
    },
  ]
}