import { API } from '../../../../../config';
import { errorHandler } from '../../../auth/errorAction';

export const unmountListPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_POSITIONS'})
}

export const unmountDetailPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_POSITIONS'})
}

export const unmountMasterPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_POSITIONS'})
}

export const listPositions = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_POSITIONS' })
  return API.GET('/positions/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_POSITIONS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_POSITIONS_FAILED' }), 
    ))
  })
}

export const detailPosition = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_POSITIONS' })
  const params = {
    'id': id
  }
  API.GET('/positions/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_POSITIONS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_POSITIONS_FAILED' }), 
      ))
    )
  })
}

export const masterPositions = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_POSITIONS' })
  return API.GET('/positions/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_POSITIONS_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_POSITIONS_FAILED' }), 
    ))
  })
}

export const positionsByIndustries = (id, successCB, failedCB) => async (dispatch) => {
  const params = { 'industries': id }
  await dispatch({ type: 'LOAD_DATA_POSITION_BY_INDUSTRIES' })
  return API.GET('/positions/getByIndustries', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_POSITION_BY_INDUSTRIES_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_POSITION_BY_INDUSTRIES_FAILED' }), 
    ))
  })
}

export const positionsByIndustriesGroup = (id, successCB, failedCB) => async (dispatch) => {
  const params = { 'industries': id }
  return API.GET('/positions/getByIndustries/group', params).then((response) => {
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
    ))
  })
}

export const createPosition = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/positions/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editPosition = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/positions/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enablePosition = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/positions/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disablePosition = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/positions/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}