import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col, Form, Select, InputNumber, Space, Button, Descriptions } from 'antd';

export default class ContentModal extends Component {
  render() {
    const { formRef, valueModalEdit, onFinish, submitLoading, getData } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" size="middle" column={2}>
              <Descriptions.Item label="Course">{valueModalEdit.courseCode} - {valueModalEdit.courseName}</Descriptions.Item>
              <Descriptions.Item label="Category">{valueModalEdit.courseCategory === 'GENERAL_COURSE' ? 'Mata Kuliah Umum' : 'Mata Kuliah Jurusan'}</Descriptions.Item>
              <Descriptions.Item label="SKS">{valueModalEdit.courseSks} SKS</Descriptions.Item>
              <Descriptions.Item label="Semester">{valueModalEdit.courseSemester}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Form
              ref={formRef}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item 
                    label="Lecturer" 
                    name="participantLecturerId"
                    initialValue={valueModalEdit.participantLecturerId ? valueModalEdit.participantLecturerId : null}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <Select placeholder="Select a lecturer" onChange={this.handleChangePosition}>
                      {
                        getData?.listLecturer?.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              {
                                <Select.Option key={i} value={item.id}>
                                  {`${item.lecturerFrontTitle ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}`}
                                </Select.Option>
                              }
                            </React.Fragment>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    label="Score" 
                    name="score"
                    initialValue={valueModalEdit.score ? Number(valueModalEdit.score) : null}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { pattern: /^[0-9]*$/, message: 'Can only use numbers' },
                    ]}
                  >
                    <InputNumber max={100} placeholder='Input Score here from 0 - 100' />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    label="Note" 
                    name="note"
                    initialValue={valueModalEdit.note ? valueModalEdit.note : null}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      // { min: 3, message: 'At least 3 characters long' },
                      // { max: 200, message: '200 characters only' },
                    ]}
                  >
                    <ReactQuill placeholder="Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Space style={{ float: 'right' }}>
                      <Button htmlType="submit" type="primary" loading={submitLoading}>{valueModalEdit.status === "CONFIRMED" ? 'Update' : "Confirm"}</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
