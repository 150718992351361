import React, { Component } from 'react';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select, DatePicker, Tooltip, AutoComplete } from 'antd';
const { RangePicker } = DatePicker;

export default class HeaderTable extends Component {

  handleRangePicker = (dates, dateStrings) => {
    const { onRegistered } = this.props;
    const date = {
      'startDate': dateStrings[0],
      'endDate': dateStrings[1]
    }
    return onRegistered(date)
  }

  handleChangeStatus = (e) => {
    const { onStatus } = this.props;
    return onStatus(e)
  }

  handleChangeCategory = (e) => {
    const { onCategory } = this.props;
    return onCategory(e)
  }

  handleChangeType = (e) => {
    const { onTypeAccount } = this.props;
    return onTypeAccount(e)
  }

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }

  render() {
    const { fase1, fase2, category, tooltipDate, tooltipStatus, tooltipCategory, placeholder } = this.props;
    return (
      <React.Fragment>
        <Row gutter={8}>
          <Tooltip title={tooltipDate}>
            <Col span={5}>
              <RangePicker
                onChange={this.handleRangePicker}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }}
              />
            </Col>
          </Tooltip>
          {
            fase2 &&
            <Col span={4}>
              <Tooltip title="Filter by Category">
                <Select
                  defaultValue={fase2.value[0].value}
                  onChange={this.handleChangeType}
                  placeholder={fase2.key}
                  style={{ width: '100%' }}
                >
                  {fase2.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            category &&
            <Col span={4}>
              <Tooltip title={tooltipCategory}>
                <Select
                  defaultValue={category.value[0].value}
                  onChange={this.handleChangeCategory}
                  placeholder={category.key}
                  style={{ width: '100%' }}
                >
                  {category.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            fase1 &&
            <Col span={4}>
              <Tooltip title={tooltipStatus}>
                <Select
                  defaultValue={fase1.value[0].value}
                  onChange={this.handleChangeStatus}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            this.props.event &&
            <Col span={4}>
              <Tooltip title={this.props.tooltipCategory}>
                <Select
                  defaultValue={fase1.valueCategory[0].value}
                  onChange={this.handleChangeCategory}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.valueCategory.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          <Col span={8}>
            <AutoComplete 
              onSearch={this.handleChangeSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder={placeholder}  />
            </AutoComplete>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
