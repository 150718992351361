import { API } from '../../../../../config';
import { errorHandler } from '../../../auth/errorAction';

export const unmountListDocumentType = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_DOCUMENT_TYPE'})
}

export const unmountDetailDocumentType = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_DOCUMENT_TYPE'})
}

export const unmountMasterDocumentType = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_DOCUMENT_TYPE'})
}

export const listDocumentTypes = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_DOCUMENT_TYPE' })
  return API.GET('/document-type/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_DOCUMENT_TYPE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_DOCUMENT_TYPE_FAILED' }), 
    ))
  })
}

export const detailDocumentType = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_DOCUMENT_TYPE' })
  const params = {
    'id': id
  }
  API.GET('/document-type/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_DOCUMENT_TYPE_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_DOCUMENT_TYPE_FAILED' }), 
      ))
    )
  })
}

export const masterDocumentTypes = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE' })
  return API.GET('/document-type/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE_FAILED' }), 
    ))
  })
}

export const createDocumentType = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/document-type/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateDocumentType = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/document-type/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableDocumentType = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/document-type/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableDocumentType = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/document-type/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}
