import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';
const { Text } = Typography;

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      render: (record) => (
        <Link className="link" to={`/academic/participants/detail/${record.id}`}>
          {record.participantNumber}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.applicantName}
        </Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.institutionName}
        </Text>
      )
    },
    {
      title: 'Field of Study',
      key: 'fieldOfStudy',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.fieldOfStudyName}
        </Text>
      )
    },
    {
      title: 'Followed Program',
      key: 'followedProgram',
      width: 100,
      render: (record) => (
        record.programNumber ?
          <Link className="link" to={`/lecturers/programs/detail/${record.programId}/information`}>
            {record.programNumber} - {record.programTitle}
          </Link>
        :
          '-'
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
          {
            record.participantStatus === 'ACTIVE' ? 
              <Tag color="green" style={{ width: '100%' }}>Active</Tag>
            : 
              <Tag color="blue" style={{ width: '100%' }}>Done</Tag>
          }
        </React.Fragment>
      )
    }
  ]
}