import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';
// import { EditOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

// const menu = (record, onClickEdit, handleEnable, handleDisable) => (
//   <Menu>
//     <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
//       Edit                              
//     </Menu.Item>
//     {
//       record.enabled ?
//       <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
//         Set as inactive
//       </Menu.Item>
//       :
//       <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
//         Set as active
//       </Menu.Item>
//     }
//   </Menu>
// );

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 120,
      render: (record) => (
        <Link className="link" to={`/partner/participant/detail/${record.applicantId}`}>
          {record.participantNumber}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 120,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.applicantName}
        </Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.institutionName ? record.institutionName : '-'}
        </Text>
      )
    },
    {
      title: 'Field of Study',
      key: 'fieldOfStudy',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.fieldOfStudyName ? record.fieldOfStudyName : '-'}
        </Text>
      )
    },
    {
      title: 'Followed Program',
      key: 'followedProgram',
      width: 150,
      render: (record) => (
        <Link className="link" to={`/partner/my-program/detail/${record.programId}/information`}>
           {record.programNumber ? record.programNumber : '-'} - {record.programTitle ? record.programTitle : '-'}
        </Link>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'DONE'? 
            <Tag color='blue'>Done</Tag>
            :
            <Tag color='green'>Active</Tag>
          }
        </React.Fragment>
      )
    },
    
  ]
}