import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.status === "PUBLISHED" ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as Unpublished
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as Published
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Published Date',
      key: 'publishedDate',
      // fixed: 'left',
      width: 50,
      render: (record) => (
        <Link className="link" to={`/news/detail/${record.id}`}>
          {record.publishedDate ? moment(record.publishedDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null} {record.publishedTime ? moment(record.publishedTime, 'hh:mm:ss').format('hh:mm:ss') : null}
          {/* {record.publishedDate, } {record.publishedTime} */}
        </Link>
      )
    },
    {
      title: 'Title',
      key: 'title',
      // fixed: 'left',
      sorter: (a, b) => a.title - b.title,
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'title' && sortedInfo.order : null,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          <Tooltip title={record.title}>
            {record.title}
          </Tooltip>
        </Text>
      )
    },
    {
      title: 'Category',
      key: 'category',
      width: 40,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.newsCategory === "NEWS" ? "News" : "Announcement"}
        </Text>
      )
    },
    {
      title: 'Comment',
      key: 'comment',
      width: 50,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.isComment ? 'Yes' : 'No'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {
            record.status === "PUBLISHED" ? 
            <Tag color="green">Published</Tag>
            : 
            <Tag color="red">Unpublished</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 10,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}