import React, { Component } from 'react';
import debounce from 'lodash/debounce';
// import ReactToPrint from 'react-to-print';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ViewProfile from '../../Profile';
import HeaderDetailPrograms from '../../Header';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Loading, SearchDetailJob } from '../../../../../../components';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Modal, message, Table, Pagination, Button } from 'antd';
import { detailPrograms, listApplicant, participantReview, rejectApplicant, detailParticipant, unmountListApplicant, unmountDetailPrograms, unmountDetailParticipant } from '../../../../../../redux/actions/admin/programs/programsAction';
import { detailApplicants, unmountDetailApplicants } from '../../../../../../redux/actions/admin/applicants/applicantsAction';
import MenuDetail from '../../MenuDetail';
import MenuPhase from '../MenuPhase';
// import ApplicantNotes from '../../ApplicantNotes';
const { confirm } = Modal;
const { Text } = Typography;

class DetailAcceptedPrograms extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      checked: true,
      visible: false,
      isModalReject: false,
      selectedRowKeys: [],
      idApplicant: null,
      documentName: null,
      isModalNotes: false,
      meta: {
        page: 1,
        perpage: 10,
        programId: '',
        status: 'ACCEPTED'
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionListApplicant, actionGetDetail, match: { params } } = this.props;
    meta.programId = Number(params.id)
    actionListApplicant(meta)
    actionGetDetail(params.id)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.key) {
      case "applied":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/applied`);
      case "review":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/review`);
      case "accepted":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/accepted`);
      default:
        break;
    }
  }

  modalParticipantReview = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionParticipantReview, actionListApplicant } = this.props;
    const idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys.join() : id
    confirm({
      title: 'The application will be processed to review.',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      width: 500,
      onOk() {
        return actionParticipantReview(idApplicant, () => {
          message.success('Successfully move to review')
          actionListApplicant(meta)
          self.setState({ selectedRowKeys: [] })
        }, (err) => message.error(err))
      }
    });
  }

  showModal = (id) => {
    this.setState({ isModalReject: true, idApplicant: id })
  };

  showModalNotes = (id) => {
    const { actionListApplicantNotes } = this.props
    actionListApplicantNotes(id)
    this.setState({ isModalNotes: true, idApplicant: id })
  };
  
  handleCancel = () => {
    this.setState({ isModalReject: false, isModalNotes: false })
  };

  onFinish = value => {
    // const { meta, selectedRowKeys, idApplicant } = this.state;
    const { meta, idApplicant } = this.state;
    const { actionReject, actionListApplicant } = this.props;
    // const _idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys : [idApplicant]

    // value.id = _idApplicant
    value.id = idApplicant

    return actionReject(value, () => {
      message.success('Successfully reject')
      actionListApplicant(meta)
      this.setState({ selectedRowKeys: [], isModalReject: false })
    }, (err) => message.error(err))
  }

  onFinishNotes = value => {
    const { meta, url, idApplicant } = this.state
    const { actionAddApplicantNotes, actionListApplicantNotes, actionListApplicant } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionListApplicant(meta, url)
    }, (err) => message.error(err))
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionListApplicant(meta)
  }

  onFilter = value => {
    const { meta, url } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.gender = value.gender
    meta.maxAge = value.maxAge
    meta.minAge = value.minAge
    return actionListApplicant(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionListApplicant(meta, url)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onPriviewProfile = (applicantId, id) => {
    const { actionGetProfile, actionDetailParticipant } = this.props;
    return actionGetProfile(applicantId, () => {
      actionDetailParticipant(id)
      this.setState({ visible: true })
    })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }
  
  render() {
    // const { selectedRowKeys, checked, visible, isModalReject, isModalNotes } = this.state;
    const { checked, visible } = this.state;
    const { getData: { data, loading }, getApplicant } = this.props;

    if(loading){
      return <Loading />
    }
    
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };

    // const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Programs</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/programs">Programs List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.programNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Program Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailPrograms 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <MenuDetail rowKey="applicants" id={data.id} />
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <MenuPhase rowKey="accepted" data={data} handleMenuPhase={(item) => this.handleMenuPhase(item)} />
                </Col>
                <Col span={24}>
                  <SearchDetailJob onSearch={this.onSearch} />
                </Col>
                {/* Table */}
                <Col span={24}>
                  <Table 
                    size="small"
                    style={{ marginBottom: 20 }}
                    rowKey={(i) => i.id} 
                    dataSource={getApplicant.data} 
                    loading={getApplicant.loading} 
                    columns={columns(this.onPriviewProfile, this.modalParticipantReview, this.showModal, this.showModalNotes)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                    // rowSelection={rowSelection}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getApplicant.pagination.total}
                    onChange={this.pagination}
                    current={getApplicant.pagination.page}
                    pageSize={getApplicant.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Detail"
          onCancel={this.onCancel}
          footer={<Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>}
        >
          <ViewProfile {...this.props} />
          {/* <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <div ref={(response) => (this.componentRef = response)}>
            </div>
          </div> */}
        </Modal>
        {/* <Modal title="Applicant Rejection" visible={isModalReject} onCancel={this.handleCancel} footer={null} destroyOnClose>
          <Form
            layout="vertical"
            onFinish={this.onFinish}
          >
            <Form.Item
              label="Reason"
              name="rejectReason"
              initialValue={null}
              rules={[{ required: true, message: 'Please input your reject notes!' }]}
            >
              <ReactQuill />
            </Form.Item>

            <Form.Item>
              <Button type="danger" htmlType="submit" style={{ float: 'right' }}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal> */}
        {/* <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.handleCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal> */}
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountListApplicant, unmountDetailParticipant, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountListApplicant()
    unmountDetailParticipant()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData               : state.programs.detail,
  getApplicant          : state.programs.applicant,
  getProfile            : state.applicants.detail,
  getDetailParticipant  : state.programs.detailParticipant
})

const mapDispatchToProps = {
  actionGetDetail           : detailPrograms,
  actionListApplicant       : listApplicant,
  actionParticipantReview   : participantReview,
  actionReject              : rejectApplicant,
  actionGetProfile          : detailApplicants,
  actionDetailParticipant   : detailParticipant,
  unmountGetDetail          : unmountDetailPrograms,
  unmountListApplicant      : unmountListApplicant,
  unmountDetailParticipant  : unmountDetailParticipant,
  unmountGetProfile         : unmountDetailApplicants,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailAcceptedPrograms)
