import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListLecturers = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_LECTURERS'})
}

export const unmountDetailLecturers = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_LECTURERS'})
}

export const unmountMasterLecturers = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_LECTURERS'})
}

export const unmountDetailSupervisingLecturers = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_SUPERVISING_LECTURERS'})
}


export const listLecturers = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_LECTURERS' })
  return API.GET(`/lecturer/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_LECTURERS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_LECTURERS_FAILED' }), 
    ))
  })
}

export const detailLecturers = (id, successCB, failedCB) => async dispatch => {
  const params = {
    'id': id
  }
  API.GET(`/lecturer/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_LECTURERS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_LECTURERS_FAILED' }), 
      ))
    )
  })
}

export const detailSupervisingLecturers = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_SUPERVISING_LECTURERS' })
  const params = {
    'id': id
  }
  API.GET(`/participant/getBylecturerId`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_SUPERVISING_LECTURERS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_SUPERVISING_LECTURERS_FAILED' }), 
      ))
    )
  })
}

export const masterLecturers = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_LECTURERS' })
  return API.GET('/lecturer/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_LECTURERS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_LECTURERS_FAILED' }), 
    ))
  })
}

export const updateAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/update/avatar', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateBiodata = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/update/biodata', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateContact = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/update/contact', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSocial = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/social-media/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSocial = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/social-media/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateJobPreference = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/update/job-preference', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/work-experience/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/work-experience/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteWorkExperience = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/work-experience/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/education/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/education/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteEducation = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/education/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/training/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/training/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/training/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSkill = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/skill/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSkill = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/skill/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addPortfolio = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/portfolio/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deletePortfolio = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/portfolio/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/achievement/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/achievement/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteAchievement = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/achievement/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/document/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/document/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteDocument = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/document/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updatePassword = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/lecturer/update/password', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const createLecturer = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editLecturer = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableLecturers = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableLecturers = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/lecturer/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}