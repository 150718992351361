import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components';
import { UserOutlined } from '@ant-design/icons';
import {PageHeader, Card, Row, Col, Descriptions, Typography, Breadcrumb, Image } from 'antd';
import { dataProfile, unmountDataProfile } from '../../../redux/actions/partners/profile/profileAction';
const { Text } = Typography;

class MyProfile extends Component {

  componentDidMount() {
    const { actionGetDetail } = this.props;
    actionGetDetail()
  }
  
  render() {
    const { getData: { data, loading }} = this.props;
    if(loading ){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> My Profile</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/partner/my-profile">{data.name ? data.name : '-'}</Link>
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item>Detail</Breadcrumb.Item> */}
              {/* <Breadcrumb.Item>{data.number ? data.number : '-'}</Breadcrumb.Item> */}
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='My Profile' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    
                    <Descriptions.Item label={<Text type="secondary">Name</Text>}>
                     {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Email</Text>}>
                     {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                     {data.contactPerson ? data.contactPerson : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                     {data.cpMobile ? data.cpMobile : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Industry</Text>}>
                     {data.industriesName ? data.industriesName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Speacialties</Text>}>
                     {data.specialties ? data.specialties : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Office Phone Number</Text>}>
                     {data.officePhone ? data.officePhone : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">country</Text>}>
                     {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">province</Text>}>
                     {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                     {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                     {data.streetAddress ? data.streetAddress : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                     {data.postalCode ? data.postalCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Size</Text>}>
                      {
                        data.companySize ?
                          data.companySize === 'LARGE' ? 
                            'Large'
                          : data.companySize === 'MEDIUM' ? 
                            'Medium'
                          : data.companySize === 'SMALL' ? 
                            'Small'
                          : null
                        :
                          'N/A'
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Website</Text>}>
                     {data.website ? data.website : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                     {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Logo</Text>}>
                    {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
          </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.partnersProfile.data,
})

const mapDispatchToProps = {
  actionGetDetail   : dataProfile,
  unmountGetDetail  : unmountDataProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
