import React, { Component } from 'react';
import moment from 'moment'
import { Form, Row, Col, Input, Select, Spin, DatePicker, Typography, Checkbox } from 'antd';
const { Text } = Typography;

export default class UpdateDocument extends Component {
  render() {
    const { 
      formRef, 
      getDocumentTypes,
      isDate,
      current, 
      onSubmitAdd, 
      handleChecked,
      onChangeEndDate,
      onChangeStartDate,
      uploadDocument,
      dataDocuments
    } = this.props;

    return (
      <React.Fragment>
        <Form 
          ref={formRef} 
          onFinish={onSubmitAdd} 
          layout="vertical"
          initialValues={{
            documentTypeId: dataDocuments.documentTypeId,
            documentNumber: dataDocuments.documentNumber ? dataDocuments.documentNumber : "",
            issueDate: dataDocuments.issueDate ? moment(dataDocuments.issueDate, 'MM/YYYY') : "",
            expirationDate: dataDocuments.expirationDate ? moment(dataDocuments.expirationDate, 'MM/YYYY') : "",
            remark: dataDocuments.remark ? dataDocuments.remark : ""
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Document Type"
                name="documentTypeId"
                validateFirst
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Select
                  loading={getDocumentTypes.loading}
                  notFoundContent={getDocumentTypes.loading ? <Spin size="small" /> : null}
                >
                  {
                    getDocumentTypes.data && getDocumentTypes.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Document Number"
                name="documentNumber" 
                validateFirst
                rules={[
                  { max: 50, message: '50 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="current">
                <Checkbox onChange={handleChecked} checked={current}>This document does not expire</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Issue Date" 
                name="issueDate"
              >
                <DatePicker 
                  picker="month" 
                  placeholder="YYYY-MM" 
                  style={{ width: '100%' }}
                  onChange={onChangeStartDate} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Expiration Date" 
                name="expirationDate"
                validateStatus={isDate && !current ? "error" : null}
                help={isDate && !current ? "Your end date can't be earlier than your start date" : null}
              >
                {
                  current ?
                    <Text className="fs-12" type="secondary">No Expiration Date</Text>
                  :
                    <DatePicker 
                      picker="month" 
                      placeholder="YYYY-MM" 
                      style={{ width: '100%' }}
                      onChange={onChangeEndDate} 
                    />
                }
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="documentFile">
                {uploadDocument}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Remarks"
                name="remark"
                validateFirst
                rules={[
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
