const LOAD_PROGRAMS_DETAIL_PARTICIPANT           = 'LOAD_PROGRAMS_DETAIL_PARTICIPANT'
const LOAD_PROGRAMS_DETAIL_PARTICIPANT_SUCCESS   = 'LOAD_PROGRAMS_DETAIL_PARTICIPANT_SUCCESS'
const LOAD_PROGRAMS_DETAIL_PARTICIPANT_FAILED    = 'LOAD_PROGRAMS_DETAIL_PARTICIPANT_FAILED'
const UNMOUNT_PROGRAMS_DETAIL_PARTICIPANT        = 'UNMOUNT_PROGRAMS_DETAIL_PARTICIPANT'

const initialState = {
  loading: true,
  data: null,
  message: null
}

const programsDetailParticipantReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_PROGRAMS_DETAIL_PARTICIPANT:
      return {
        ...state,
        loading: true,
        data: null,
        pagination: {
          page: 1,
          total: 0,
          perpage: 10
        }
      }
    case LOAD_PROGRAMS_DETAIL_PARTICIPANT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS'
      }
    case LOAD_PROGRAMS_DETAIL_PARTICIPANT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED'
      }
    case UNMOUNT_PROGRAMS_DETAIL_PARTICIPANT:
      return { 
        ...state, 
        loading: true,
        data: null
      }
    default:
      return state
  }
}

export default programsDetailParticipantReducer;