import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';
import { Loading } from '../../../../components';
import { UploadOutlined, FileTextFilled } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { masterIndustries, unmountMasterIndustries } from '../../../../redux/actions/admin/master/industries/industriesAction';
import { Card, Row, Col, PageHeader,DatePicker, Form, Input, Upload,Typography, Button, Space, message, Breadcrumb } from 'antd';
import { detailFile, editFile, unmountDetailFile } from '../../../../redux/actions/admin/file/fileAction';


class EditFile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      file: null,
      editFile: false,
      submitLoading: false,
      fileList: [
        {
          uid: '1',
          name: null,
          url: null
        }
      ]
    }
  }
  
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      if(response.data.fileAttachment){
        this.setState({ 
          fileList: [
            {
              uid: '1',
              name: response.data.fileAttachment,
              url: response.data.fileUrl
            }
          ] 
        })
      }
    })
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5 MB!');
          this.setState({ 
            editFile: false
          })
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            file: file, 
            editFile: true,
            fileList: [
              {
                uid: file.uid,
                name: file.name
              }
            ]
          })
        }
        return false;
      },
    }
  }

  onFinish = (values) => {
    const { file } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    const time = moment(values.publishedDate).format('DD/MM/YYYY HH:mm:ss')
    values.publishedDate = values.publishedDate ? moment(values.publishedDate).format('DD/MM/YYYY') : null
    values.publishedTime = values.publishedDate ? moment(time, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss') : null
    values.enabled = data.enabled
    values.id = data.id
    if(file){
      values.file = file
    }
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/file-management')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { getData: { data, loading } } = this.props;
    const { submitLoading, editFile, fileList } = this.state;
    
    if(loading ){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><FileTextFilled /> File Management</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/file">File List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.name ? data.name : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Update a File" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                <Col span={24} style={{ marginBottom: 32 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Course Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="File Name" 
                      name="name" 
                      validateFirst
                      initialValue={data.name}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'File Name must be at least 3 characters long' },
                        { max: 50, message: 'File Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="File Attachment">
                      <Space>
                        <Upload {...this.handleUpload()} showUploadList={true} fileList={fileList} maxCount={1}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editFile ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Published Date"
                      name="publishedDate"
                      initialValue= {moment(`${data.publishedDate} ${data.publishedTime}`, 'DD/MM/YYYY HH:mm:ss' )}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        showTime 
                        format={'DD/MM/YYYY HH:mm:ss'}
                        style={{ width: '100%' }} 
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="About" 
                      name="description"
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 1000, message: '1000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetCountry, unmountGetDetail } = this.props;
    unmountGetDetail()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getData     : state.file.detail,
  getIndustries  : state.industries.master,
  getCountry  : state.countries.master
})

const mapDispatchToProps = {
  actionUpdate        : editFile,
  actionCheckEmail    : checkEmail,
  actionCheckPhone    : checkPhone,
  actionGetDetail     : detailFile,
  actionGetIndustries   : masterIndustries,
  actionGetCountry    : masterCountries,
  actionGetProvince   : provinceByCountry,
  actionGetCity       : cityByProvince,
  unmountGetIndustries  : unmountMasterIndustries,
  unmountGetCountry   : unmountMasterCountries,
  unmountGetDetail    : unmountDetailFile
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFile)