import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListCourses = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_COURSES'})
}

export const unmountDetailCourses = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_COURSES'})
}

export const unmountMasterCourses = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_COURSES'})
}

export const listCourses = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_COURSES' })
  return API.GET('/course/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_COURSES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_COURSES_FAILED' }), 
    ))
  })
}

export const detailCourses = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_COURSES' })
  const params = {
    'id': id
  }
  API.GET('/course/getById/', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_COURSES_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_COURSES_FAILED' }), 
      ))
    )
  })
}

export const masterCourses = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_COURSES' })
  return API.GET('/course/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_COURSES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_COURSES_FAILED' }), 
    ))
  })
}

export const createCourse = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/course/add', value, 'createCourse').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editCourse = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/course/update', value, 'createCourse').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableCourse = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/course/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableCourse = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/course/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}