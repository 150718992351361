import axios from 'axios';
import { env } from './index';
import Cookie from 'js-cookie';

export const GET = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const GET_DOWNLOAD = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload,
      responseType: 'blob'
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const POST_LOGIN = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_LOGIN_NO_WITH_BEARER = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_WITH_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_NO_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_FORM_DATA = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [val, name] of Object.entries(payload)) {
      data.append(val, name);
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}

export const POST_FORM_DATA_ARRAY = (path, payload, type) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [key, name] of Object.entries(payload)) {
      if(type === 'createProgram'){
        if(name.id){
          data.append('id', name.id);
        }
        data.append('programCategory', name.programCategory);
        data.append('title', name.title);
        data.append('partnerId', name.partnerId);
        data.append('creditHour', name.creditHour);
        data.append('programLevel', name.programLevel);
        if(name.countryId){
          data.append('countryId', name.countryId);
        }
        if(name.provinceId){
          data.append('provinceId', name.provinceId);
        }
        data.append('cityId', name.cityId);
        data.append('openDate', name.openDate);
        data.append('closeDate', name.closeDate);
        data.append('startDate', name.startDate);
        data.append('endDate', name.endDate);
        data.append('positionNumber', name.positionNumber);
        data.append('hasCertificate', name.hasCertificate);
        data.append('isGroup', name.isGroup);
        data.append('status', name.status);
        if(name.programDescription){
          data.append('programDescription', name.programDescription);
        }
        if(name.programProcedure){
          data.append('programProcedure', name.programProcedure);
        }
        if(name.programConversion){
          data.append('programConversion', name.programConversion);
        }
        if(name?.listDocument?.length > 0){
          name.listDocument.map((item, i) =>
            item.id ? data.append(`listDocument[${i}].id`, item.id) : null
          )
          name.listDocument.map((item, i) =>
            data.append(`listDocument[${i}].name`, item.name)
          )
        }
        if(name.programRequirement){
          data.append('programRequirement', name.programRequirement);
        }
        if(name.positionId){
          name.positionId.map((item, i) =>
            data.append(`listInternship[${i}].positionId`, item.positionId)
          )
        }
        if(name.fieldOfStudyId){
          name.fieldOfStudyId.map((item, i) =>
            data.append(`listExchange[${i}].fieldOfStudyId`, item.fieldOfStudyId)
          )
        }
        if(name?.listInternship?.length > 0){
          name.listInternship.map((item, i) =>
            data.append(`listInternship[${i}].positionId`, item.positionId)
          )
        }
        if(name?.listExchange?.length > 0){
          name.listExchange.map((item, i) =>
            data.append(`listExchange[${i}].fieldOfStudyId`, item.fieldOfStudyId)
          )
        }
        if(name?.listAttachment?.length > 0){
          name.listAttachment.map((item, i) => 
            item.id ? data.append(`listAttachment[${i}].id`, item.id) : null
          )
          name.listAttachment.map((item, i) => 
            item.name ? data.append(`listAttachment[${i}].name`, item.name) : null
          )
          name.listAttachment.map((item, i) => 
            item.file ? data.append(`listAttachment[${i}].file`, item.file.file) : null
          )
          name.listAttachment.map((item, i) => 
            data.append(`listAttachment[${i}].isDelete`, item.isDelete ? item.isDelete : false)
          )
        }
        if(name.imageFile){
          data.append('imageFile', name.imageFile.file);
        }
      }else if(type === 'createCourse'){
        if(name.id){
          data.append('id', name.id);
        }
        if(name.code){
          data.append('code', name.code);
          data.append('name', name.name);
          data.append('category', name.category);
          data.append('sks', name.sks);
          data.append('semester', name.semester);
          data.append('enabled', name.enabled);
          if(name.description){
            data.append('description', name.description);
          }
          if(name.rpsAttachmentFile){
            data.append('rpsAttachmentFile', name.rpsAttachmentFile);
          }
          if(name?.listCourseCpmk?.length > 0){
            name.listCourseCpmk.map((item, i) =>
              data.append(`listCourseCpmk[${i}].component`, item.component )
            )
          }
        }
      }else{
        data.append('data', key)
      }
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}

// export const POST_FORM_DATA_FILE = (path, payload, type) => {
//   const getToken = Cookie.get('user');
//   return new Promise((resolve, reject) => {
//     const data = new FormData();
//     for (const [key, name] of Object.entries(payload)) {
//       if(type === 'createFile'){
//         if(name.id){
//           data.append('id', name.id);
//         }
//         data.append('fileName', name.name);
//         data.append('publishedDate', name.publishedDate);
//         data.append('about', name.about);
//         if(name?.listAttachment?.length > 0){
//           name.fileAttachment.map((item, i) => 
//             item.id ? data.append(`fileAttachment[${i}].id`, item.id) : null
//           )
//           name.fileAttachment.map((item, i) => 
//             item.name ? data.append(`fileAttachment[${i}].name`, item.name) : null
//           )
//           name.fileAttachment.map((item, i) => 
//             item.file ? data.append(`fileAttachment[${i}].file`, item.file.file) : null
//           )
//           name.fileAttachment.map((item, i) => 
//             data.append(`fileAttachment[${i}].isDelete`, item.isDelete ? item.isDelete : false)
//           )
//         }
//         if(name.imageFile){
//           data.append('imageFile', name.imageFile.file);
//         }
//       }else if(type === 'createCourse'){
//         if(name.id){
//           data.append('id', name.id);
//         }
//       }else{
//         data.append('data', key)
//       }
//     }
//     axios.post(`${env}${path}`, data, {
//       headers: {
//         'Content-Type' : 'multipart/form-data',
//         'X-Requested-With': 'XMLHttpRequest',
//         'Cache-Control': 'no-cache',
//         'X-Authorization': `Bearer ${getToken}`
//       },
//     }).then((response) => {
//       if(response.status === 200){
//         return resolve(response.data);
//       }else{
//         return reject();
//       }
//     }).catch((err) => {
//       return reject(err.response.data.message);
//     })
//   }) 
// }