import React, { Component } from "react";
import {Row, Col,Skeleton, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Divider, Empty} from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import svg from '../../../../../assets/img/profile.svg';
import { detailApplicants, unmountDetailApplicants, unmountFollowedProgramApplicants, detailFollowedProgram } from '../../../../../redux/actions/admin/applicants/applicantsAction';
import Menu from '../Menu';
import { connect } from "react-redux";
import moment from 'moment';
const { Text } = Typography;


class ApplicantDetailFollowedProgram extends Component{
    formRef = React.createRef();
    constructor(props) {
     super(props)
  
    this.state = {}
  }

  componentDidMount() {
    const { actionGetDetail,actionGetFollowedProgram, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetFollowedProgram(params.id)
  }
    
  render () {
    // const { visibleAdd, visibleEdit } = this.state;
    const { getDetailApplicants: { loading, data }, getDetailFollowedProgram  } = this.props;
    
    if(loading || getDetailFollowedProgram.loading){
      return <Skeleton avatar />
    }

    return(
      <React.Fragment>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined/> Applicant</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="followed-program">
                  Applicant List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Applicant Detail</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col span={24}>
            <PageHeader className="site-page-header" title='Applicants Detail'/>
          </Col>
          <Col span={7}>
            <Card style={{border: 'none'}}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.photoUrl ? data.photoUrl : svg }/>
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.firstName} {data.lastName}</Text>
                      <Text type="secondary">{data.applicantNumber}</Text>
                    </Space>
                  </Space>
                </Col>

                <Col span={24}>
                  <Menu rowKey="followedProgram" id={data.id} />
                </Col>
              </Row>
            </Card>
          </Col>

          {/* <Col span={17}>
            <Card style={{border: 'none'}}
              title={<Text strong>Followed Program</Text>}
              >
                {
                  getDetailFollowedProgram?.data?.length > 0 ?
                    getDetailFollowedProgram.data.map(item => 
                      <React.Fragment>
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <Space direction="vertical">
                              <Text style={{color: '#3274D7'}}> {item.programNumber ? item.programNumber : 'N/A'}</Text>
                              <Text>{item.title ? item.title : 'N/A' }</Text>
                            </Space>
                          </Col>
                          <Col span={8}>
                            <Space direction="vertical">
                              <Text>{item.programCategory ? item.programCategory : 'N/A'}</Text>
                              <Text>{item.partnerName ? item.partnerName : 'N/A'}</Text>
                            </Space>
                          </Col>
                          <Col span={8}>
                            <Space direction="vertical">
                              <Text>{item.cityName ? item.cityName : 'N/A'}</Text>
                              <Text>Program Date Start - End</Text>
                            </Space>
                          </Col>
                        </Row>
                    </Card>
                </Col> */}

                <Col span={17}>
                  <Card style={{border: 'none'}}
                    title={<Text strong>Followed Program</Text>}
                    >
                        {
                            getDetailFollowedProgram.data.length > 0 ?
                            getDetailFollowedProgram.data.map(item => 
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col span={8}>
                                            <Space direction="vertical">
                                                <Text type="secondary" style={{color: '#3274D7'}}> {item.programNumber ? item.programNumber : 'N/A'}</Text>
                                                <Text type="secondary" style={{color: '#000000'}}>{item.title ? item.title : 'N/A' }</Text>
                                            </Space>
                                        </Col>
                                        <Col span={8}>
                                          <Space direction="vertical">
                                                <Text type="secondary">{item.programCategory ? item.programCategory : 'N/A'}</Text>
                                                <Text type="secondary">{item.partnerName ? item.partnerName : 'N/A'}</Text>
                                          </Space>
                                        </Col>

                                        <Col span={8}>
                                          <Space direction="vertical">
                                          <Text type="secondary">{item.cityName ? item.cityName : 'N/A'}</Text>
                                          <Text type="secondary">{item.startDate ? moment(item.startDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'} - {item.endDate ? moment(item.endDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}</Text>
                                          </Space>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </React.Fragment>
                            )
                            :
                              <Empty />
                        }
                  </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountFollowedProgramApplicants} = this.props;
    unmountGetDetail()
    unmountFollowedProgramApplicants()
  }
}

const mapStateToProps = (state) => ({
  getDetailApplicants  : state.applicants.detail,
  getDetailFollowedProgram : state.applicants.followedProgram
})

const mapDispatchToProps = {
  actionGetDetail         : detailApplicants,
  actionGetFollowedProgram : detailFollowedProgram,
  unmountGetDetail        : unmountDetailApplicants,
  unmountFollowedProgramApplicants : unmountFollowedProgramApplicants
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDetailFollowedProgram)