import React, { Component } from 'react'
import moment from 'moment';
import svg from '../../../../assets/img/profile.svg';
import ImgCrop from 'antd-img-crop';
import { connect } from 'react-redux'
import { message, Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Button, Descriptions, Image, Modal, Upload  } from 'antd';
import { Link } from 'react-router-dom';
import { masterCities, unmountMasterCities } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { dataProfile, unmountDataProfile, updateAvatar, } from '../../../../redux/actions/lecturers/profile/profileAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { UserOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { Text } = Typography;

class MyProfile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      cityId: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, actionGetCountry, actionGetCity } = this.props;
    actionGetDetail()
    actionGetCountry()
    actionGetCity()
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail } = this.props;
    return this.setState({ visible: false }, () => {
      actionGetDetail()
    })
  }

  handleUpload(){
    const { actionUpdateAvatar, actionGetDetail, match: {params}} = this.props;
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const value = {
            'photoFile': file,
          }
          return actionUpdateAvatar(value, () => {
            message.success('Successfully uploaded')
            actionGetDetail(params.id)
          })
        }
        return false;
      },
    }
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onFinish = () => {
    const { cityId } = this.state;
    const { actionUpdateBiodata, actionGetDetail } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = Number()
      values.currentCityId = cityId
      values.birthdate = values.birthdate ? moment(values.birthdate).format('DD/MM/YYYY') : null
      return actionUpdateBiodata(values, () => {
        return this.setState({ visible: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail()
        })
      }, (err) => message.error(err))
    })
  }

  render() {
    const { visible } = this.state;
    const { getDetailLecturers: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item> <UserOutlined />
                <Link className="link" to="/lecturers/biodata">
                    My Profile
                </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {data.nidn}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col span={24}>
                <PageHeader className="site-page-header" title='My Profile' />
            </Col>

            <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                  <Avatar size={100} src={data.photoUrl ? data.photoUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong> {`${data.frontTitle ? `${data.frontTitle} ` : ''}${data.firstName} ${data.lastName}${data.backTitle ? ` ${data.backTitle}` : ''}`}</Text>
                      <Text type="secondary">{data.nidn}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="biodata" id={data.id} />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Biodata</Text>} 
              // extra={<Button key="1" className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={this.showModal}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
                      <Space direction="vertical">
                      <Image width={100} src={data.photoUrl ? data.photoUrl : svg} />
                        <ImgCrop>
                          <Upload {...this.handleUpload()}>
                            <Button type="primary" size="small" ghost style={{ borderRadius: 6 }}>{data.photoUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                          </Upload>
                        </ImgCrop>
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name (with Title):</Text>}>
                      {`${data.frontTitle ? `${data.frontTitle} ` : ''}${data.firstName} ${data.lastName}${data.backTitle ? ` ${data.backTitle}` : ''}`}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                    {data.birthPlace ? data.birthPlace : 'N/A'}, {data.birthDate ? moment(data.birthDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {data.religion ? data.religion : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                    {data.gender ? data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          centered
          width={1000}
          visible={visible}
          title={<Text strong>Biodata</Text>}
          onCancel={this.onCancel}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={this.onCancel}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onFinish}>Save</Button>
            </React.Fragment>
          ]}
        />
          {/* <div style={{ height: 500, overflowY: 'auto', overflowX: 'hidden' }}>
            <EditBiodata 
              {...this.props} 
              formRef={this.formRef} 
              onFinish={this.onFinish} 
              handleCity={this.handleCity}
            />
          </div>
        </Modal> */}
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetCity } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetCity()
  }
}

const mapStateToProps = (state) => ({
  getDetailLecturers  : state.lecturersProfile.data,
  getCountry          : state.countries.master,
  getCity             : state.cities.master
})

const mapDispatchToProps = {
  actionUpdateAvatar  : updateAvatar,
  // actionUpdateBiodata : updateBiodata,
  actionGetDetail     : dataProfile,
  actionGetCountry    : masterCountries,
  actionGetCity       : masterCities,
  unmountGetDetail    : unmountDataProfile,
  unmountGetCountry   : unmountMasterCountries,
  unmountGetCity      : unmountMasterCities
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)