import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { UploadOutlined, ContactsOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Divider, Space, message, Breadcrumb, Typography } from 'antd';
import { detailCourses, editCourse, unmountDetailCourses } from '../../../../redux/actions/admin/courses/coursesAction';

class EditCourse extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      submitLoading: false,
      valuesArray: [],
      fileList: [
        {
          uid: '1',
          name: null,
          url: null
        }
      ]
    }
  }
  
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      if(response.data.rpsAttachment){
        this.setState({ 
          fileList: [
            {
              uid: '1',
              name: response.data.rpsAttachment,
              url: response.data.rpsAttachmentUrl
            }
          ] 
        })
      }
    })
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
            fileList: [
              {
                uid: file.uid,
                name: file.name
              }
            ]
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { editAvatar, avatar, valuesArray } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    values.enabled = true
    values.id = data.id
    if(editAvatar){
      values.rpsAttachmentFile = avatar
    }
    valuesArray.push(values)
    this.setState({ submitLoading: true })
    return actionUpdate(valuesArray, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/courses')
      })
    }, (err) => {
      this.setState({ submitLoading: false, valuesArray: [] }, () => message.error(err))
    })
  }

  render() {
    const { getData: { data, loading } } = this.props;
    const { submitLoading, editAvatar, fileList } = this.state;
    if(loading){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Courses</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/courses">Courses List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.code ? data.code : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Update Course Data" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 32 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Course Information</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      label="Course Code" 
                      name="code" 
                      validateFirst
                      initialValue={data.code}
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Credit Weight (SKS)" 
                      name="sks" 
                      validateFirst
                      initialValue={data.sks}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 2, message: 'Maximum is 2 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Course Name" 
                      name="name"
                      validateFirst
                      initialValue={data.name}
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Semester" 
                      name="semester"
                      validateFirst
                      initialValue={data.semester}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 2, message: 'Maximum is 2 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Course Category" 
                      name="category"
                      validateFirst
                      initialValue={data.category}
                      rules={[
                        { required: true, message: 'This is a required field' }             
                      ]}
                    >
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="GENERAL_COURSE">General Course</Select.Option>
                        <Select.Option value="MAJOR_COURSE">Major Course</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item label="RPS Attachment">
                      <Space>
                        <Upload {...this.handleUpload()} showUploadList={data.rpsAttachment || editAvatar} fileList={fileList}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                      </Space>
                    </Form.Item>
                  </Col>
                  
                  <Col span={24}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue={data.description ? data.description : null}
                      rules={[
                        { max: 1000, message: 'Maximum is 1000 characters' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 32 }}>
                    <Typography.Text style={{ fontSize: 20 }}>CPMK</Typography.Text>
                  </Col>
                  
                  <Col span={24}>
                    <Form.List name="listCourseCpmk" initialValue={data.listCourseCpmk ? data.listCourseCpmk : null}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Row key={field.fieldKey} gutter={16}>
                              <Col span={23}>
                                <Form.Item 
                                  {...field}
                                  name={[index, 'component']}
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { max: 500, message: 'Maximum is 500 characters' }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              
                              <Col span={1}>
                                <Form.Item>
                                  <MinusCircleOutlined onClick={() => {
                                    remove(field.name)
                                  }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                          <Col span={24}>
                            <Form.Item>
                              <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                                Add CPMK
                              </Button>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Divider />

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData     : state.courses.detail
})

const mapDispatchToProps = {
  actionUpdate        : editCourse,
  actionGetDetail     : detailCourses,
  unmountGetDetail    : unmountDetailCourses
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse)