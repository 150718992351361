import { API } from '../../../config';

export const checkEmail = (value, successCB, failedCB) => () => {
  const params = {
    email: value
  }
  API.POST_WITH_PARAMS('/checkEmail', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const checkPhone = (value, successCB, failedCB) => () => {
  const params = {
    mobile: value
  }
  API.POST_WITH_PARAMS('/checkMobile', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}