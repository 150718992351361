import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, PhoneOutlined, ReadOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
      <Menu.Item key="biodata" icon={<UserOutlined />}>
        <Link to={`/lecturers/biodata`}>
          Biodata
        </Link>
      </Menu.Item>
      <Menu.Item key="lecturerInformation" icon={<ReadOutlined />}>
        <Link to={`/lecturers/lecturer-information`}>
          Lecturer Information
        </Link>
      </Menu.Item>
      <Menu.Item key="contactInformation" icon={<PhoneOutlined />}>
        <Link to={`/lecturers/contact-information`}>
          Contact & Addres
        </Link>
      </Menu.Item>
    </Menu>
  )
}