import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';

const { Text } = Typography;

export const columns = (onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      width: 80,
      render: (record) => (
        <React.Fragment>
          <Link className="link" to={`lecturers/detail/${record.id}/biodata`}>
            {record.number}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.firstName && record.lastName ? `${record.firstName} ${record.lastName}` : 'N/A'}
        </Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 160,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.institutionName ? record.institutionName : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Mobile',
      key: 'mobile',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.mobileNumber ? record.mobileNumber : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    }
  ]
}