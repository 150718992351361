import React, { Component } from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
import { UserOutlined } from '@ant-design/icons';
import { List, Row, Col, Typography, Avatar } from 'antd';
const { Text } = Typography;

export default class ListApplicant extends Component {
  render() {
    const { loading, pagination, idApplicant, selectedApplicant, dataSource, handlePagination } = this.props;
    return (
      <React.Fragment>
        <List
          dataSource={dataSource}
          loading={loading}
          pagination={{
            onChange: (e) => handlePagination(e),
            style:{ textAlign: 'center' },
            size: 'small',
            showSizeChanger: false,
            pageSize: pagination.perpage,
            total: pagination.total,
            current: pagination.page
          }}
          renderItem={(item) => (
            <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
              <List.Item.Meta
                onClick={() => selectedApplicant(item)}
                avatar={
                  <Avatar src={item.photoUrl ? item.photoUrl : null} icon={!item.photoUrl ? <UserOutlined /> : null} />
                }
                title={<Text style={{ color: '#0076de' }}>{item.applicantName ? item.applicantName : 'N/A'}</Text>}
                description={
                  <Row>
                    {/* <Col span={24}><Text type="secondary">{item.institution ? item.institution : 'N/A'}</Text></Col> */}
                    <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.participantNumber ? item.participantNumber : 'N/A'}</Text></Col>
                    {
                      item.institutionName || item.fieldOfStudyName ?
                        <Col span={24}><Text style={{ color: '#b1b1b1' }} type="secondary">{item.institutionName ? item.institutionName : 'N/A'}, {item.fieldOfStudyName ? item.fieldOfStudyName : 'N/A'}</Text></Col>
                      : item.applicantCityName || item.applicantCountryName ?
                        <Col span={24}><Text style={{ color: '#b1b1b1' }} type="secondary">{item.applicantCityName ? item.applicantCityName : 'N/A'}, {item.applicantCountryName ? item.applicantCountryName : 'N/A'}</Text></Col>
                      : null
                    }
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    )
  }
}
