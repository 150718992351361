import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item key='1' icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item key='2' icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item key='3' icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Code',
      key: 'code',
      fixed: 'left',
      width: 60,
      render: (record) => (
        <Link className="link" to={`/courses/detail/${record.id}`}>
          {record.code}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : null,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          <Tooltip title={record.name}>
            {record.name}
          </Tooltip>
        </Text>
      )
    },
    {
      title: 'Category',
      key: 'category',
      width: 50,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.category === 'MAJOR_COURSE' ? 'Major Course' : 'General Course' }
        </Text>
      )
    },
    {
      title: 'SKS',
      key: 'sks',
      width: 20,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.sks}
        </Text>
      )
    },
    {
      title: 'Description',
      key: 'description',
      width: 200,
      ellipsis: true,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.description ? record.description : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 20,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}