import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import HeaderDetailPrograms from '../Header';
import { ContainerOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { detailPrograms, inactivePrograms, unmountDetailPrograms } from '../../../../../redux/actions/admin/programs/programsAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Descriptions, Modal, Space, Image, message } from 'antd';
import MenuDetail from '../MenuDetail';
import { programCategory } from '../../../../../utils/constant/programCategory';

const { Text } = Typography;
const { confirm } = Modal;

class AcademicDetailInformationPrograms extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      checked: true
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  render() {
    const { checked } = this.state;
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContainerOutlined /> Programs</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/academic/programs">Programs List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.programNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Program Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailPrograms 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <MenuDetail rowKey="detailInformation" id={data.id} />
          </Col>
          {/* Information */}
          <Col span={24}>
            <Card title="Information">
              <Row gutter={16}>
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Program Information</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                    <Descriptions.Item label={<Text type="secondary">Title</Text>}>
                      {data.title ? data.title : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Program Category</Text>}>
                      {data.programCategory ? programCategory.find(item => item.value === data.programCategory)?.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Partner:</Text>}>
                      {data.partnerName ? data.partnerName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Level</Text>}>
                      {
                        data.programLevel ?
                          <React.Fragment>
                            {data.programLevel === "INTERNATIONAL" ? "International" : null}
                            {data.programLevel === "DOMESTIC" ? "Domestic" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Location</Text>}>
                      {
                        data.programLevel === 'INTERNATIONAL' ?
                          `${data.countryName}, ${data.cityName}`
                        : data.programLevel === 'DOMESTIC' ?
                          data.cityName
                        :
                          null
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Number of Credit (SKS)</Text>}>
                      {data.creditHour ? data.creditHour : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Registration Date</Text>}>
                      {data.startDate && data.endDate ? `${moment(data.openDate, 'DD/MM/YYYY').format("DD MMMM YYYY")} - ${moment(data.closeDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Program Date</Text>}>
                      {data.openDate && data.closeDate ? `${moment(data.startDate, 'DD/MM/YYYY').format("DD MMMM YYYY")} - ${moment(data.endDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Number of Position</Text>}>
                      {data.positionNumber ? data.positionNumber : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  {
                    data?.listInternship?.length > 0 ?
                      <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label={<Text type="secondary">Available Positions</Text>}>
                          <ul>
                            {
                              data?.listInternship?.map(item => 
                                <li key={item.id}>{item.positionName}</li>
                              )
                            }
                          </ul>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data?.listExchange?.length > 0 ?
                      <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label={<Text type="secondary">Available Field of Studies</Text>}>
                          <ul>
                            {
                              data?.listExchange?.map(item => 
                                <li key={item.id}>{item.fieldOfStudyName}</li>
                              )
                            }
                          </ul>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data.programDescription ?
                      <Descriptions layout="vertical" size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Description</Text>}>
                          <div dangerouslySetInnerHTML={{ __html: data.programDescription ? data.programDescription : 'N/A' }}></div>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data.programRequirement ?
                      <Descriptions layout="vertical" size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Requirement</Text>}>
                          <div dangerouslySetInnerHTML={{ __html: data.programRequirement ? data.programRequirement : 'N/A' }}></div>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data.programRequirement ?
                      <Descriptions layout="vertical" size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Procedure</Text>}>
                          <div dangerouslySetInnerHTML={{ __html: data.programProcedure ? data.programProcedure : 'N/A' }}></div>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data.programRequirement ?
                      <Descriptions layout="vertical" size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">SKS Conversion</Text>}>
                          <div dangerouslySetInnerHTML={{ __html: data.programConversion ? data.programConversion : 'N/A' }}></div>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                  {
                    data?.listImage?.length > 0 ?
                      <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label={<Text type="secondary">Images</Text>}>
                        <Space size={16}>
                          {
                            data?.listImage?.map((item,i) => 
                              <Image key={i} src={item.fileUrl} height={100} width={100} style={{ objectFit: 'contain' }} />
                            )
                          }
                        </Space>
                        </Descriptions.Item>
                      </Descriptions>
                    : null
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.programs.detail
})

const mapDispatchToProps = {
  actionInactive    : inactivePrograms,
  actionGetDetail   : detailPrograms,
  unmountGetDetail  : unmountDetailPrograms
}

export default connect(mapStateToProps, mapDispatchToProps)(AcademicDetailInformationPrograms)
