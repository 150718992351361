import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import update from 'react-addons-update';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { detailPrograms, editProgram, unmountDetailPrograms } from '../../../../redux/actions/admin/programs/programsAction';
import { masterPartners, unmountMasterPartners } from '../../../../redux/actions/admin/partners/partnersAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { provinceByCountry } from '../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCities, cityByProvince, unmountMasterCities } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { masterPositions, unmountMasterPositions } from '../../../../redux/actions/admin/master/positions/positionsAction';
import { masterFieldOfStudy, unmountMasterFieldOfStudy } from '../../../../redux/actions/admin/master/fieldOfStudy/fieldOfStudyAction';
import { SoundOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Card, Row, Col, Breadcrumb, PageHeader, Typography, Button, Input, Select, Radio, DatePicker, Space, Modal, Upload, Image, Tooltip, message } from 'antd';
// import { jobType } from '../../../../utils/constant/job';
const { confirm } = Modal;
const { Text } = Typography;
const { Dragger } = Upload;

class EditPrograms extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      // valueForm: null,
      submitLoading: false,
      startDate: moment(),
      openDate: moment(),
      closeDate: moment(),
      programCategory: null,
      programLevel: 'DOMESTIC',
      dataProvince: [],
      dataCity: [],
      position: [],
      dataPosition: null,
      fieldOfStudy: [],
      dataFieldOfStudy: null,
      editAvatar: false,
      onPreviewAvatar: null,
      avatar: [],
      showFile: false,
      listAttachment: [],
      valueIndex: 0,
      deletedListAttachment: [],
      valuesArray: []
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail,
      actionGetPartner,
      actionGetCountry,
      actionGetMasterCity,
      actionGetPosition,
      actionGetFieldOfStudy,
      actionGetProvince,
      actionGetCity,
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ 
        programCategory: response.data.programCategory,
        programLevel: response.data.programLevel,
        avatar: response.data.imageUrl,
        listAttachment: response.data.listAttachment ? response.data.listAttachment : []
      })
      if(response.data.programLevel === 'INTERNATIONAL'){
        actionGetProvince(response.data.countryId, (response) => {
          this.setState({ dataProvince: response.data })
        })
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
    })
    actionGetPartner()
    actionGetCountry()
    actionGetMasterCity()
    actionGetPosition()
    actionGetFieldOfStudy()
  }

  handleProgramCategory = (value) => {
    this.setState({ 
      programCategory: value
    })
    this.formRef.current.setFieldsValue({
      listInternship: null,
      listExchange: null
    });
  }

  handleProgramLevel = (value) => {
    this.setState({ 
      programLevel: value
    })
    this.formRef.current.setFieldsValue({
      countryId: null,
      provinceId: null,
      cityId: null,
    });
  }

  onChangeOpenDate = (current) => {
    this.formRef.current.setFieldsValue({
      closeDate: null,
      startDate: null,
      endDate: null,
    });
    this.setState({ openDate: current })
  }

  disabledOpenDate = (current) => {
    let yesterday = moment().subtract(1, 'days')
    return !current || current.isBefore(yesterday)
  }

  disabledCloseDate = (current) => {
    const { openDate } = this.state
    return current < openDate
  }

  onChangeCloseDate = (current) => {
    this.setState({ closeDate: current })
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      endDate: null,
    });
    this.setState({ startDate: current })
  }

  disabledStartDate = (current) => {
    const { closeDate } = this.state
    let tommorowClodeDate = moment(closeDate).add(1, 'days')
    
    return !current || current.isBefore(tommorowClodeDate)
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  addPosition = () => {
    const { dataPosition } = this.state;
    this.setState({
      position: update(this.state.position, {
        $push: [dataPosition]
      }),
      dataPosition: null
    })
  }

  handleChangePosition = (value) => {
    this.setState({ dataPosition: value })
  }

  handleRemovePosition = (index) => {
    this.setState({
      position: update(this.state.position, {
        $splice: [[index, 1]]
      })
    })
  }

  addFieldOfStudy = () => {
    const { dataFieldOfStudy } = this.state;
    this.setState({
      fieldOfStudy: update(this.state.fieldOfStudy, {
        $push: [dataFieldOfStudy]
      }),
      dataFieldOfStudy: null
    })
  }

  handleChangeFieldOfStudy = (value) => {
    this.setState({ dataFieldOfStudy: value })
  }

  handleRemoveFieldOfStudy = (index) => {
    this.setState({
      fieldOfStudy: update(this.state.fieldOfStudy, {
        $splice: [[index, 1]]
      })
    })
  }

  handleChange = (value, fileUrl) => {
    return this.setState({
      avatar: fileUrl
    })
  }

  handleUploadAttcahment(index){
    return {
      withCredentials: true,
      accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5MB!');
          this.setState({ showFile: false })
          return false
        }
        if (file.type !== 'application/pdf') {
          message.error('PDF file only!');
          this.setState({ showFile: false })
          return false
        }
        return false;
      }
    }
  }
  
  handleChangeNameAttachment = (e, index) => {
    const { listAttachment } = this.state
    listAttachment[index].name = e.target.value
    this.setState({ listAttachment })
  }

  handleChangeUploadAttachment = (info, index) => {
    const { listAttachment } = this.state
    listAttachment[index].file = info
    listAttachment[index].fileName = info.file.name
    this.setState({ listAttachment })
  }

  deleteAttachment = (index) => {
    const { listAttachment, deletedListAttachment } = this.state
    if(listAttachment[index].id){
      deletedListAttachment.push({
        id: listAttachment[index].id,
        isDelete: true
      })
      this.setState({ deletedListAttachment })
    }
  }

  handleUpload(){
    return {
      multiple: true,
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.handleChange(file, e.target.result)
          this.setState({ 
            editAvatar: true,
          })
        }
        return false;
      },
    }
  }

  onCancel = () => {
    const { history } = this.props;
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, you will lose all of your edit a program data and you can’t undo this action.',
      onOk() {
        return history.push('/programs')
      }
    });
  }

  // handleChangeValue = (value) => {
  //   if(value.title && value.dateOpened && value.targetDate){
  //     this.setState({ valueForm: value })
  //   }
  // }

  onSave = (values) => {
    const { url } = this.state;
    const { actionSave, history } = this.props;
    const valuesForm = values.getFieldsValue();
    valuesForm.dateOpened = valuesForm.dateOpened ? moment(valuesForm.dateOpened).format('DD/MM/YYYY') : null
    valuesForm.targetDate = valuesForm.targetDate ? moment(valuesForm.targetDate).format('DD/MM/YYYY') : null
    this.setState({ submitLoading: true })
    return actionSave(valuesForm, url, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Successfully saved')
        history.push('/programs')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onFinish = (values) => {
    const { programLevel, valuesArray, listAttachment, deletedListAttachment } = this.state
    const { actionUpdate, history, getData: { data } } = this.props;
    const allListAttachment = listAttachment.concat(deletedListAttachment)
    if(programLevel === 'DOMESTIC'){
      delete values.countryId
      delete values.provinceId
    }
    values.id = data.id
    values.openDate = values.openDate ? moment(values.openDate).format('DD/MM/YYYY') : null
    values.closeDate = values.closeDate ? moment(values.closeDate).format('DD/MM/YYYY') : null
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.endDate = values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : null
    values.listAttachment = allListAttachment
    values.status = 'ACTIVE'
    valuesArray.push(values)
    this.setState({ submitLoading: true })
    return actionUpdate(valuesArray, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/programs')
      })
    }, (err) => {
      this.setState({ submitLoading: false, valuesArray: [] }, () => message.error(err))
    })
  }
  
  render() {
    const { 
      getPartner, 
      getCountry,
      getMasterCity,
      getPosition,
      getFieldOfStudy,
      getData: { data, loading }, 
    } = this.props;

    const { 
      // valueForm,
      submitLoading, 
      programCategory,
      programLevel,
      dataProvince,
      dataCity,
      avatar,
      // showFile,
      listAttachment,
      valueIndex
    } = this.state;

    if(loading || getPartner.loading || getCountry.loading || getMasterCity.loading || getPosition.loading || getFieldOfStudy.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form 
          layout="vertical" 
          ref={this.formRef} 
          scrollToFirstError={true}
          onFinish={this.onFinish} 
          // onValuesChange={(_, value) => {
          //   this.handleChangeValue(value)
          // }}
        >
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><SoundOutlined /> Programs</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/programs">Programs List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Program</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title='Edit a Program' 
                extra={[
                  <Button key="3" className="btn-save-primary" type="default" onClick={this.onCancel}>Cancel</Button>,
                  // <Button key="2" className="btn-radius" type="primary" onClick={() => this.onSave(this.formRef.current)} loading={submitLoading} disabled={valueForm !== null ? false : true} ghost>Save as draft</Button>,
                  <Button key="1" className="btn-radius" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>,
                ]}
              />
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={24} style={{ marginBottom: 32 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Program Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Program Category" 
                      name="programCategory"
                      initialValue={data.programCategory ? data.programCategory : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder='Select category' onChange={this.handleProgramCategory}>
                        <Select.Option value="INTERNSHIP">Internship (Magang)</Select.Option>
                        <Select.Option value="STUDENT_EXCHANGE">Student Exchange (Pertukaran Mahasiswa)</Select.Option>
                        <Select.Option value="ENTREPRENEURSHIP">Entrepreneurship (Kewirausahaan)</Select.Option>
                        <Select.Option value="HUMANITY">Humanity (Proyek Kemanusiaan)</Select.Option>
                        <Select.Option value="COMMUNITY_SERVICE">Community Service (Membangun Desa / KKN)</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}></Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Title" 
                      name="title" 
                      initialValue={data.title ? data.title : null}
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Title must be at least 3 characters long' },
                        { max: 100, message: 'Title is too long. Maximum is 100 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Partner" 
                      name="partnerId"
                      initialValue={data.partnerId ? data.partnerId : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select partner"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getPartner?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Number of Credits (SKS)" 
                      name="creditHour"
                      validateFirst
                      initialValue={data.creditHour ? data.creditHour : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'Number only' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Level" 
                      name="programLevel" 
                      initialValue={programLevel}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select onChange={this.handleProgramLevel}>
                        <Select.Option value="DOMESTIC">Domestic</Select.Option>
                        <Select.Option value="INTERNATIONAL">International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {
                    programLevel === 'INTERNATIONAL' ?
                      <>
                        <Col span={12}>
                          <Form.Item 
                            label="Country" 
                            name="countryId"
                            initialValue={data.countryId ? data.countryId : null}
                            rules={[
                              { required: programLevel === 'INTERNATIONAL', message: 'This is a required field' },
                            ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select a country"
                              optionFilterProp="children"
                              onChange={this.handleChangeCountry}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {
                                getCountry?.data.map(item => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item 
                            label="Province" 
                            name="provinceId"
                            initialValue={data.provinceId ? data.provinceId : null}
                            rules={[
                              { required: programLevel === 'INTERNATIONAL', message: 'This is a required field' },
                            ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select a province"
                              onChange={this.handleChangeProvince}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {
                                dataProvince.map(item => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      </>
                    :
                      null
                  }

                  <Col span={12}>
                    <Form.Item 
                      label="Location" 
                      name="cityId"
                      initialValue={data.cityId ? data.cityId : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a location"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          programLevel === 'INTERNATIONAL' ?
                            dataCity.map(item => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))
                          : programLevel === 'DOMESTIC' ?
                            getMasterCity?.data?.map(item => (
                              item.countryName === 'Indonesia' ?
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              :
                                null
                            ))
                          :
                            null
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Registration Date Opened"
                      name="openDate"
                      initialValue={data.openDate ? moment(data.openDate, 'DD/MM/YYYY') : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        // disabledDate={this.disabledOpenDate}
                        onChange={this.onChangeOpenDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Registration Date Closed"
                      name="closeDate"
                      initialValue={data.closeDate ? moment(data.closeDate, 'DD/MM/YYYY') : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledCloseDate} 
                        onChange={this.onChangeCloseDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Program Start Date"
                      name="startDate"
                      initialValue={data.startDate ? moment(data.startDate, 'DD/MM/YYYY') : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        // disabledDate={this.disabledStartDate}
                        onChange={this.onChangeStartDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Program End Date"
                      name="endDate"
                      initialValue={data.endDate ? moment(data.endDate, 'DD/MM/YYYY') : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledEndDate} 
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Number of Position" 
                      name="positionNumber"
                      validateFirst
                      initialValue={data.positionNumber ? data.positionNumber : null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="This program have a certificate?" 
                      name="hasCertificate"
                      initialValue={data.hasCertificate}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Participant Type?" 
                      name="isGroup"
                      initialValue={data.isGroup} 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={false}>Individu</Radio>
                        <Radio value={true}>Group</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {
                    programCategory === 'INTERNSHIP' ?
                      <>
                        <Col span={24} style={{ marginBottom: 16 }}>
                          <Typography.Text style={{ fontSize: 18 }}>Available Positions</Typography.Text>
                        </Col>

                        <Col span={24}>
                          <Form.List name="listInternship" initialValue={data.listInternship ? data.listInternship : null}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <Row key={field.fieldKey} gutter={16}>
                                    <Col span={12}>
                                      <Form.Item 
                                        {...field}
                                        name={[index, 'positionId']}
                                        initialValue={null}
                                        rules={[
                                          { required: true, message: 'This is a required field' },
                                        ]}
                                      >
                                        <Select placeholder="Select a position" onChange={this.handleChangePosition}>
                                          {
                                            getPosition?.data?.map((res, i) => {
                                              const act = this.state.position.includes(res.name);
                                              return (
                                                <React.Fragment key={i}>
                                                  {
                                                    !act && 
                                                    <Select.Option key={i} value={res.id}>
                                                      {res.name}
                                                    </Select.Option>
                                                  }
                                                </React.Fragment>
                                              )
                                            })
                                          }
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                      <Form.Item>
                                        <MinusCircleOutlined onClick={() => {
                                          remove(field.name);
                                          return this.handleRemovePosition(index)
                                        }} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))}
                                <Col span={24}>
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => { add(); this.addPosition() }} block icon={<PlusOutlined />}>
                                      Add Position
                                    </Button>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </>
                    : programCategory === 'STUDENT_EXCHANGE' ?
                      <>
                        <Col span={24} style={{ marginBottom: 16 }}>
                          <Typography.Text style={{ fontSize: 18 }}>Available Field of Studies</Typography.Text>
                        </Col>

                        <Col span={24}>
                          <Form.List name="listExchange" initialValue={data.listExchange ? data.listExchange : null}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <Row key={field.fieldKey} gutter={16}>
                                    <Col span={12}>
                                      <Form.Item 
                                        {...field}
                                        name={[index, 'fieldOfStudyId']}
                                        initialValue={null}
                                        rules={[
                                          { required: true, message: 'This is a required field' },
                                        ]}
                                      >
                                        <Select placeholder="Select a field of study" onChange={this.handleChangeFieldOfStudy}>
                                          {
                                            getFieldOfStudy?.data?.map((res, i) => {
                                              const act = this.state.fieldOfStudy.includes(res.name);
                                              return (
                                                <React.Fragment key={i}>
                                                  {
                                                    !act && 
                                                    <Select.Option key={i} value={res.id}>
                                                      {res.name}
                                                    </Select.Option>
                                                  }
                                                </React.Fragment>
                                              )
                                            })
                                          }
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                      <Form.Item>
                                        <MinusCircleOutlined onClick={() => {
                                          remove(field.name);
                                          return this.handleRemoveFieldOfStudy(index)
                                        }} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))}
                                <Col span={24}>
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => { add(); this.addFieldOfStudy() }} block icon={<PlusOutlined />}>
                                      Add Field Study
                                    </Button>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </>
                    :
                      null
                  }

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Program Description</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Description" 
                      name="programDescription" 
                      initialValue={data.programDescription ? data.programDescription : null}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Program Requirements</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Requirements" 
                      name="programRequirement" 
                      initialValue={data.programRequirement ? data.programRequirement : null}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Requirements" />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Registration Document</Typography.Text>
                  </Col>
                  
                  <Col span={24}>
                    <Form.List name="listDocument" initialValue={data.listDocument}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Row key={field.fieldKey} gutter={[16,0]}>
                              <Col span={23}>
                                <Form.Item 
                                  {...field}
                                  name={[index, 'name']}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <Form.Item>
                                  <DeleteOutlined onClick={() => {
                                    remove(field.name);
                                  }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                          <Form.Item className='mt-8'>
                            <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                              Add Registration Document
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Procedure</Typography.Text>
                  </Col>
                  
                  <Col span={24}>
                    <Form.Item 
                      label="Procedure" 
                      name="programProcedure" 
                      initialValue={data.programProcedure ? data.programProcedure : null}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Procedure" />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>SKS Conversion</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="SKS Conversion" 
                      name="programConversion" 
                      initialValue={data.programConversion ? data.programConversion : null}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="SKS Conversion" />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Attachment</Typography.Text>
                  </Col>
                  
                  <Col span={24}>
                    <Form.List name="listAttachment" initialValue={data.listAttachment}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Row key={field.fieldKey} gutter={[16,0]}>
                              <Col span={17}>
                                <Form.Item 
                                  {...field}
                                  name={[index, 'name']}
                                  onChange={(e) => this.handleChangeNameAttachment(e, index)}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item 
                                  {...field}
                                  name={[index, 'file']}
                                  fieldKey={[index, 'file']}
                                  rules={[
                                    { required: !listAttachment[index]?.id && !listAttachment[index]?.file, message: 'This is a required field' },
                                  ]}
                                >
                                  <Upload 
                                    {...this.handleUploadAttcahment(index)} 
                                    onChange={(e) => this.handleChangeUploadAttachment(e, index)}
                                    maxCount={1}
                                    // showUploadList={showFile}
                                    fileList={listAttachment?.length > 0 && listAttachment[index]?.fileName ?
                                      [
                                        {
                                          uid: index,
                                          name: listAttachment[index]?.fileName,
                                          status: 'done',
                                          url: listAttachment[index]?.fileUrl,
                                        }
                                      ]
                                    :
                                      null
                                    }
                                  >
                                    <Button icon={<UploadOutlined />}><Text>Upload Dokumen</Text></Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                {
                                  fields.length > 1 ?
                                    <Form.Item>
                                      <DeleteOutlined onClick={() => {
                                        remove(field.name);
                                        this.deleteAttachment(index);
                                        listAttachment.splice(index, 1);
                                        this.setState({ listAttachment })
                                      }} />
                                    </Form.Item>
                                  : null
                                }
                              </Col>
                            </Row>
                          ))}
                          <Text className='fs-12 light-color'>Document Format PDF Only, Max  file size is 5MB.</Text>
                          <Form.Item className='mt-8'>
                            <Button 
                              type="dashed" 
                              onClick={(index) => { 
                                add();
                                listAttachment.push({
                                  name: null,
                                  file: null,
                                  isDelete: false
                                })
                                this.setState({ listAttachment, valueIndex: valueIndex+1 })
                              }} 
                              block 
                              icon={<PlusOutlined />}
                            >
                              Tambah Lampiran
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Image</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Card style={{ border: 'none' }}>
                      <Row gutter={[16, 24]}>
                        <Col span={24}>
                          {
                            avatar ? 
                              <>
                                <Card>
                                  <Row gutter={[16, 24]}>
                                    <Col span={24}>
                                      <Image src={avatar} preview={false} width={'100%'} />
                                    </Col>
                                  </Row>
                                </Card>
                                <Tooltip title="Change Image">
                                  <div style={{ position: 'absolute', top: 0, right: 8 }}>
                                    <Form.Item name="imageFile">
                                      <Upload {...this.handleUpload()}>
                                        <Button 
                                          shape="circle" 
                                          type="primary"
                                          icon={<EditOutlined style={{ color: 'white' }} />} 
                                        />
                                      </Upload>
                                    </Form.Item>
                                  </div>
                                </Tooltip>
                              </>
                            :
                              <Form.Item name="imageFile">
                                <Dragger 
                                  {...this.handleUpload()} 
                                  style={{ 
                                    backgroundColor: '#fafafa', 
                                    boxShadow: ' 0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                                    border: 'solid 1px #f3f3f3',
                                    padding: 25 
                                  }}
                                >
                                  <Space direction="vertical">
                                    <Button 
                                      style={{ 
                                        backgroundColor: '#c7e9d8',
                                        width: '499px',
                                        height: '38px',
                                        margin: '0px 0px 19px',
                                        borderRadius: '6px',
                                        border: 'none',
                                        boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                                        color: '#32ab6d',
                                        fontSize: '16px'
                                      }}
                                    >
                                      Select Photos
                                    </Button>
                                    <Text type="secondary" style={{ fontSize: '16px' }}>
                                      or drag photos here (ideally 480 x 1400 pixels)
                                    </Text>
                                  </Space>
                                </Dragger>
                              </Form.Item>
                          }
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {/* <Divider />

                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={this.onCancel}>Cancel</Button>
                        <Button className="btn-radius" type="primary" onClick={() => this.onSave(this.formRef.current)} loading={submitLoading} disabled={valueForm !== null ? false : true} ghost>Save as draft</Button>
                        <Button className="btn-radius" type="primary" htmlType="submit" loading={submitLoading}>Submit and Propose</Button>
                      </Space>
                    </Form.Item>
                  </Col> */}
                  
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { 
      unmountDetailPrograms,
      unmountGetPartner, 
      unmountGetCountry, 
      unmountGetMasterCity,
      unmountGetPosition,
      unmountGetFieldOfStudy
    } = this.props;
    unmountDetailPrograms()
    unmountGetPartner()
    unmountGetCountry()
    unmountGetMasterCity()
    unmountGetPosition()
    unmountGetFieldOfStudy()
  }
}

const mapStateToProps = (state) => ({
  getData          : state.programs.detail,
  getPartner       : state.partners.master,
  getCountry       : state.countries.master,
  getMasterCity    : state.cities.master,
  getPosition      : state.positions.master,
  getFieldOfStudy  : state.fieldOfStudy.master
})

const mapDispatchToProps = {
  actionGetDetail         : detailPrograms,
  actionUpdate            : editProgram,
  // actionSave                  : saveJob,
  actionGetPartner        : masterPartners,
  actionGetCountry        : masterCountries,
  actionGetProvince       : provinceByCountry,
  actionGetCity           : cityByProvince,
  actionGetMasterCity     : masterCities,
  actionGetPosition       : masterPositions,
  actionGetFieldOfStudy   : masterFieldOfStudy,
  unmountDetailPrograms   : unmountDetailPrograms,
  unmountGetPartner       : unmountMasterPartners,
  unmountGetCountry       : unmountMasterCountries,
  unmountGetMasterCity    : unmountMasterCities,
  unmountGetPosition      : unmountMasterPositions,
  unmountGetFieldOfStudy  : unmountMasterFieldOfStudy
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPrograms)