import React, { Component } from 'react';
import moment from 'moment';
import AddDocument from './Add';
import EditDocument from './Edit';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Space, Avatar, Typography, Divider, Tooltip, Empty, Modal, Upload, message } from 'antd';
import { detailApplicants, unmountDetailApplicants } from '../../../../../redux/actions/admin/applicants/applicantsAction';
import { UserOutlined, ExclamationCircleOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { Text } = Typography;
const { confirm } = Modal;

class ApplicantDetailSupportingFile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataDocuments: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      documentFile: null,
      showFileEdit: false
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id)
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true, documentFile: null })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataDocuments: item, 
        current: item.current,
        showFileEdit: true
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: false, documentFile: null, })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: false,
        dataDocuments: null,
        current: false,
        showFileEdit: false
      })
    }
  }

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state
    this.setState({ startDate: dateString })
    if(endDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state
    this.setState({ endDate: dateString })
    if(dateString <= startDate){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleChecked = (e) => {
    this.setState({ current: e.target.checked })
    if(e.target.checked){
      this.setState({ endDate: null })
      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleUpload(){
    return {
      withCredentials: true,
      accept:".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            documentFile: file
          })
          message.info('Upload Success!');
        }
        return false;
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      documentFile: null,
      showFileEdit: false
    })
    message.success(`${file.name} file removed successfully`);
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { current, documentFile } = this.state;
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
      values.documentFile = documentFile
      values.remark = values.remark ? values.remark : ""
      values.candidateId = Number(params.id)
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { current, dataDocuments, documentFile } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.current = current 
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : dataDocuments.issueDate ? dataDocuments.issueDate : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : dataDocuments.expirationDate ? dataDocuments.expirationDate : ""
      if(documentFile){
        values.documentFile = documentFile
      }else{
        delete values.documentFile
      }
      values.candidateId = Number(params.id)
      values.id = dataDocuments.id
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false, showFileEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visibleAdd, visibleEdit, documentFile, dataDocuments, showFileEdit } = this.state;
    const { getDetailApplicants: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }

    const addFileList = [
      {
        uid: '-1',
        name: documentFile?.name,
        status: 'done'
      }
    ]

    const editFileList = [
      {
        uid: '-1',
        name: dataDocuments?.documentPic,
        status: 'done',
        url: dataDocuments?.documentUrl,
        thumbUrl: dataDocuments?.documentUrl,
      }
    ]

    const uploadDocumentAdd = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile}
        fileList={documentFile ? addFileList : []}
      >
        <Button icon={<UploadOutlined />}>Upload Document</Button>
      </Upload>
    )

    const uploadDocumentEdit = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile ? documentFile : showFileEdit}
        fileList={documentFile ? addFileList : showFileEdit ? editFileList : []}
      >
        <Button icon={<UploadOutlined />}>Upload Document</Button>
      </Upload>
    )
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Applicants</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/applicants" style={{ color: '#0076de' }}>
                  Applicants List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Applicants Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Applicants Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.photoUrl ? data.photoUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="supportingFile" id={data.id} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Supporting File</Text>} 
              // extra={<Button key="1" className="btn-save-primary" type="primary" icon={<PlusOutlined />} onClick={() => this.showModal('add')}>Add</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data?.listDocument?.length > 0 ?
                      <React.Fragment>
                        {
                          data.listDocument.map((item, i) => (
                            <React.Fragment key={i}>
                              <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                    {item.documentTypeName}
                                  </Text>
                                  <Text style={{ fontSize: 14 }}>
                                    {item.documentNumber}
                                  </Text>
                                </Space>
                                <Space>
                                  <a href={item.fileUrl} target="_blank" rel='noreferrer'>
                                    <Tooltip title="Download">
                                      <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                    </Tooltip>
                                  </a>
                                  {/* <Tooltip title="Edit">
                                    <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => this.showModal('edit', item)} />
                                  </Tooltip>
                                  <Tooltip title="Remove">
                                    <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={() => this.handleDelete(item.id)} />
                                  </Tooltip> */}
                                </Space>
                              </div>
                              <Divider />
                            </React.Fragment>
                          ))
                        }
                      </React.Fragment>
                    :
                      <Empty description={<Text>No Data</Text>} />
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitAdd}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd}
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentAdd}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Update</Button>
            </React.Fragment>
          ]}
        >
          <EditDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef}
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentEdit}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailApplicants  : state.applicants.detail
})

const mapDispatchToProps = {
  actionGetDetail         : detailApplicants,
  unmountGetDetail        : unmountDetailApplicants,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDetailSupportingFile)
