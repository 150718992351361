import React, { Component } from 'react';
import DashboardAdmin from './Admin';
import DashboardAcademic from './Academic';
import DashboardLecturer from './Lecturer';
import DashboardPartner from './Partner';
import { connect } from 'react-redux';
// import { applicantSummary, jobOpeningStatus, jobOverview, pipelineHiring, unmountApplicantSummary, unmountJobOpeningStatus, unmountJobOverview, unmountPipelineHiring } from '../../redux/actions/dashboard/dashboardAction';

class Dashboard extends Component {
  render() {
    const { getAuth } = this.props;
    return (
      <React.Fragment>
        {
          getAuth.role === "ROLE_ADMIN" &&
          <DashboardAdmin />
        }
        {
          getAuth.role === "ROLE_ACADEMIC" &&
          <DashboardAcademic />
        }
        {
          getAuth.role === "ROLE_LECTURER" &&
          <DashboardLecturer />
        }
        {
          getAuth.role === "ROLE_PARTNER" &&
          <DashboardPartner/>
        }
       {/* {
          getAuth.role === "ROLE_AGENCY" &&
          <DashboardAgency {...this.props} />
        } */}
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    // const { unmountGetPipelineHiring, unmountGetApplicantSummary, unmountGetJobOverview, unmountGetJobOpeningStatus } = this.props;
    // unmountGetPipelineHiring()
    // unmountGetApplicantSummary()
    // unmountGetJobOverview()
    // unmountGetJobOpeningStatus()
  }
}

const mapStateToProps = (state) => ({
  getAuth               : state.authReducer,
  // getApplicantSummary   : state.dashboard.applicantSummary,
  // getPipelineHiring     : state.dashboard.pipelineHiring,
  // getJobOverview        : state.dashboard.jobOverview,
  // getJobOpeningStatus   : state.dashboard.jobOpeningStatus
})

const mapDispatchToProps = {
  // actionGetApplicantSummary   : applicantSummary,
  // actionGetPipelineHiring     : pipelineHiring,
  // actionGetJobOverview        : jobOverview,
  // actionGetJobOpeningStatus   : jobOpeningStatus,
  // unmountGetPipelineHiring    : unmountPipelineHiring,
  // unmountGetApplicantSummary  : unmountApplicantSummary,
  // unmountGetJobOverview       : unmountJobOverview,
  // unmountGetJobOpeningStatus  : unmountJobOpeningStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
