import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, modalParticipantReview, showModal, showModalNotes) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Link to="#" onClick={() => onPriviewProfile(record.applicantId)} style={{ color: '#0076de' }}>
          {record.participantNumber ? record.participantNumber : "N/A"}
        </Link>
        // <Text style={{ color: '#0076de' }}>{record.participantNumber ? record.participantNumber : "N/A"}</Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text style={{ color: '#0076de' }}>{record.applicantName ? record.applicantName : "N/A"}</Text>
      )
    },
    {
      title: 'Gender',
      key: 'name',
      fixed: 'gender',
      width: 30,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.gender ? record.gender === "MALE" ? "Male" : "Female" : "N/A"}</Text>
      )
    },
    {
      title: 'Age',
      key: 'age',
      width: 20,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.age ? record.age : "N/A"}</Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.institutionName ? record.institutionName : "N/A"}</Text>
      )
    },
    {
      title: 'Field of Study',
      key: 'fieldOfStudy',
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.fieldOfStudyName ? record.fieldOfStudyName : "N/A"}</Text>
      )
    },
    {
      title: '',
      key: 'action',
      width: 80,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Space direction="horizontal">
          <Button 
            type="primary" 
            ghost 
            onClick={() => modalParticipantReview(record.id)}
          >
            Process
          </Button>
          <Button 
            type="danger" 
            ghost 
            onClick={() => showModal(record.id)}
          >
            Reject
          </Button>
        </Space>
      )
    },
  ]
}