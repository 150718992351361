import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { HeaderTable } from '../../../components';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, Button, message } from 'antd';
import { disableLecturers, enableLecturers, listLecturers, unmountListLecturers } from '../../../redux/actions/admin/lecturers/lecturersAction';

class LecturerList extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta, url)
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(this.state.meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(this.state.meta)
    }, (err) => message.error(err))
  }

  onClickRow = (record) => {
    this.props.history.push(`/lecturers/detail/${record.id}/biodata`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/lecturers/edit/${id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  
  render() {
    const { selectedRowKeys } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Lecturers</Breadcrumb.Item>
              <Breadcrumb.Item>Lecturers List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Lecturer List' 
              extra={[
                <Link key="1" to="/lecturers/create">
                  <Button className="btn-create-primary" type="primary" icon={<PlusOutlined />} ghost>
                    Create Lecturer
                  </Button>
                </Link>
                ,
              ]}
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Register Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Name, Email"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}  
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              dataSource={data} columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable, this.onClickRow)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.lecturers.list
})

const mapDispatchToProps = {
  actionGetData   : listLecturers,
  actionEnable    : enableLecturers,
  actionDisable   : disableLecturers,
  unmountGetData  : unmountListLecturers
}

export default connect(mapStateToProps, mapDispatchToProps)(LecturerList)
