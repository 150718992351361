import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContentParticipant from './Content';
import HeaderDetailJob from '../Header';
import ListApplicant from '../ListApplicant';
import ViewProfile from '../Profile';
import ModalDetail from './ModalDetail';
import ModalEdit from './ModalEdit';
import { Loading } from '../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Modal, message, Empty, Input, AutoComplete, Button } from 'antd';
import { detailPrograms, listParticipant, detailParticipant, confirmAssessment, updateAssessment, unmountDetailPrograms, unmountListParticipant, unmountDetailParticipant } from '../../../../../redux/actions/admin/programs/programsAction';
import { detailApplicants, unmountDetailApplicants } from '../../../../../redux/actions/admin/applicants/applicantsAction';

import MenuDetail from '../MenuDetail';

const { Text } = Typography;
const { confirm } = Modal;

class DetailParticipantPrograms extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isMethod: true,
      isResult: true,
      checked: true,
      visible: false,
      visibleModalDetail: false,
      visibleModalEdit: false,
      valueModalEdit: null,
      listData: [],
      valueForm: null,
      idApplicant: null,
      dataParticipant: null,
      documentName: null,
      isModalNotes: false,
      meta: {
        page: 1,
        perpage: 10,
        programId: ''
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetParticipant, actionGetDetail, match: { params } } = this.props;
    meta.programId = Number(params.id)
    actionGetParticipant(meta, (response) => {
      this.setState({ listData: response.data })
    })
    actionGetDetail(params.id)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  selectedApplicant = (value) => {
    const { actionDetailParticipant } = this.props;
    this.setState({ idApplicant: value.id, dataParticipant: value, isResult: true, isMethod: true })
    actionDetailParticipant(value.id)
  }

  onFinish = (values) => {
    const { actionConfirmAssessment, actionDetailParticipant, actionUpdateAssessment, getDetailParticipant } = this.props;
    const { valueModalEdit } = this.state
    values.id = valueModalEdit.id
    values.letterGrade = values.score >= 85 ? 'A' : values.score >= 75 ? 'B' : values.score >= 60 ? 'C' : values.score >= 50 ? 'D' : 'E'
    this.setState({ submitLoading: true })
    if(valueModalEdit.status === "CONFIRMED"){
      return actionUpdateAssessment(values, () => {
        message.success('Success confirm assessment')
        this.setState({ visibleModalEdit: false, submitLoading: false })
        actionDetailParticipant(getDetailParticipant.data.id)
      }, 
      (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    }else{
      return actionConfirmAssessment(values, () => {
        message.success('Success confirm assessment')
        this.setState({ visibleModalEdit: false, submitLoading: false })
        actionDetailParticipant(getDetailParticipant.data.id)
      }, 
      (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    }
    
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetParticipant } = this.props;
    meta.search = value === '' ? null : value
    return actionGetParticipant(meta, (response) => {
      this.setState({ listData: response.data })
    })
  }

  onPriviewProfile = (applicantId, id) => {
    const { actionGetProfile, actionDetailParticipant } = this.props;
    return actionGetProfile(applicantId, () => {
      actionDetailParticipant(id)
      this.setState({ visible: true })
    })
  }

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  showModalEdit = (val) => {
    this.setState({ visibleModalEdit: true, valueModalEdit: val })
  }

  showModalDetail = (val) => {
    this.setState({ visibleModalDetail: true, valueModalEdit: val })
  }

  onCancel = () => {
    this.setState({ visible: false, visibleModalDetail: false, visibleModalEdit: false })
  }

  // onSubmit = () => {
  //   const { valueForm, meta, url } = this.state;
  //   const { actionAddInterview, actionGetParticipant } = this.props;
  //   this.setState({ submitLoading: true })
  //   return actionAddInterview(valueForm, () => {
  //     this.setState({ visible: false, submitLoading: false })
  //     message.success('Successfully moved')
  //     actionGetParticipant(meta, url, (response) => {
  //       this.setState({ listData: response.data, dataParticipant: null })
  //     })
  //   }, (err) => {
  //     this.setState({ submitLoading: false }, () => {
  //       message.error(err)
  //     })
  //   })
  // }

  onFinishNotes = value => {
    const { meta, url, idApplicant } = this.state
    const { actionAddApplicantNotes, actionGetParticipant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetParticipant(meta, url, (response) => {
        this.setState({ listData: response.data, dataParticipant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  }

  handlePagination = (e) => {
    const { meta, url } = this.state;
    const { actionGetParticipant } = this.props;
    meta.page = e
    actionGetParticipant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  render() {
    const { checked, idApplicant, dataParticipant, listData, visible, visibleModalDetail, visibleModalEdit, valueModalEdit, submitLoading } = this.state;
    const { getData: { data, loading }, getParticipant, getDetailParticipant } = this.props;
    if(loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Programs</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/programs">Programs List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.programNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Program Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <MenuDetail rowKey="participants" id={data.id} />
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Participants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <AutoComplete
                    onSearch={this.onSearch} 
                    style={{ width: '100%' }} 
                    allowClear
                  >
                    <Input 
                      prefix={<SearchOutlined />} 
                      style={{ width: '28%'}} 
                      placeholder="Search by Number, Applicant Name" 
                    />
                  </AutoComplete>
                </Col>
                <Col span={24}>
                  <Text>Showing {getParticipant.pagination.total} Data</Text>
                </Col>
                <Col span={6}>
                  <ListApplicant 
                    loading={getParticipant.loading} 
                    pagination={getParticipant.pagination}
                    dataSource={listData}
                    idApplicant={idApplicant}
                    selectedApplicant={(value) => this.selectedApplicant(value)}
                    handlePagination={(e) => this.handlePagination(e)}
                  />
                </Col>
                <Col span={18}>
                  <Card>
                    {
                      dataParticipant ? 
                      <ContentParticipant 
                        dataParticipant={dataParticipant}
                        detailParticipant={getDetailParticipant}
                        onPriviewProfile={this.onPriviewProfile}
                        showModalEdit={this.showModalEdit}
                        showModalDetail={this.showModalDetail}
                      />
                      : 
                      <Empty description={false} />
                    }
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Detail"
          onCancel={this.onCancel}
          footer={<Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>}
        >
          <ViewProfile {...this.props} />
          {/* <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <div ref={(response) => (this.componentRef = response)}>
            </div>
          </div> */}
        </Modal>
        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleModalDetail}
          onCancel={this.onCancel}
          title="Detail Assessment"
          footer={false}
        >
          <ModalDetail 
            valueModalEdit={valueModalEdit}
          />
        </Modal>
        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleModalEdit}
          onCancel={this.onCancel}
          title="Input Assessment"
          footer={false}
        >
          <ModalEdit 
            formRef={this.formRef} 
            valueModalEdit={valueModalEdit}
            onFinish={this.onFinish}
            submitLoading={submitLoading}
            getDetailParticipant={getDetailParticipant.data}
            // {...this.props} 
            // {...this.state}
            // checkEmail={this.checkEmail}
            // checkPhone={this.checkPhone}
            // handleChangeCountry={this.handleChangeCountry}
            // handleChangeProvince={this.handleChangeProvince}
            // handleChangeCity={this.handleChangeCity}
            // handleChangeSubDistrict={this.handleChangeSubDistrict}
            // onSubmitInformation={this.onSubmitInformation}
            // handleChangeCallingCode={this.handleChangeCallingCode}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetParticipant, unmountDetailParticipant } = this.props;
    unmountGetDetail()
    unmountGetParticipant()
    unmountDetailParticipant()
  }
}

const mapStateToProps = (state) => ({
  getData               : state.programs.detail,
  getParticipant        : state.programs.participant,
  getDetailParticipant  : state.programs.detailParticipant,
  getProfile            : state.applicants.detail
})

const mapDispatchToProps = {
  actionGetDetail             : detailPrograms,
  actionGetParticipant        : listParticipant,
  actionDetailParticipant     : detailParticipant,
  actionGetProfile            : detailApplicants,
  actionConfirmAssessment     : confirmAssessment,
  actionUpdateAssessment      : updateAssessment,
  unmountGetParticipant       : unmountListParticipant,
  unmountGetDetail            : unmountDetailPrograms,
  unmountDetailParticipant    : unmountDetailParticipant,
  unmountGetProfile           : unmountDetailApplicants
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailParticipantPrograms)