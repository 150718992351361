import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, AutoComplete } from 'antd';

export default class FilterDetailHired extends Component {

  handleRangePicker = (dates, dateStrings) => {
    const { onRangePicker } = this.props;
    const date = {
      'startDate': dateStrings[0],
      'endDate': dateStrings[1]
    }
    return onRangePicker(date)
  }

  handleChangeStatus = (e) => {
    const { onStatus } = this.props;
    return onStatus(e)
  }

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }

  render() {
    // const { tooltopRangePicker, fase1 } = this.props
    return (
      <React.Fragment>
        <Row gutter={8}>
          {/* <Tooltip title={tooltopRangePicker}>
            <Col span={5}>
              <RangePicker
                onChange={this.handleRangePicker}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }}
              />
            </Col>
          </Tooltip> */}
          {/* {
            fase1 &&
            <Col span={4}>
              <Tooltip title="Filter by Last Stage">
                <Select
                  defaultValue={fase1.value[0].value}
                  onChange={this.handleChangeStatus}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          } */}
          <Col span={6}>
            <AutoComplete 
              onSearch={this.handleChangeSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder="Search by Name"  />
            </AutoComplete>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
