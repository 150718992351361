import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, PhoneOutlined, BulbOutlined, ContainerOutlined  } from '@ant-design/icons';

export default function index(props) {
  return (
    <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
      <Menu.Item key="biodata" icon={<UserOutlined />}>
        <Link to={`/academic/lecturers/detail/${props.id}/biodata`}>
          Biodata
        </Link>
      </Menu.Item>
      <Menu.Item key="lecturerInformation" icon={<BulbOutlined />}>
        <Link to={`/academic/lecturers/detail/${props.id}/lecturer-information`}>
          Lecturer Information
        </Link>
      </Menu.Item>
      <Menu.Item key="contactInformation" icon={<PhoneOutlined />}>
        <Link to={`/academic/lecturers/detail/${props.id}/contact-information`}>
          Contact Information
        </Link>
      </Menu.Item>
      <Menu.Item key="supervisingLecturer" icon={<ContainerOutlined/>}>
        <Link to={`/academic/lecturers/detail/${props.id}/supervising-lecturer`}>
          Supervising Lecturer
        </Link>
      </Menu.Item>
    </Menu>
  )
}