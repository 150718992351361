import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ViewProfile from '../../Profile';
import ContentReview from './Content';
import HeaderDetailJob from '../../Header';
import ListApplicant from '../../ListApplicant';
import { Loading } from '../../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { masterCourses, unmountMasterCourses } from '../../../../../../redux/actions/admin/courses/coursesAction';
import { masterLecturers, unmountMasterLecturers } from '../../../../../../redux/actions/admin/lecturers/lecturersAction';
import { detailPrograms, listApplicant, detailParticipant, participantAccept, rejectApplicant, unmountDetailPrograms, unmountListApplicant, unmountDetailParticipant } from '../../../../../../redux/actions/admin/programs/programsAction';
import { detailApplicants, unmountDetailApplicants } from '../../../../../../redux/actions/admin/applicants/applicantsAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Modal, message, Empty, Input, Button, AutoComplete } from 'antd';
import MenuDetail from '../../MenuDetail';
import MenuPhase from '../MenuPhase';
// import ApplicantNotes from '../../ApplicantNotes';
const { Text } = Typography;
const { confirm } = Modal;

class DetailReviewPrograms extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isMethod: true,
      isResult: true,
      checked: true,
      visible: false,
      visibleProfile: false,
      listData: [],
      valueForm: null,
      idApplicant: null,
      dataApplicant: null,
      documentName: null,
      isModalNotes: false,
      meta: {
        page: 1,
        perpage: 10,
        programId: '',
        status: 'REVIEWED'
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, } = this.state;
    const { actionGetApplicant, actionGetDetail, match: { params } } = this.props;
    meta.programId = Number(params.id)
    actionGetApplicant(meta, (response) => {
      this.setState({ listData: response.data })
    })
    actionGetDetail(params.id)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.key) {
      case "applied":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/applied`);
      case "review":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/review`);
      case "accepted":
        return this.props.history.push(`/programs/detail/${data.id}/applicants/accepted`);
      default:
        break;
    }
  }

  selectedApplicant = (value) => {
    // const { actionGetProfile, actionListApplicantNotes } = this.props;
    const { actionDetailParticipant, actionGetCourse, actionGetLecture } = this.props;
    this.setState({ idApplicant: value.id, dataApplicant: value, isResult: true })
    // actionGetProfile(value.candidateId)
    // actionListApplicantNotes(value.id)
    actionDetailParticipant(value.id)
    actionGetCourse()
    actionGetLecture()
  }

  handleChangeResult = (e) => {
    this.formRef.current.setFieldsValue({
      isMethod: true,
      notes: null,
    });
    this.setState({ isResult: e.target.value })
  }

  onFinish = (values) => {
    const { meta, idApplicant } = this.state;
    const { actionParticipantAccept, actionReject, actionGetApplicant } = this.props;
    const self = this;
    values.id = idApplicant
    if(values.isResult){
      delete values.isResult
      return actionParticipantAccept(values, () => {
        this.setState({ submitLoading: false })
        message.success('Successfully move to accept')
        actionGetApplicant(meta, (response) => {
          this.setState({ listData: response.data, dataApplicant: null, submitLoading: false })
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    }else {
      delete values.isResult
      return actionReject(values, () => {
        message.success('Successfully moved')
        actionGetApplicant(meta, (response) => {
          self.setState({ listData: response.data, dataApplicant: null })
        }, (err) => message.error(err))
      })
    }
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetApplicant } = this.props;
    meta.search = value === '' ? null : value
    return actionGetApplicant(meta, (response) => {
      this.setState({ listData: response.data })
    })
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  onCancel = () => {
    this.setState({ visible: false })
  }

  onFinishNotes = value => {
    const { meta, url, idApplicant } = this.state
    const { actionAddApplicantNotes, actionGetApplicant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetApplicant(meta, url, (response) => {
        this.setState({ listData: response.data, dataApplicant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  }

  handlePagination = (e) => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.page = e
    actionGetApplicant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  render() {
    const { checked, idApplicant, dataApplicant, isMethod, isResult, listData, visible, submitLoading } = this.state;
    const { getData: { data, loading }, getApplicant, getApplicantNotes, getDetailParticipant, getDataCourse, getDataLecture } = this.props;
    if(loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Programs</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/programs">Programs List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.programNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Program Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <MenuDetail rowKey="applicants" id={data.id} />
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <MenuPhase rowKey="review" data={data} handleMenuPhase={(item) => this.handleMenuPhase(item)} />
                </Col>
                <Col span={24}>
                  <AutoComplete
                    onSearch={this.onSearch} 
                    style={{ width: '100%' }} 
                    allowClear
                  >
                    <Input 
                      prefix={<SearchOutlined />} 
                      style={{ width: '28%'}} 
                      placeholder="Search by Applicant Name" 
                    />
                  </AutoComplete>
                </Col>
                <Col span={24}>
                  <Text>Showing {getApplicant.pagination.total} Data</Text>
                </Col>
                <Col span={6}>
                  <ListApplicant 
                    loading={getApplicant.loading} 
                    pagination={getApplicant.pagination}
                    dataSource={listData}
                    idApplicant={idApplicant}
                    selectedApplicant={(value) => this.selectedApplicant(value)}
                    // handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()} 
                    handlePagination={(e) => this.handlePagination(e)}
                  />
                </Col>
                <Col span={18}>
                  <Card>
                    {
                      dataApplicant ? 
                      <ContentReview 
                        dataApplicant={dataApplicant}
                        formRef={this.formRef} 
                        isResult={isResult} 
                        isMethod={isMethod} 
                        onFinish={this.onFinish} 
                        onPriviewProfile={this.onPriviewProfile}
                        handleChangeResult={(e) => this.handleChangeResult(e)}
                        handleChangeMethod={(e) => this.handleChangeMethod(e)}
                        showModalNotes={this.showModalNotes}
                        getApplicantNotes={getApplicantNotes}
                        detailParticipant={getDetailParticipant}
                        getDataCourse={getDataCourse}
                        getDataLecture={getDataLecture}
                        submitLoading={submitLoading}
                      />
                      : 
                      <Empty description={false} />
                    }
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={<Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>}
        >
          <ViewProfile {...this.props} />
          {/* <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <div ref={(response) => (this.componentRef = response)}>
            </div>
          </div> */}
        </Modal>
        {/* <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal> */}
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountDetailParticipant, unmountMasterCourses, unmountMasterLecturers, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountGetApplicant()
    unmountDetailParticipant()
    unmountMasterCourses()
    unmountMasterLecturers()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData               : state.programs.detail,
  getApplicant          : state.programs.applicant,
  getDetailParticipant  : state.programs.detailParticipant,
  getDataCourse         : state.courses.master,
  getDataLecture        : state.lecturers.master,
  getProfile            : state.applicants.detail,
})

const mapDispatchToProps = {
  actionGetDetail             : detailPrograms,
  actionGetApplicant          : listApplicant,
  actionDetailParticipant     : detailParticipant,
  actionGetCourse             : masterCourses,
  actionGetLecture            : masterLecturers,
  actionParticipantAccept     : participantAccept,
  actionReject                : rejectApplicant,
  actionGetProfile            : detailApplicants,
  unmountGetApplicant         : unmountListApplicant,
  unmountGetDetail            : unmountDetailPrograms,
  unmountDetailParticipant    : unmountDetailParticipant,
  unmountMasterCourses        : unmountMasterCourses,
  unmountMasterLecturers      : unmountMasterLecturers,
  unmountGetProfile           : unmountDetailApplicants,



}

export default connect(mapStateToProps, mapDispatchToProps)(DetailReviewPrograms)