import { API } from '../../../../../config';
import { errorHandler } from '../../../auth/errorAction';

export const unmountListProvinces = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PROVINCES'})
}

export const unmountDetailProvinces = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PROVINCES'})
}

export const unmountMasterProvinces = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_PROVINCES'})
}

export const listProvinces = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PROVINCES' })
  return API.GET('/province/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_PROVINCES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_PROVINCES_FAILED' }), 
    ))
  })
}

export const detailProvince = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_PROVINCES' })
  const params = {
    'id': id
  }
  API.GET('/province/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_PROVINCES_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_PROVINCES_FAILED' }), 
      ))
    )
  })
}

export const masterProvinces = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_PROVINCES' })
  return API.GET('/province/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_PROVINCES_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_PROVINCES_FAILED' }), 
    ))
  })
}

export const provinceByCountry = (id, successCB, failedCB) => async (dispatch) => {
  const params = { 'id': id }
  API.GET('/province/getByCountryId', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(err))
    )
  })
}

export const createProvince = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/province/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editProvince = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/province/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableProvince = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/province/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableProvince = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/province/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}