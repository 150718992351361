import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { FileTextFilled } from '@ant-design/icons';
import {PageHeader, Card, Row, Col,Tag, Descriptions, Typography, Breadcrumb, Switch, message } from 'antd';
import { detailFile, enableFile, disableFile, unmountDetailFile} from '../../../../redux/actions/admin/file/fileAction';
import moment from 'moment';
const { Text } = Typography;
// const { confirm } = Modal;

class DetailFile extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      checked: true
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id)
  }

  handleChangeStatus = (val) => {
    const { actionDisable, actionEnable, actionGetDetail, match: { params } } = this.props;
    if(val){
      return actionEnable(params.id, () => {
        message.success('Successfully changed to Published')
        return actionGetDetail(params.id)
      }) 
    }else{
      return actionDisable(params.id, () => {
        message.success('Successfully changed to Unpublished')
        return actionGetDetail(params.id)
      }) 
    }
  }

  render() {
    const { getData: { data, loading } } = this.props;
  
    if(loading){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><FileTextFilled /> File Management</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/file">File List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.code ? data.code : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='File Detail' />
          </Col>

          {/* Card Header */}


          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={16}>
                <Col span={24} style={{ marginBottom: 16 }}>
                  {
                    data.enabled ?
                      <Tag color="green">Enabled: Published</Tag> 
                    :
                      <Tag color="red">Enabled: Unpublished</Tag> 

                  }
                  <Switch defaultChecked={data.enabled} onChange={this.handleChangeStatus} />
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">File Code</Text>}>
                      {data.code ? data.code : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">File Name</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">File Attachment</Text>}>
                      {
                        data.fileAttachment ?
                          <a href={data.fileUrl} target="_blank" rel='noreferrer'>
                            {data.fileAttachment}
                          </a>
                        :
                          'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Published Date</Text>}>
                    {data.publishedDate ? moment(data.publishedDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null} {data.publishedTime ? moment(data.publishedTime, 'HH:mm:ss').format('HH:mm:ss') : null}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Description</Text>}>
                      {data.description ? data.description : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Downloader</Text>}>
                      {data.downloader ? data.downloader : 0}
                    </Descriptions.Item>
                    
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.file.detail,
})

const mapDispatchToProps = {
  actionGetDetail   : detailFile,
  actionEnable   : enableFile,
  actionDisable  : disableFile,
  unmountGetDetail  : unmountDetailFile,
 
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailFile)
