import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PARTNERS_PROGRAMS'})
}

export const unmountDetailProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PARTNERS_PROGRAMS'})
}

export const unmountListApplicant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_PARTNERS_PROGRAMS_LIST_APPLICANT'})
}

export const unmountListParticipant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_PARTNERS_PROGRAMS_LIST_PARTICIPANT'})
}

export const unmountDetailParticipant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_PARTNERS_PROGRAMS_DETAIL_PARTICIPANT'})
}

export const listProgram = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PARTNERS_PROGRAMS' })
  return API.GET(`/partner/listProgram`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_PARTNERS_PROGRAMS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_PARTNERS_PROGRAMS_FAILED' }), 
    ))
  })
}

export const detailProgram = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_PARTNERS_PROGRAMS' })
  const params = {
    'id': id
  }
  API.GET(`/program/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_PARTNERS_PROGRAMS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_PARTNERS_PROGRAMS_FAILED' }), 
      ))
    )
  })
}

export const activeProgram = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS(`/program/enable`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const inactiveProgram = (value, successCB, failedCB) => () => {
  const params = {
    id: value,
  }
  API.POST_WITH_PARAMS(`/program/disable`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const listParticipant = (meta, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_PARTNERS_PROGRAMS_LIST_PARTICIPANT' })
  return API.GET(`/participant/listParticipant`, meta).then((response) => {
    dispatch({
      type: 'LOAD_PARTNERS_PROGRAMS_LIST_PARTICIPANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_PARTNERS_PROGRAMS_LIST_PARTICIPANT_FAILED' }), 
    ))
  })
}

export const detailParticipant = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_PARTNERS_PROGRAMS_DETAIL_PARTICIPANT' })
  const params = {
    'id': id
  }
  API.GET(`/applicant/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_PARTNERS_PROGRAMS_DETAIL_PARTICIPANT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_PARTNERS_PROGRAMS_DETAIL_PARTICIPANT_FAILED' }), 
      ))
    )
  })
}

// export const participantReview = (id, successCB, failedCB) => () => {
//   const params = {
//     id: id
//   }
//   API.POST_WITH_PARAMS('/participant/review', params).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }

// export const participantAccept = (value, successCB, failedCB) => () => {
//   API.POST_NO_PARAMS('/participant/accept', value).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }

// export const rejectApplicant = (value, successCB, failedCB) => () => {
//   API.POST_NO_PARAMS('/participant/reject', value).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }

// export const confirmAssessment = (value, successCB, failedCB) => () => {
//   API.POST_NO_PARAMS('/participant/assessment/confirm', value).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }

// export const updateAssessment = (value, successCB, failedCB) => () => {
//   API.POST_NO_PARAMS('/participant/assessment/update', value).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }