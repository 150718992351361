import React from 'react'
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

export default function index(props) {
  return (
    <Menu defaultSelectedKeys={[props.rowKey]} mode="horizontal" style={{ background: 'none'  }}>
      <Menu.Item key="detailInformation">
        <Link to={`/lecturers/programs/detail/${props.id}/information`}>Detail Information</Link>
      </Menu.Item>
      <Menu.Item key="participants">
        <Link to={`/lecturers/programs/detail/${props.id}/participant`}>Participants</Link>
      </Menu.Item>
    </Menu>
  )
}
