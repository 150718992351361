import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Menu } from 'antd';
import { FlagOutlined, ApartmentOutlined, EnvironmentOutlined, PushpinOutlined, NumberOutlined, FileTextOutlined,ReadOutlined, BankOutlined, GroupOutlined, ClusterOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Card >
      <Menu defaultSelectedKeys={[`${props.rowKey}`]}>
        <Menu.Item key="countries" icon={<FlagOutlined />}>
          <Link to="/master/countries">Countries</Link>
        </Menu.Item>
        <Menu.Item key="provinces" icon={<ApartmentOutlined />}>
          <Link to="/master/provinces">Provinces</Link>
        </Menu.Item>
        <Menu.Item key="cities" icon={<EnvironmentOutlined />}>
          <Link to="/master/cities">Cities</Link>
        </Menu.Item>
        <Menu.Item key="subDistricts" icon={<PushpinOutlined />}>
          <Link to="/master/sub-districts">Sub-Districts (Kecamatan)</Link>
        </Menu.Item>
        <Menu.Item key="villages" icon={<NumberOutlined />}>
          <Link to="/master/villages">Villages (Kelurahan)</Link>
        </Menu.Item>
        <Menu.Item key="industries" icon={<GroupOutlined />}>
          <Link to="/master/industries">Industries</Link>
        </Menu.Item>
        <Menu.Item key="positions" icon={<ClusterOutlined />}>
          <Link to="/master/positions">Positions</Link>
        </Menu.Item>
        <Menu.Item key="fieldOfStudy" icon={<ReadOutlined />}>
          <Link to="/master/fields-of-study">Fields Of Study</Link>
        </Menu.Item>
        <Menu.Item key="institutions" icon={<BankOutlined />}>
          <Link to="/master/institutions">Institutions</Link>
        </Menu.Item>
        <Menu.Item key="13" icon={<FileTextOutlined />}>
          <Link to="/master/document-types">Document Types</Link>
        </Menu.Item>
      </Menu>
    </Card>
  )
}

