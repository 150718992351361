import React from 'react';
// import { Link } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, modalParticipantReview, showModal, showModalNotes) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        // <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
        //   {record.participantNumber ? record.participantNumber : "N/A"}
        // </Link>
        <Text style={{ color: '#0076de' }}>{record.participantNumber ? record.participantNumber : "N/A"}</Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text style={{ color: '#0076de' }}>{record.applicantName ? record.applicantName : "N/A"}</Text>
      )
    },
    {
      title: 'Accepted Date',
      key: 'acceptedDate',
      fixed: 'gender',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.acceptedDate ? record.acceptedDate : "N/A"}</Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.institutionName ? record.institutionName : "N/A"}</Text>
      )
    },
    {
      title: 'Field of Study',
      key: 'fieldOfStudy',
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.fieldOfStudyName ? record.fieldOfStudyName : "N/A"}</Text>
      )
    },
    {
      title: 'View',
      key: 'action',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space direction="horizontal">
          <Button 
            type="primary" 
            ghost 
            onClick={() => onPriviewProfile(record.applicantId, record.id)}
          >
            Detail
          </Button>
        </Space>
      )
    },
  ]
}