import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import HeaderDetailJob from '../Header';
import ViewProfile from '../Profile';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Link } from 'react-router-dom';
import { FilterDetailHired, Loading } from '../../../../../components';
import { ContainerOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, PageHeader, Typography, Modal, message, Card, Table, Pagination, Button } from 'antd';
// import { activeApplicant, detailJob, inactiveJob, listRejected, unmountDetailJobs, unmountListRejected } from '../../../../../redux/actions/programs/programsAction';
import { detailPrograms, listApplicant, unmountDetailPrograms, unmountListApplicant } from '../../../../../redux/actions/admin/programs/programsAction';
import { detailApplicants, unmountDetailApplicants } from '../../../../../redux/actions/admin/applicants/applicantsAction';
import MenuDetail from '../MenuDetail';
const { Text } = Typography;
const { confirm } = Modal;

class AcademicDetailRejectedPrograms extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      checked: true,
      visible: false,
      meta: {
        page: 1,
        perpage: 10,
        programId: '',
        status: 'REJECTED'
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state; 
    const { actionListApplicant, actionGetDetail, match: { params } } = this.props;
    meta.programId = Number(params.id)
    actionListApplicant(meta)
    actionGetDetail(params.id)
  }

  onRangePicker = value => {
    const { meta } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionListApplicant(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.phaseId = value
    return actionListApplicant(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionListApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value
    return actionListApplicant(meta)
  }

  pagination = (page, perpage) => {
    const { actionListApplicant } = this.props;
    const { meta } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionListApplicant(meta)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleActiveApplicant = (id) => {
    const { meta } = this.state;
    const { actionActiveApplicant, actionListApplicant } = this.props;
    confirm({
      title: 'This application will be back processed to the last stage.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionActiveApplicant(id, () => {
          message.success('Successfully moved')
          return actionListApplicant(meta)
        })
      },
    });
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  showRejectReason = (val) => {
    Modal.info({
      title: 'Rejection reason:',
      content: (
        <div dangerouslySetInnerHTML={{ __html: val }}></div>
      ),
      onOk() {},
    });
  }

  render() {
    const { checked, visible } = this.state;
    const { getData: { data, loading }, getApplicant } = this.props;

    if(loading){
      return <Loading />
    }

    const defaultStatusFilter = [
      { value: -1, name: 'All' },
      { value: '', name: 'Applied' },
      { value: 1, name: 'Qualified' },
      { value: 2, name: 'Interview' },
      { value: 3, name: 'Offered' },
      { value: 4, name: 'Hired' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContainerOutlined /> Programs</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/programs">Programs List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.programNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Program Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <MenuDetail rowKey="rejected" id={data.id} />
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Rejected Applicants">
              <Row gutter={[16, 24]}>
                {/* Filter */}
                <Col span={24}>
                  <FilterDetailHired 
                    onSearch={this.onSearch} 
                    onStatus={this.onStatus} 
                    onRangePicker={this.onRangePicker}
                    fase1={{ key: 'type', value: defaultStatusFilter}}
                    tooltopRangePicker='Filter by Rejected Date'
                  />
                  </Col>
                {/* Table */}
                <Col span={24}>
                  <Table 
                    style={{ marginBottom: 20 }}
                    size="small"
                    rowKey={(i) => i.id} 
                    dataSource={getApplicant.data} 
                    loading={getApplicant.loading} 
                    columns={columns(this.onPriviewProfile, this.handleActiveApplicant, this.showRejectReason)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getApplicant.pagination.total}
                    onChange={this.pagination}
                    current={getApplicant.pagination.page}
                    pageSize={getApplicant.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={<Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>}
        >
          <ViewProfile {...this.props} />
          {/* <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <div ref={(response) => (this.componentRef = response)}>
            </div>
          </div> */}
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountListApplicant, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountListApplicant()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.programs.detail,
  getApplicant    : state.programs.applicant,
  getProfile      : state.applicants.detail,
})

const mapDispatchToProps = {
  actionGetDetail          : detailPrograms,
  actionListApplicant      : listApplicant,
  actionGetProfile         : detailApplicants,
  unmountGetDetail         : unmountDetailPrograms,
  unmountListApplicant     : unmountListApplicant,
  unmountGetProfile        : unmountDetailApplicants,
}

export default connect(mapStateToProps, mapDispatchToProps)(AcademicDetailRejectedPrograms)
