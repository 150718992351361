import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import svg from '../../../../assets/img/profile.svg';
import { Loading } from '../../../../components';
import { BankOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions,Space, Typography, Breadcrumb, Avatar, Tooltip, Tabs, Timeline, Divider, Empty, Tag, Modal } from 'antd';
import { detailParticipant, unmountDetailParticipant, detailParticipantInformation, unmountDetailParticipantInformation, detailParticipantFollowed, unmountDetailParticipantFollowed, detailParticipantPrograms, unmountDetailParticipantPrograms } from '../../../../redux/actions/admin/participants/participantsAction';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { programCategory } from '../../../../utils/constant/programCategory';
import ModalDetail from './ModalDetail';

const { Text, Paragraph } = Typography

class AcademicDetailParticipant extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visibleModalDetail: false,
      valueModalEdit: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, actionGetDetailInformation, actionGetDetailFollowed, actionGetDetailPrograms, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {  
      actionGetDetailInformation(response.data.applicantId)
      actionGetDetailFollowed(response.data.applicantId)
      actionGetDetailPrograms(response.data.applicantId)
    })
  }
  
  showModalDetail = (val) => {
    this.setState({ visibleModalDetail: true, valueModalEdit: val })
  }

  onCancel = () => {
    this.setState({ visibleModalDetail: false, valueModalEdit: null })
  }

  render() {
    const { visibleModalDetail, valueModalEdit } = this.state
    const { getData: { data, loading }, getDataInformation, getDataFollowed, getDataPrograms } = this.props;
  
    if(loading || getDataInformation.loading || getDataFollowed.loading || getDataPrograms.loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Participants</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/participants">Participants List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.participantNumber ? data.participantNumber : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Participants Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
              <Col span={24}>
                  <Text style={{fontSize : 17, color: '#242424'}}>Personal Information</Text>
              </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
                      <Space direction="vertical">
                      <Avatar size={100} src={getDataInformation.data.photoUrl ? getDataInformation.data.photoUrl : svg} />
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {getDataInformation.data.about ? getDataInformation.data.about : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name</Text>}>
                      {getDataInformation.data.firstName} {getDataInformation.data.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {getDataInformation.data.nationality ? getDataInformation.data.nationality : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {getDataInformation.data.gender ? getDataInformation.data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Marital Status</Text>}>
                      {
                        getDataInformation.data.marital ?
                          <Text>
                            {getDataInformation.data.marital === 'BELUM_KAWIN' && 'Belum Kawin'}
                            {getDataInformation.data.marital === 'KAWIN' && 'Kawin'}
                            {getDataInformation.data.marital === 'CERAI_HIDUP' && 'Cerai Hidup'}
                            {getDataInformation.data.marital === 'CERAI_MATI' && 'Cerai Mati'}
                          </Text>
                        : 
                          'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                    {getDataInformation.data.birthplace ? getDataInformation.data.birthplace : 'N/A'}, {getDataInformation.data.birthdate ? moment(getDataInformation.data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {
                        getDataInformation.data.religion ?
                        <Text>
                          {getDataInformation.data.religion === 'ISLAM' && 'Islam'}
                          {getDataInformation.data.religion === 'CATHOLIC' && 'Catholic'}
                          {getDataInformation.data.religion === 'PROTESTAN' && 'Protestan'}
                          {getDataInformation.data.religion === 'BUDDHA' && 'Buddha'}
                          {getDataInformation.data.religion === 'HINDU' && 'Hindu'}
                          {getDataInformation.data.religion === 'OTHERS' && 'Others'}
                        </Text>
                        : 
                        'N/A'
                      } 
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Height</Text>}>
                      {getDataInformation.data.height ? getDataInformation.data.height : 'N/A'} 
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">ID Card Number (KTP)</Text>}>
                      {getDataInformation.data.nik ? getDataInformation.data.nik : 'N/A'} 
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Weight</Text>}>
                      {getDataInformation.data.weight ? getDataInformation.data.weight : 'N/A'} 
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Blood Type</Text>}>
                      {getDataInformation.data.bloodType ? getDataInformation.data.bloodType : 'N/A'} 
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col span={24}>
                    <Text style={{fontSize : 17, color: '#242424'}}>Education Information </Text>
                </Col>
                <Col>
                  <Descriptions layout="vertical" column={2} size="small">
                  <Descriptions.Item label={<Text type="secondary">Origin University</Text>}>
                      {getDataInformation.data.institutionName ? getDataInformation.data.institutionName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Semester</Text>}>
                      {getDataInformation.data.semester ? getDataInformation.data.semester : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Field Of Study</Text>}>
                      {getDataInformation.data.fieldOfStudyName ? getDataInformation.data.fieldOfStudyName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">SKS Taken</Text>}>
                      {getDataInformation.data.sksTaken ? getDataInformation.data.sksTaken : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">NIM</Text>}>
                      {getDataInformation.data.nim ? getDataInformation.data.nim : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">GPA</Text>}>
                      {getDataInformation.data.gpa ? getDataInformation.data.gpa : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col span={24}>
                    <Text style={{fontSize : 17, color: '#242424'}}>Followed Program </Text>
                </Col>
                <Col>
                  {
                    getDataFollowed.data?.map((item,i) => 
                    <React.Fragment key={i}>
                      <Descriptions layout="vertical" column={2} size="small">
                        <Descriptions.Item label={<Text type="secondary">Program Kode</Text>}>
                          {item.programNumber ? item.programNumber : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Program Name</Text>}>
                          {item.title ? item.title : 'N/A'} 
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Program Category</Text>}>
                          {item.programCategory ? programCategory.find(res => res.value === item.programCategory)?.name : 'N/A'} 
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Participant Type</Text>}>
                          {item.isGroup ? "Kelompok" : "Individu"}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider/>
                    </React.Fragment>
                     )
                  }
                </Col>
                
                <Col span={24}>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Detail Information" key="1">
                      <Row style={{ marginBottom: 16 }}>
                        <Text style={{ fontSize: 16, marginBottom: 8 }}>Requirements</Text>
                        {
                          data?.listDocument?.map((item,i) => 
                            <React.Fragment key={i}>
                              <Col span={23}>
                                <Text strong style={{ color: '#32AB6D' }}>{item.name}</Text>
                              </Col>
                              <Col span={1} className="float-right">
                                <Tooltip className="float-right" title="click to download file">
                                  <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                                </Tooltip>
                              </Col>
                              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            </React.Fragment>
                          )
                        }
                      </Row>
                      <Row style={{ marginBottom: 16 }}>
                        <Text style={{ fontSize: 16, marginBottom: 8 }}>Conversion Courses</Text>
                        {
                          data?.listCourse?.map((item,i) => 
                            <Col key={i} span={24}>
                              <Text>{`${item.courseCode} - ${item.courseName} (${item.courseCategory === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.courseSks} SKS)`}</Text>
                            </Col>
                          )
                        }
                      </Row>
                      <Row style={{ marginBottom: 16 }}>
                        <Text style={{ fontSize: 16, marginBottom: 8 }}>Supervising Lecturer</Text>
                        {
                          data?.listLecturer?.map((item,i) => 
                            <Col key={i} span={24}>
                              <Text>{`${item.lecturerFrontTitle ? `${item.lecturerFrontTitle} ` : ''}${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? ` ${item.lecturerBackTitle}` : ''} (${item.role})`}</Text>
                            </Col>
                          )
                        }
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Log Activity" key="2">
                      <Row>
                        <Col span={24}>
                          <Timeline style={{ marginTop: 16 }}>
                            {
                              data?.listActivity ? 
                                data?.listActivity?.map((item,i) =>
                                  <Timeline.Item key={i}>
                                    <Row key={i}>
                                      <Col span={4}>
                                        <Text style={{ color: '#32ab6d' }}>
                                          {item.activityDate}
                                        </Text>
                                      </Col>
                                      <Col span={18}>
                                        <Paragraph ellipsis={true ? { rows: 3, expandable: true, symbol: 'View More'} : false}>
                                          {item.summaryReport}
                                        </Paragraph>
                                      </Col>
                                      <Col span={2}>
                                        {
                                          item.fileUrl ? 
                                            <a href={item.fileUrl} target="_blank" rel='noreferrer' style={{ float: 'right' }}>
                                              <Tooltip title="Download">
                                                <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                              </Tooltip>
                                            </a>
                                          : null
                                        }
                                      </Col>
                                    </Row> 
                                  </Timeline.Item>
                                )
                              :
                                <Empty />
                            }
                          </Timeline>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Assessment" key="3">
                      {
                        data?.listAssessment?.map((item,i) => 
                          <React.Fragment key={i}>
                            <Row align="middle">
                              <Col span={12}>
                                <Space direction='vertical'>
                                  <Text strong style={{ color: '#32AB6D' }}>{item.courseCode} - {item.courseName}</Text>
                                  <Text>{item.category === 'GENERAL_COURSE' ? 'Mata Kuliah Umum' : 'Mata Kuliah Jurusan'} - {item.courseSks} SKS</Text>
                                </Space>
                              </Col>
                              <Col span={2}>
                                <Space direction='vertical'>
                                  <Text>{item.score ? item.score : 'N/A'}</Text>
                                  <Text strong style={{ color: '#32AB6D' }}>{item.letterGrade ? item.letterGrade : 'N/A'}</Text>
                                </Space>
                              </Col>
                              <Col span={4}>
                                <Space direction='vertical'>
                                  <Text strong style={{ color: '#32AB6D' }}>{item.lecturerFirstName ? `${item.lecturerFirstName ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}` : 'N/A'}</Text>
                                  <Text>{item.assessmentDate ? `pada ${item.assessmentDate}` : 'N/A'}</Text>
                                </Space>
                              </Col>
                              <Col span={6}>
                                <Space style={{ float: 'right' }}>
                                  {
                                    item.status === 'CONFIRMED' ? 
                                      <>
                                        <Tag color="green">Confirmed</Tag>
                                        <Tooltip title="view detail" onClick={() => this.showModalDetail(item)}>
                                          <EyeOutlined style={{ color: '#32AB6D' }} />
                                        </Tooltip>
                                      </>
                                    : 
                                      <Tag color="orange">Unconfirmed</Tag>
                                  }
                                </Space>
                              </Col>
                            </Row>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                          </React.Fragment>
                        )
                      }
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleModalDetail}
          onCancel={this.onCancel}
          title="Detail Assessment"
          footer={false}
        >
          <ModalDetail 
            valueModalEdit={valueModalEdit}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetDetailInformation, unmountGetDetailFollowed, unmountGetDetailPrograms } = this.props;
    unmountGetDetail()
    unmountGetDetailInformation()
    unmountGetDetailFollowed()
    unmountGetDetailPrograms()
  }
}

const mapStateToProps = (state) => ({
  getData: state.participants.detail,
  getDataInformation: state.participants.detailInformation,
  getDataFollowed: state.participants.detailFollowed,
  getDataPrograms : state.participants.detailPrograms
})

const mapDispatchToProps = {
  actionGetDetail   : detailParticipant,
  actionGetDetailInformation   : detailParticipantInformation,
  actionGetDetailFollowed : detailParticipantFollowed,
  actionGetDetailPrograms : detailParticipantPrograms,
  unmountGetDetail  : unmountDetailParticipant,
  unmountGetDetailInformation  :unmountDetailParticipantInformation,
  unmountGetDetailFollowed  :unmountDetailParticipantFollowed,
  unmountGetDetailPrograms : unmountDetailParticipantPrograms
 
 
}

export default connect(mapStateToProps, mapDispatchToProps)(AcademicDetailParticipant)
