import { API } from '../../../../../config';
import { errorHandler } from '../../../auth/errorAction';

export const unmountListFieldOfStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_FIELD_OF_STUDY'})
}

export const unmountDetailFieldOfStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_FIELD_OF_STUDY'})
}

export const unmountMasterFieldOfStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_FIELD_OF_STUDY'})
}

export const listFieldOfStudy = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_FIELD_OF_STUDY' })
  return API.GET('/field-of-study/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_FIELD_OF_STUDY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_FIELD_OF_STUDY_FAILED' }), 
    ))
  })
}

export const detailFieldOfStudy = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_FIELD_OF_STUDY' })
  const params = {
    'id': id
  }
  API.GET('/field-of-study/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_FIELD_OF_STUDY_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_FIELD_OF_STUDY_FAILED' }), 
      ))
    )
  })
}

export const masterFieldOfStudy = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY' })
  return API.GET('/field-of-study/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED' }), 
    ))
  })
}

export const createFieldOfStudy = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/field-of-study/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editFieldOfStudy = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/field-of-study/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableFieldOfStudy = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/field-of-study/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableFieldOfStudy = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/field-of-study/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}