import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { UploadOutlined, ContactsOutlined, DeleteOutlined } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { createPartner } from '../../../../redux/actions/admin/partners/partnersAction';
import { masterIndustries, unmountMasterIndustries } from '../../../../redux/actions/admin/master/industries/industriesAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Divider, Space, Breadcrumb, Image, Typography, message } from 'antd';

class CreatePartner extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: []
    }
  }
  
  componentDidMount() {
    const { actionGetIndustries, actionGetCountry, actionGetProvince } = this.props;
    actionGetProvince(99, (response) => {
      this.setState({ dataProvince: response.data })
    })
    actionGetIndustries()
    actionGetCountry()
  }

  checkEmail = (value) => {
    const { actionCheckEmail } = this.props;
    return actionCheckEmail(value, (response) => {
      if(response){
        message.error('Email already exist')
        this.formRef.current.setFieldsValue({
          email: null,
        });
      }
    })
  }

  checkPhone = (value) => {
    const { actionCheckPhone } = this.props;
    const valuePhone = value.replace(/^0+/, '')
    this.formRef.current.setFieldsValue({
      cpMobile: value.replace(/^0+/, '')
    });
    return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
      if(response){
        message.error('Mobile Number already exist')
        this.formRef.current.setFieldsValue({
          cpMobile: null,
        });
      }
    })
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { editAvatar, avatar } = this.state;
    values.enabled = true
    if(!values.specialties){
      delete values.specialties
    }
    if(!values.officePhone){
      delete values.officePhone
    }
    if(!values.provinceId){
      delete values.provinceId
    }
    if(!values.countryId){
      delete values.countryId
    }
    if(!values.cityId){
      delete values.cityId
    }
    if(!values.streetAddress){
      delete values.streetAddress
    }
    if(!values.postalCode){
      delete values.postalCode
    }
    if(!values.companySize){
      delete values.companySize
    }
    if(!values.website){
      delete values.website
    }
    if(!values.about){
      delete values.about
    }
    if(editAvatar){
      values.logoFile = avatar
    }

    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/partners')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity } = this.state;
    const { getIndustries, getCountry } = this.props;

      if(getIndustries.loading || getCountry.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Partners</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/partners">Partners List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create Partner</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Create an Partner" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>User Account</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Email" 
                      name="email" 
                      tooltip="This email will be used for account login"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'Your email address is invalid' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Mobile Number"
                      name="cpMobile"
                      tooltip="This mobile number will be used for account login"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore="+62" onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Partner Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Partner Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'Partner Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Name" 
                      name="contactPerson"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Partner Name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Industry" 
                      name="industriesId"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an Industry"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getIndustries?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Specialties (niche or specific industry of the partner)" 
                      name="specialties" 
                      initialValue=''
                      rules={[
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Office Phone Number" 
                      name="officePhone"
                      validateFirst 
                      initialValue=''
                      rules={[
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Country" name="countryId" initialValue={99}>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Province" name="provinceId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="City" name="cityId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="streetAddress"
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="postalCode"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Company Size" 
                      name="companySize"
                      initialValue=''
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="SMALL">Small</Select.Option>
                        <Select.Option value="MEDIUM">Medium</Select.Option>
                        <Select.Option value="LARGE">Large</Select.Option>
                        {/* <Select.Option value="1 - 20 employees">1 - 20 employees</Select.Option>
                        <Select.Option value="21 - 100 employees">21 - 100 employees</Select.Option>
                        <Select.Option value="100 - 250 employees">100 - 250 employees</Select.Option>
                        <Select.Option value="250 - 500 employees">250 - 500 employees</Select.Option>
                        <Select.Option value="500 - 1000 employees">500 - 1000 employees</Select.Option>
                        <Select.Option value="> 1000 employees">{`> 1000 employees`}</Select.Option> */}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Website" 
                      name="website"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Maximum is 100 characters' },
                        { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid website url' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="About" 
                      name="about"
                      initialValue=''
                      rules={[
                        { max: 2000, message: 'Maximum is 2000 characters' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Logo">
                      {
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editAvatar ? 'Change Logo' : 'Upload Logo'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Divider />

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetCountry } = this.props;
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getIndustries  : state.industries.master,
  getCountry  : state.countries.master,
})

const mapDispatchToProps = {
  actionCreate          : createPartner,
  actionCheckEmail      : checkEmail,
  actionCheckPhone      : checkPhone,
  actionGetIndustries   : masterIndustries,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  unmountGetIndustries  : unmountMasterIndustries,
  unmountGetCountry     : unmountMasterCountries

}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePartner)