import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListNews = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_NEWS'})
}

export const unmountDetailNews = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_NEWS'})
}

export const unmountMasterNews = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_NEWS'})
}

export const listNews = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_NEWS' })
  return API.GET('/news/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_NEWS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_NEWS_FAILED' }), 
    ))
  })
}

export const detailNews = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_NEWS' })
  const params = {
    'id': id
  }
  API.GET('/news/getById/', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_NEWS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_NEWS_FAILED' }), 
      ))
    )
  })
}

export const masterNews = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_NEWS' })
  return API.GET('/news/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_NEWS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_NEWS_FAILED' }), 
    ))
  })
}

export const createNews = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/news/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editNews = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/news/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableNews = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/news/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableNews = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/news/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}