import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Tag } from 'antd';
const { Text } = Typography;

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/academic/partners/detail/${record.id}`}>
          {record.number}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : null,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          <Tooltip title={record.name}>
            {record.name}
          </Tooltip>
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email}
        </Text>
      )
    },
    {
      title: 'Industry',
      key: 'industry',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.industriesName ? record.industriesName : null}
        </Text>
      )
    },
    {
      title: 'Contact Person',
      key: 'contactPerson',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.contactPerson ? record.contactPerson : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    }
  ]
}