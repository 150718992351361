import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListPartners = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PARTNERS'})
}

export const unmountDetailPartners = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PARTNERS'})
}

export const unmountDetailPartnersProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PARTNERS_PROGRAM'})
}

export const unmountMasterPartners = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_PARTNERS'})
}

export const listPartners = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PARTNERS' })
  return API.GET('/partner/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_PARTNERS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_PARTNERS_FAILED' }), 
    ))
  })
}

export const detailPartners = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_PARTNERS' })
  const params = {
    'id': id
  }
  API.GET('/partner/getById/', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_PARTNERS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_PARTNERS_FAILED' }), 
      ))
    )
  })
}

export const detailPartnersProgram = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_PARTNERS_PROGRAM' })
  const params = {
    'id': id
  }
  API.GET('/program/getByPartnerId', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_PARTNERS_PROGRAM_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_PARTNERS_PROGRAM_FAILED' }), 
      ))
    )
  })
}


export const masterPartners = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_PARTNERS' })
  return API.GET('/partner/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_PARTNERS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_PARTNERS_FAILED' }), 
    ))
  })
}

export const createPartner = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/partner/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editPartner = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/partner/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enablePartner = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/partner/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disablePartner = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/partner/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}