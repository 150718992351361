import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';

// Admin

import applicantsReducer from './admin/applicants/applicantsReducer';
import applicantsDetailReducer from './admin/applicants/applicantsDetailReducer';
import applicantsFollowedProgramReducer from './admin/applicants/applicantsFollowedProgramReducer';

import lecturersReducer from './admin/lecturers/lecturersReducer';
import lecturersDetailReducer from './admin/lecturers/lecturersDetailReducer';
import masterLecturersReducer from './admin/lecturers/masterLecturersReducer';
import supervisingLecturersDetailReducer from './admin/lecturers/lecturersSupervisingReducer';

import partnersReducer from './admin/partners/partnersReducer';
import partnersDetailReducer from './admin/partners/partnersDetailReducer';
import partnersProgramDetailReducer from './admin/partners/partnerProgramDetailReducer';
import masterPartnersReducer from './admin/partners/masterPartnersReducer';

import academicReducer from './admin/academic/academicReducer';
import academicDetailReducer from './admin/academic/academicDetailReducer';

import participantsReducer from './admin/participants/participantsReducer';
import participantsDetailReducer from './admin/participants/participantsDetailReducer';
import participantsInformationReducer from './admin/participants/participantsDetailInformationReducer';
import participantsFollowedReducer from './admin/participants/participantsDetailFollowedReducer';
import participantsProgramsReducer from './admin/participants/participantsDetailProgramsReducer';

import programsReducer from './admin/programs/programsReducer';
import programsDetailReducer from './admin/programs/programsDetailReducer';
import programsApplicantReducer from './admin/programs/programsApplicantReducer';
import programsParticipantReducer from './admin/programs/programsParticipantReducer';
import programsDetailParticipantReducer from './admin/programs/programsDetailParticipantReducer';

import newsReducer from './admin/news/newsReducer';
import newsDetailReducer from './admin/news/newsDetailReducer';
import masterNewsReducer from './admin/news/masterNewsReducer';

import coursesReducer from './admin/courses/coursesReducer';
import coursesDetailReducer from './admin/courses/coursesDetailReducer';
import masterCoursesReducer from './admin/courses/masterCoursesReducer';

import fileReducer from './admin/file/fileReducer';
import fileDetailReducer from './admin/file/fileDetailReducer';
import masterFileReducer from './admin/file/masterFileReducer';

import countriesReducer from './admin/master/countries/countriesReducer';
import countriesDetailReducer from './admin/master/countries/countriesDetailReducer';
import masterCountriesReducer from './admin/master/countries/masterCountriesReducer';
import provincesReducer from './admin/master/provinces/provincesReducer';
import provincesDetailReducer from './admin/master/provinces/provincesDetailReducer';
import masterProvincesReducer from './admin/master/provinces/masterProvincesReducer';
import citiesReducer from './admin/master/cities/citiesReducer';
import citiesDetailReducer from './admin/master/cities/citiesDetailReducer';
import masterCitiesReducer from './admin/master/cities/masterCitiesReducer';
import subDistrictReducer from './admin/master/subDistrict/subDistrictReducer';
import subDistrictDetailReducer from './admin/master/subDistrict/subDistrictDetailReducer';
import masterSubDistrictReducer from './admin/master/subDistrict/masterSubDistrictReducer';
import villageReducer from './admin/master/villages/villageReducer';
import villageDetailReducer from './admin/master/villages/villageDetailReducer';
import masterVillageReducer from './admin/master/villages/masterVillageReducer';
import industriesReducer from './admin/master/industries/industriesReducer';
import industriesDetailReducer from './admin/master/industries/industriesDetailReducer';
import masterIndustriesReducer from './admin/master/industries/masterIndustriesReducer';
import positionsReducer from './admin/master/positions/positionsReducer';
import positionsDetailReducer from './admin/master/positions/positionsDetailReducer';
import masterPositionsReducer from './admin/master/positions/masterPositionsReducer';
import positionByIndustriesReducer from './admin/master/positions/positionByIndustriesReducer';
import institutionsReducer from './admin/master/institutions/institutionsReducer';
import institutionsDetailReducer from './admin/master/institutions/institutionsDetailReducer';
import masterInstitutionsReducer from './admin/master/institutions/masterInstitutionsReducer';
import fieldOfStudyReducer from './admin/master/fieldOfStudy/fieldOfStudyReducer';
import fieldOfStudyDetailReducer from './admin/master/fieldOfStudy/fieldOfStudyDetailReducer';
import masterFieldOfStudyReducer from './admin/master/fieldOfStudy/masterFieldOfStudyReducer';
import documentTypeReducer from './admin/master/documentTypes/documentTypeReducer';
import documentTypeDetailReducer from './admin/master/documentTypes/documentTypeDetailReducer';
import masterDocumentTypeReducer from './admin/master/documentTypes/masterDocumentTypeReducer';

// Lecturers

import lecturersProfileReducer from './lecturers/profile/profileReducer';

import lecturersPrograms from './lecturers/programs/programsReducer';
import lecturersProgramsDetailReducer from './lecturers/programs/programsDetailReducer';
import lecturersProgramsParticipantReducer from './lecturers/programs/programsParticipantReducer';
import lecturersProgramsDetailParticipantReducer from './lecturers/programs/programsDetailParticipantReducer';

import lecturersParticipantsReducer from './lecturers/participants/participantsReducer';
import lecturersParticipantsDetailReducer from './lecturers/participants/participantsDetailReducer';
import lecturersParticipantInformationReducer from './lecturers/participants/participantsDetailInformationReducer';
import lecturersParticipantFollowedReducer from './lecturers/participants/participantsDetailFollowedReducer';
import lecturersParticipantProgramsReducer from './lecturers/participants/participantsDetailProgramsReducer';

// Partners

import partnersProfileReducer from './partners/profile/profileReducer';

import partnersProgramsReducer from './partners/programs/programsReducer';
import partnersProgramsDetailReducer from './partners/programs/programsDetailReducer';
import partnersProgramsParticipantReducer from './partners/programs/programsParticipantReducer';
import partnersProgramsDetailParticipantReducer from './partners/programs/programsDetailParticipantReducer';

import partnersParticipantsReducer from './partners/participants/participantsReducer';
import partnersParticipantsDetailReducer from './partners/participants/participantsDetailReducer';


export default combineReducers({
  authReducer,
  applicants: combineReducers({
    list    : applicantsReducer,
    detail  : applicantsDetailReducer,
    followedProgram : applicantsFollowedProgramReducer
  }),
  lecturers: combineReducers({
    list    : lecturersReducer,
    detail  : lecturersDetailReducer,
    master  : masterLecturersReducer,
    detailSupervisingLecturers : supervisingLecturersDetailReducer
  }),
  partners: combineReducers({
    list    : partnersReducer,
    detail  : partnersDetailReducer,
    detailPartnersProgram : partnersProgramDetailReducer,
    master  : masterPartnersReducer
  }),
  academic: combineReducers({
    list    : academicReducer,
    detail  : academicDetailReducer
  }),
  participants: combineReducers({
    list    : participantsReducer,
    detail  : participantsDetailReducer,
    detailInformation : participantsInformationReducer,
    detailFollowed   : participantsFollowedReducer,
    detailPrograms : participantsProgramsReducer,
  }),
  programs: combineReducers({
    list          : programsReducer,
    detail        : programsDetailReducer,
    applicant     : programsApplicantReducer,
    participant   : programsParticipantReducer,
    detailParticipant   : programsDetailParticipantReducer,
  }),
  news: combineReducers({
    list    : newsReducer,
    detail  : newsDetailReducer,
    master  : masterNewsReducer
  }),
  courses: combineReducers({
    list    : coursesReducer,
    detail  : coursesDetailReducer,
    master  : masterCoursesReducer
  }),
  file: combineReducers({
    list    : fileReducer,
    detail  : fileDetailReducer,
    master  : masterFileReducer
  }),
  countries: combineReducers({
    list    : countriesReducer,
    detail  : countriesDetailReducer,
    master  : masterCountriesReducer
  }),
  provinces: combineReducers({
    list    : provincesReducer,
    detail  : provincesDetailReducer,
    master  : masterProvincesReducer
  }),
  cities: combineReducers({
    list    : citiesReducer,
    detail  : citiesDetailReducer,
    master  : masterCitiesReducer
  }),
  subDistricts: combineReducers({
    list    : subDistrictReducer,
    detail  : subDistrictDetailReducer,
    master  : masterSubDistrictReducer
  }),
  villages: combineReducers({
    list    : villageReducer,
    detail  : villageDetailReducer,
    master  : masterVillageReducer
  }),
  industries: combineReducers({
    list    : industriesReducer,
    detail  : industriesDetailReducer,
    master  : masterIndustriesReducer
  }),
  positions: combineReducers({
    list              : positionsReducer,
    detail            : positionsDetailReducer,
    master            : masterPositionsReducer,
    listByIndustries  : positionByIndustriesReducer,
  }),
  institutions: combineReducers({
    list    : institutionsReducer,
    detail  : institutionsDetailReducer,
    master  : masterInstitutionsReducer
  }),
  fieldOfStudy: combineReducers({
    list    : fieldOfStudyReducer,
    detail  : fieldOfStudyDetailReducer,
    master  : masterFieldOfStudyReducer
  }),
  documentTypes: combineReducers({
    list    : documentTypeReducer,
    detail  : documentTypeDetailReducer,
    master  : masterDocumentTypeReducer
  }),
  // Lecturers
  lecturersProfile: combineReducers({
    data  : lecturersProfileReducer,
  }),
  lecturersPrograms: combineReducers({
    list    : lecturersPrograms,
    detail  : lecturersProgramsDetailReducer,
    participant         : lecturersProgramsParticipantReducer,
    detailParticipant   : lecturersProgramsDetailParticipantReducer,
  }),
  lecturersParticipant: combineReducers({
    list    : lecturersParticipantsReducer,
    detail  : lecturersParticipantsDetailReducer,
    detailInformation : lecturersParticipantInformationReducer,
    detailFollowed   : lecturersParticipantFollowedReducer,
    detailPrograms : lecturersParticipantProgramsReducer,
  }),
  // Partners
  partnersProfile: combineReducers({
    data  : partnersProfileReducer,
  }),
  partnersPrograms: combineReducers({
    list                : partnersProgramsReducer,
    detail              : partnersProgramsDetailReducer,
    participant         : partnersProgramsParticipantReducer,
    detailParticipant   : partnersProgramsDetailParticipantReducer,
  }),
  partnersParticipant: combineReducers({
    list    : partnersParticipantsReducer,
    detail  : partnersParticipantsDetailReducer,
  })
})