import React from 'react'
import { Menu } from 'antd';

export default function index(props) {
  const { rowKey, handleMenuPhase } = props
  return (
    <Menu defaultSelectedKeys={[rowKey]} mode="horizontal" style={{ background: 'none'  }}>
      <Menu.Item key='applied' onClick={(e) => handleMenuPhase(e)}>
        Applied
      </Menu.Item>
      <Menu.Item key='review' onClick={(e) => handleMenuPhase(e)}>
        Reviewed
      </Menu.Item>
      <Menu.Item key='accepted' onClick={(e) => handleMenuPhase(e)}>
        Accepted
      </Menu.Item>
    </Menu>
  )
}
