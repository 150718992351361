import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Link } from 'react-router-dom';
import { HeaderTable } from '../../../components';
import { PlusOutlined, FileOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Button, Breadcrumb, Pagination, message } from 'antd';
import { disableCourse, enableCourse, listCourses, unmountListCourses } from '../../../redux/actions/admin/courses/coursesAction';

class Courses extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }
  
  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onCategory = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.courseCategory = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  sorter = (pagination, filters, sorter) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.field = sorter.columnKey
    meta.sort = sorter.order ? sorter.order === "ascend" ? 'asc' : 'desc' : null
    return this.setState({ sortedInfo: sorter }, () => {
      return actionGetData(meta)
    })
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
     message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
     message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickRow = (record) => {
    this.props.history.push(`/courses/detail/${record.id}`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/courses/edit/${id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys, sortedInfo } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    const defaultCategoryFilter = [
      { value: null, name: 'All Category' },
      { value: 'GENERAL_COURSE', name: 'General Course' },
      { value: 'MAJOR_COURSE', name: 'Major Course' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><FileOutlined /> Courses</Breadcrumb.Item>
              <Breadcrumb.Item>Courses List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Courses List' 
              extra={[
                <Link key="1" to="/courses/create">
                  <Button className="btn-create-primary" type="primary" icon={<PlusOutlined />} ghost>
                    Create Courses
                  </Button>
                </Link>
                ,
              ]}
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter }}
              category={{ key: 'category', value: defaultCategoryFilter }}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onCategory={this.onCategory}
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Created Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Name"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1200 }}
              rowSelection={rowSelection}
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(sortedInfo, this.onClickEdit, this.handleEnable, this.handleDisable, this.onClickRow)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.courses.list
})

const mapDispatchToProps = {
  actionGetData   : listCourses,
  actionEnable    : enableCourse,
  actionDisable   : disableCourse,
  unmountGetData  : unmountListCourses
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
