import React, { Component } from 'react';
import '../assets/styles/global.css'
import * as Containers from '../containers';
import { connect } from 'react-redux';
import { Result } from 'antd';
import Cookie from 'js-cookie';
import { Loading } from '../components';
import { convertMenu } from '../utils/menuSelection';
import { PrivateRoute, AuthRoute } from './route';
import { checkAuth, checkToken } from '../redux/actions/auth/authAction';
import { BrowserRouter as Router, Switch } from "react-router-dom";

class AppRoute extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      _menu: [],
      collapsed: false,
      isDisconnected: false
    }
  }

  componentDidMount() {
    const { actionCheckAuth, actionCheckToken } = this.props;
    const getToken = Cookie.get('user')
    actionCheckAuth()
    if(getToken){
      actionCheckToken(() => {
        window.location.reload()
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { permission, loading, role } = nextProps
    !loading && convertMenu(permission, role, (response) => {
      this.setState({ _menu: response })
    })
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { loading } = this.props;
    const { _menu, collapsed, isDisconnected } = this.state;

    if(loading){ 
      return <Loading /> 
    };

    return (
      <Router>
        <Switch>
          <AuthRoute exact path='/login' component={Containers.Login} passProps={this.props} private={false} />
          { 
            isDisconnected && (
              <React.Fragment>
                <Result status="500" title="Lost Connection" subTitle="Please check your internet connection" />
              </React.Fragment>
            )
          } 
          {
            _menu.map((response, i) => {
              return <PrivateRoute 
              {...response} 
              collapsed={collapsed} 
              toggleCollapsed={this.toggleCollapsed} 
              key={i} 
              exact 
              component={Containers[response.component]} 
              passProps={this.props} 
            />
            })
          }
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  loading     : state.authReducer.loading,
  authed      : state.authReducer.authed,
  permission  : state.authReducer.permission,
  role        : state.authReducer.role
})

const mapDispatchToProps = {
  actionCheckAuth   : checkAuth,
  actionCheckToken  : checkToken
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)