const LOAD_PROGRAMS_LIST_PARTICIPANT           = 'LOAD_PROGRAMS_LIST_PARTICIPANT'
const LOAD_PROGRAMS_LIST_PARTICIPANT_SUCCESS   = 'LOAD_PROGRAMS_LIST_PARTICIPANT_SUCCESS'
const LOAD_PROGRAMS_LIST_PARTICIPANT_FAILED    = 'LOAD_PROGRAMS_LIST_PARTICIPANT_FAILED'
const UNMOUNT_PROGRAMS_LIST_PARTICIPANT        = 'UNMOUNT_PROGRAMS_LIST_PARTICIPANT'

const initialState = {
  loading: true,
  data: null,
  message: null,
  pagination: {
    page: 1,
    total: 0,
    perpage: 10
  }
}

const programsParticipantReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_PROGRAMS_LIST_PARTICIPANT:
      return {
        ...state,
        loading: true,
        data: null,
        pagination: {
          page: 1,
          total: 0,
          perpage: 10
        }
      }
    case LOAD_PROGRAMS_LIST_PARTICIPANT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
        pagination: {
          total: action.payload.pagination.total,
          page: action.payload.pagination.page,
          perpage: action.payload.pagination.perpage
        }
      }
    case LOAD_PROGRAMS_LIST_PARTICIPANT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
        pagination: {
          page: 1,
          total: 0,
          perpage: 10
        }
      }
    case UNMOUNT_PROGRAMS_LIST_PARTICIPANT:
      return { 
        ...state, 
        loading: true,
        data: null,
        pagination: {
          page: 1,
          total: 0,
          perpage: 10
        }
      }
    default:
      return state
  }
}

export default programsParticipantReducer;