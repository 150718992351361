import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Loading } from '../../../../../../../components';
import { UserOutlined, DownloadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Radio, Select, Button, Avatar, Space, Typography, Divider, Tooltip, Input } from 'antd';
const { Title, Text } = Typography

export default class ContentReview extends Component {

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { dataApplicant, formRef, isResult, handleChangeResult, detailParticipant, getDataCourse, getDataLecture, onPriviewProfile, submitLoading } = this.props;
    if(detailParticipant.loading ||getDataCourse.loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={16}>
            <Space size="large">
              <Avatar size={64} src={dataApplicant.photoUrl ? dataApplicant.photoUrl : null } icon={!dataApplicant.photoUrl ? <UserOutlined /> : null} />
              <div>
                <Title level={4}>{dataApplicant.applicantName ? dataApplicant.applicantName : 'N/A'}</Title>
                <Text type="secondary">{dataApplicant.participantNumber ? dataApplicant.participantNumber : 'N/A'}</Text>
                <br />
                <Space direction='vertical' size={0}>
                  <Text>
                    {dataApplicant.institutionName ? dataApplicant.institutionName : 'N/A'} 
                  </Text>
                  <Text>
                    {dataApplicant.fieldOfStudyName ? dataApplicant.fieldOfStudyName : 'N/A'}
                  </Text>
                </Space>
              </div>
            </Space>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* {
                getApplicantNotes?.data?.length > 0 ?
                  <Badge dot offset={[-2, 2]} style={{ width: 10, height: 10 }}>
                    <Button 
                      className="btn-create-default" 
                      onClick={() => showModalNotes(dataApplicant.id, dataApplicant.applicantNotes)}
                    >
                      <FileDoneOutlined /> Applicant Notes
                    </Button>
                  </Badge>
                :
                  <Button 
                    className="btn-create-default" 
                    onClick={() => showModalNotes(dataApplicant.id)}
                  >
                    <FileDoneOutlined /> Applicant Notes
                  </Button>
              } */}
              <Button className="btn-color-blue" onClick={() => onPriviewProfile(dataApplicant.applicantId)}>
                View Profile
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Typography.Text style={{ fontSize: 18 }}>Requirements</Typography.Text>
            </Col>
            <Col span={24}>
              {
                detailParticipant?.data?.listDocument?.map((item,i) => 
                  <Row key={i} className="mb-16">
                    <Col span={23}>
                      <Text strong style={{ color: '#32AB6D' }}>{item.name}</Text>
                    </Col>
                    <Col span={1} className="float-right">
                      <Tooltip className="float-right" title="click to download file">
                        <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                      </Tooltip>
                    </Col>
                    <Divider />
                  </Row>
                )
              }
            </Col>
            <Col span={24}>
              <Form.Item name="isResult" label="Review Result" initialValue={isResult}>
                <Radio.Group onChange={handleChangeResult}>
                  <Radio value={true}>Yes, accept</Radio>
                  <Radio value={false}>No, reject</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {
              isResult ?
              <React.Fragment>
                <Col span={24} style={{ marginBottom: 32 }}>
                  <Typography.Text style={{ fontSize: 18 }}>Conversion Courses</Typography.Text>
                </Col>
                <Col span={24}>
                  <Form.List name="listCourse">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row key={field.fieldKey} gutter={16}>
                            <Col span={23}>
                              <Form.Item 
                                {...field}
                                name={[index, 'courseId']}
                                initialValue={null}
                                rules={[
                                  { required: true, message: 'This is a required field' },
                                ]}
                              >
                                <Select placeholder="Select a course" onChange={this.handleChangePosition}>
                                  {
                                    getDataCourse?.data?.map((item, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          {
                                            <Select.Option key={i} value={item.id}>
                                              {`${item.code} - ${item.name} (${item.category === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.sks} SKS)`}
                                            </Select.Option>
                                          }
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            
                            <Col span={1}>
                              <Form.Item>
                                <MinusCircleOutlined onClick={() => { remove(field.name) }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                        <Col span={24}>
                          <Form.Item>
                            <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                              Add Course
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col span={24} style={{ marginBottom: 32 }}>
                  <Typography.Text style={{ fontSize: 18 }}>Supervising Lecturer</Typography.Text>
                </Col>
                <Col span={24}>
                  <Form.List name="listLecturer">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row key={field.fieldKey} gutter={16}>
                            <Col span={11}>
                              <Form.Item 
                                {...field}
                                name={[index, 'lecturerId']}
                                initialValue={null}
                              >
                                <Select placeholder="Select a lecturer" onChange={this.handleChangePosition}>
                                  {
                                    getDataLecture?.data?.map((item, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          {
                                            <Select.Option key={i} value={item.id}>
                                              {`${item.number} - ${item.firstName} ${item.lastName}`}
                                            </Select.Option>
                                          }
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item 
                                {...field}
                                name={[index, 'role']}
                                initialValue={null}
                              >
                                <Input placeholder="Role" />
                              </Form.Item>
                            </Col>
                            
                            <Col span={1}>
                              <Form.Item>
                                <MinusCircleOutlined onClick={() => { remove(field.name) }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                        <Col span={24}>
                          <Form.Item>
                            <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                              Add Lecture
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Col>
              </React.Fragment>
              :
              null
            }
            
            <Col span={24}>
              <Form.Item 
                name={isResult ? "note" : "rejectNote"}
                label={isResult ? "Notes to Applicant (optional)" : "Rejection Note"} 
                initialValue={null}
                rules={[
                  { required: !isResult, message: 'This is a required field' },
                ]}
              >
                <ReactQuill />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}