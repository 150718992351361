let env = "https://mymbkmbo.binawan.ac.id/api"
// let env = "http://172.16.2.29:9999/api"

if(process.env.REACT_APP_ENV === "development"){
  env = "https://stg-mbkmbo.binawan.ac.id/api"
}

if(process.env.REACT_APP_ENV === "production"){
  env = "https://mymbkmbo.binawan.ac.id/api"
}

export default env;