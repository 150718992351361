import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UploadOutlined, IdcardOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { createNews } from '../../../../redux/actions/admin/news/newsAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload,Typography, Button, Space, message, Breadcrumb, Image, DatePicker, Switch, Tooltip } from 'antd';
const { Text } = Typography;

class CreateNews extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      isUploadImage: null,
      mainImageFile: null,
      image2File: null,
      image3File: null,
      image4File: null
    }
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.getBase64(file, (url) => {
            const { isUploadImage } = this.state
            switch (isUploadImage) {
              case 'mainImage':
                return this.setState({ mainImageUrl: url, mainImageFile: file })
              case 'image2':
                return this.setState({ image2Url: url, image2File: file })
              case 'image3':
                return this.setState({ image3Url: url, image3File: file })
              case 'image4':
                return this.setState({ image4Url: url, image4File: file })
              default:
                return null
            }
          })
        }
        return false;
      }
    }
  }

  handleChangeImage = typeImage => {
    this.setState({ isUploadImage: typeImage })
  }
  
  handleDeleteImage = (typeImage) => {
    switch (typeImage) {
      case 'mainImage':
        return this.setState({ mainImageFile: null })
      case 'image2':
        return this.setState({ image2File: null })
      case 'image3':
        return this.setState({ image3File: null })
      case 'image4':
        return this.setState({ image4File: null })
      default:
        return null
    }
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { mainImageFile, image2File, image3File, image4File } = this.state;
    const time = moment(values.publishedDate).format('DD/MM/YYYY HH:mm:ss')
    values.publishedDate = values.publishedDate ? moment(values.publishedDate).format('DD/MM/YYYY') : null
    values.publishedTime = values.publishedDate ? moment(time, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss') : null
    values.status = 'PUBLISHED'
    values.mainImageFile = mainImageFile
    if(image2File){
      values.image2File = image2File
    }
    if(image3File){
      values.image3File = image3File
    }
    if(image4File){
      values.image4File = image4File
    }
    if(values.mainImageFile){
      this.setState({ submitLoading: true })
      return actionCreate(values, () => {
        this.setState({ submitLoading: false }, () => {
          message.success('Data created successfully')
          history.push('/news')
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    }else{
      message.error('Main Image is a required field!')
    }
  }

  onChangeComment = (checked) => {
    this.formRef.current.setFieldsValue({ isComment: checked })
  }

  onChangePopular = (checked) => {
    this.formRef.current.setFieldsValue({ isPopular: checked })
  }

  onChangeHighlight = (checked) => {
    this.formRef.current.setFieldsValue({ isHighlight: checked })
  }

  handleTitle = (e) => {
    const value = e.target.value.toLowerCase().split(' ').join('-')
    this.formRef.current.setFieldsValue({ slug: value })
  }

  render() {
    const { submitLoading, mainImageFile, mainImageUrl, image2File, image2Url, image3File, image3Url, image4File, image4Url } = this.state;

    return (
      <React.Fragment>
        {/* Form */}
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><IdcardOutlined /> News</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/news">News List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create News</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            {/* Page Header */}
            <Col span={12}>
              <PageHeader className="site-page-header" title="Create a News" />
            </Col>
            {/* Button */}
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item>
                <Space>
                  <Button type="default" onClick={() => window.history.back()}>Cancel</Button>
                  {/* <Button type="primary" ghost>Save as Draft</Button> */}
                  <Button type="primary" htmlType="submit" loading={submitLoading}>Submit</Button>
                </Space>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row gutter={8}>
                <Col span={14}>
                  <Card>
                    <Row gutter={16}>
                      <Col span={24} style={{marginBottom: 32}}>
                        <Text style={{ fontSize: 20 }}>News</Text>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Title" 
                          name="title" 
                          validateFirst
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { min: 3, message: 'Title must be at least 3 characters long' },
                            // { max: 50, message: 'Title is too long. Maximum is 50 characters' },
                            // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                          ]}
                        >
                          <Input onChange={this.handleTitle} />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Post Slug" 
                          name="slug" 
                          extra={<Text type='secondary' style={{ fontSize: 12 }}>Generate from title name</Text>}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Category" 
                          name="newsCategory"
                          rules={[
                          { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Select placeholder='Select category' onChange={this.handleProgramCategory}>
                            <Select.Option value="NEWS">News</Select.Option>
                            <Select.Option value="ANNOUNCEMENT">Announcement</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Tag" 
                          name="tag" 
                          extra={<Text type='secondary' style={{ fontSize: 12 }}>Improve discoverability of your news by addings tags relevant to the subjects matters</Text>}
                          // validateFirst
                          // rules={[
                          //   { required: true, message: 'This is a required field' },
                          // ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Published Date"
                          name="publishedDate"
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <DatePicker 
                            showTime 
                            format={'DD/MM/YYYY HH:mm:ss'} 
                            style={{ width: '100%' }} 
                            // disabledDate={this.disabledOpenDate}
                            onChange={this.onChangeOpenDate}
                            allowClear={true}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Content" 
                          name="content" 
                          initialValue={null}
                          rules={[
                            {required: true, message: 'This is a required field'},
                          ]}
                        >
                          <ReactQuill placeholder="Description" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col span={10}>
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <Card>
                      <Text style={{ fontSize: 16 }}>Image</Text>
                      <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={24}>
                          <Form.Item label="Main Image" extra={<Text type='secondary' style={{ fontSize: 12 }}>Recommanded resolution is 640*640 with max file size 5MB</Text>}>
                            {
                              mainImageFile ? 
                                <Space direction='vertical'>
                                  <Image
                                    src={mainImageUrl}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      objectFit: 'containt'
                                    }}
                                  />
                                  <Space>
                                    <Tooltip title="Change Image">
                                        <Upload
                                          {...this.handleUpload()}
                                          onChange={() => this.handleChangeImage('mainImage')}
                                          showUploadList={false}
                                        >
                                          <EditOutlined style={{ color: '#3e6fa1', cursor: 'pointer' }} />
                                        </Upload>
                                    </Tooltip>
                                    <Tooltip title="Delete Image">
                                      <DeleteOutlined style={{ color: '#d8675a' }} onClick={() => this.handleDeleteImage('mainImage')} />
                                    </Tooltip>
                                  </Space>
                                </Space>
                              :
                                <Upload
                                  {...this.handleUpload()}
                                  onChange={() => this.handleChangeImage('mainImage')}
                                  showUploadList={false}
                                >
                                  <Button className="btn-radius" icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            }
                          </Form.Item>

                          <Form.Item label="Image 2" extra={<Text type='secondary' style={{ fontSize: 12 }}>Recommanded resolution is 640*640 with max file size 5MB</Text>}>
                            {
                              image2File ? 
                                <Space direction='vertical'>
                                  <Image
                                    src={image2Url}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      objectFit: 'containt'
                                    }}
                                  />
                                  <Space>
                                    <Tooltip title="Change Image">
                                        <Upload
                                          {...this.handleUpload()}
                                          onChange={() => this.handleChangeImage('image2')}
                                          showUploadList={false}
                                        >
                                          <EditOutlined style={{ color: '#3e6fa1', cursor: 'pointer' }} />
                                        </Upload>
                                    </Tooltip>
                                    <Tooltip title="Delete Image">
                                      <DeleteOutlined style={{ color: '#d8675a' }} onClick={() => this.handleDeleteImage('image2')} />
                                    </Tooltip>
                                  </Space>
                                </Space>
                              :
                                <Upload
                                  {...this.handleUpload()}
                                  onChange={() => this.handleChangeImage('image2')}
                                  showUploadList={false}
                                >
                                  <Button className="btn-radius" icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            }
                          </Form.Item>

                          <Form.Item label="Image 3" extra={<Text type='secondary' style={{ fontSize: 12 }}>Recommanded resolution is 640*640 with max file size 5MB</Text>}>
                            {
                              image3File ? 
                                <Space direction='vertical'>
                                  <Image
                                    src={image3Url}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      objectFit: 'containt'
                                    }}
                                  />
                                  <Space>
                                    <Tooltip title="Change Image">
                                        <Upload
                                          {...this.handleUpload()}
                                          onChange={() => this.handleChangeImage('image3')}
                                          showUploadList={false}
                                        >
                                          <EditOutlined style={{ color: '#3e6fa1', cursor: 'pointer' }} />
                                        </Upload>
                                    </Tooltip>
                                    <Tooltip title="Delete Image">
                                      <DeleteOutlined style={{ color: '#d8675a' }} onClick={() => this.handleDeleteImage('image3')} />
                                    </Tooltip>
                                  </Space>
                                </Space>
                              :
                                <Upload
                                  {...this.handleUpload()}
                                  onChange={() => this.handleChangeImage('image3')}
                                  showUploadList={false}
                                >
                                  <Button className="btn-radius" icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            }
                          </Form.Item>

                          <Form.Item label="Image 4" extra={<Text type='secondary' style={{ fontSize: 12 }}>Recommanded resolution is 640*640 with max file size 5MB</Text>}>
                            {
                              image4File ? 
                                <Space direction='vertical'>
                                  <Image
                                    src={image4Url}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      objectFit: 'containt'
                                    }}
                                  />
                                  <Space>
                                    <Tooltip title="Change Image">
                                        <Upload
                                          {...this.handleUpload()}
                                          onChange={() => this.handleChangeImage('image4')}
                                          showUploadList={false}
                                        >
                                          <EditOutlined style={{ color: '#3e6fa1', cursor: 'pointer' }} />
                                        </Upload>
                                    </Tooltip>
                                    <Tooltip title="Delete Image">
                                      <DeleteOutlined style={{ color: '#d8675a' }} onClick={() => this.handleDeleteImage('image4')} />
                                    </Tooltip>
                                  </Space>
                                </Space>
                              :
                                <Upload
                                  {...this.handleUpload()}
                                  onChange={() => this.handleChangeImage('image4')}
                                  showUploadList={false}
                                >
                                  <Button className="btn-radius" icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Card>
                      <Text style={{ fontSize: 16 }}>Status</Text>
                      <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col span={12}>
                          <Text>Open Comment</Text>
                          <Form.Item name="isComment" initialValue={true}>
                            <Switch defaultChecked onChange={this.onChangeComment} />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Highlight Post</Text>
                          <Form.Item name="isHighlight" initialValue={false}>
                            <Switch defaultChecked={false} onChange={this.onChangeHighlight} />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Text>Popular Post</Text>
                          <Form.Item name="isPopular" initialValue={true}>
                            <Switch defaultChecked onChange={this.onChangePopular} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  actionCreate          : createNews

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNews)