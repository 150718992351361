const LOAD_DATA_MASTER_POSITIONS           = 'LOAD_DATA_MASTER_POSITIONS'
const LOAD_DATA_MASTER_POSITIONS_SUCCESS   = 'LOAD_DATA_MASTER_POSITIONS_SUCCESS'
const LOAD_DATA_MASTER_POSITIONS_FAILED    = 'LOAD_DATA_MASTER_POSITIONS_FAILED'
const UNMOUNT_DATA_MASTER_POSITIONS        = 'UNMOUNT_DATA_MASTER_POSITIONS'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const masterPositionsReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_MASTER_POSITIONS:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DATA_MASTER_POSITIONS_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_MASTER_POSITIONS_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_MASTER_POSITIONS:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default masterPositionsReducer;