import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';
// import { CheckCircleOutlined, StopOutlined, EditOutlined } from '@ant-design/icons';
import { programCategory } from '../../../utils/constant/programCategory';
import moment from 'moment';
const { Text } = Typography;

// const menu = (record, onClickEdit, handleActive, handleInactive) => (
//   <Menu>
//     <Menu.Item key='3' icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
//       Edit                              
//     </Menu.Item>
//     {
//       record.status === "INACTIVE" ?
//       <Menu.Item key='1' icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleActive(record.id)}}>
//         Set as active
//       </Menu.Item>
//       :
//       <Menu.Item key='2' icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleInactive(record.id)}}>
//         Set as inactive
//       </Menu.Item>

//     }
//   </Menu>
// );

export const columns = (onClickEdit, handleActive, handleInactive, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      render: (record) => (
        <Link className="link" to={`/partner/my-program/detail/${record.id}/information`}>
          {record.programNumber}
        </Link>
      )
    },
    {
      title: 'Title',
      key: 'title',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.title}
        </Text>
      )
    },
    {
      title: 'Category',
      key: 'category',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.programCategory ? programCategory.find(item => item.value === record.programCategory)?.name : '-'}
        </Text>
      )
    },
    {
      title: 'Partner',
      key: 'job Position',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.partnerName ? record.partnerName : '-'}
        </Text>
      )
    },
    {
      title: 'Close Reg. Date',
      key: 'CloseRegDate',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.closeDate ? moment(record.closeDate, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'} 
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {record.status === 'ACTIVE' ? <Tag color="green"> Active</Tag> : null}
          {record.status === 'CLOSED' ?  <Tag color="red">Close</Tag> : null}
        </React.Fragment>
      )
    },
    // {
    //   title: '',
    //   key: 'action',
    //   width: 20,
    //   align: 'right',
    //   fixed: 'right',
    //   render: (record) => (
    //     <Dropdown overlay={menu(record, onClickEdit, handleActive, handleInactive)} trigger={['click']} placement="bottomCenter" arrow>
    //       <UnorderedListOutlined style={{ fontSize: 20 }} />
    //     </Dropdown>
    //   )
    // },
  ]
}