import React, { Component } from 'react';
import moment from 'moment';
import { Card, Row, Col, PageHeader, Typography, Tag, Descriptions } from 'antd';
import { programCategory } from '../../../../../utils/constant/programCategory';

const { Text } = Typography;

export default class HeaderDetailPrograms extends Component {
  render() {
    // const { data, checked, handleChangeStatus } = this.props
    const { data } = this.props
    return (
      <React.Fragment>
        <Card>
          <Row gutter={16}>
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title={<Text style={{ fontWeight: 'normal' }}>{data.title}</Text>} 
                tags={
                  <React.Fragment>
                    {data.status === "DRAFT" ? <Tag>Status: Draft</Tag> : null}
                    {
                      data.status === "ACTIVE" ? 
                      <React.Fragment>
                        <Tag color="green">Status: Active</Tag> 
                        {/* <Switch checked={checked} onChange={handleChangeStatus} /> */}
                      </React.Fragment>
                      : 
                      null
                    }
                    {data.status === "INACTIVE" ? <Tag color="orange">Status: Inactive</Tag> : null}
                    {data.status === "REJECT" ? <Tag color="red">Status: Reject</Tag> : null}
                  </React.Fragment>
                }
              />
            </Col>
            <Col span={24}>
              <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                <Descriptions.Item label={<Text type="secondary">Partner</Text>}>
                  {data.partnerName ? data.partnerName : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Program Category</Text>}>
                  {data.programCategory ? programCategory.find(item => item.value === data.programCategory)?.name : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Participant Type</Text>}>
                  {data.isGroup === true ? "Kelompok" : data.isGroup === false ? "Individu" : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Location</Text>}>
                  {
                    data.programLevel === 'INTERNATIONAL' ?
                      `${data.countryName}, ${data.cityName}`
                    : data.programLevel === 'DOMESTIC' ?
                      data.cityName
                    :
                      null
                  }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Registration Date</Text>}>
                  {data.openDate && data.closeDate ? `${moment(data.openDate, 'DD/MM/YYYY').format("DD MMMM YYYY")} - ${moment(data.closeDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}` : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Number Of Position</Text>}>
                  {data.positionNumber ? data.positionNumber : 'N/A'}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    )
  }
}
