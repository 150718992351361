import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Dropdown, Menu, Tag, Tooltip } from 'antd';
import { EditOutlined, UnorderedListOutlined, LeftOutlined, DeleteOutlined} from '@ant-design/icons';
import moment from 'moment';
const { Text } = Typography;

const menu = (record, onClickDetail, onClickEdit, handleDelete, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    <Menu.Item icon={<LeftOutlined/>} onClick={(e) => {e.domEvent.stopPropagation(); onClickDetail(record.id)}}>
      Detail                              
    </Menu.Item>
    <Menu.Item icon={<DeleteOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDelete(record.id)}}>
      Delete                              
    </Menu.Item>
  </Menu>
);

export const columns = (sortedInfo, onClickDetail, onClickEdit, handleDelete, handleEnable, handleDisable, handleRoute) => {
  return [
      {
      title: 'Code',
      key: 'code',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/file-management/detail/${record.id}`}>
          {record.code}
        </Link>
      )
    },
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : null,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          <Tooltip title={record.name}>
            {record.name}
          </Tooltip>
        </Text>
      )
    },
    {
      title: 'Attachment File',
      key: 'file',
      width: 150,
      render: (record) => (
        record.fileAttachment ?
          <a href={record.fileUrl} target="_blank" rel='noreferrer'>
            {record.fileAttachment}
          </a>
        :
          'N/A'
      )
    },
    {
      title: 'Published Date',
      key: 'publishedDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.publishedDate ? moment(record.publishedDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null} {record.publishedTime ? moment(record.publishedTime, 'HH:mm:ss').format('HH:mm:ss') : null}
        </Text>
      )
    },
    {
      title: 'Downloader',
      key: 'downloader',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.downloader ? record.downloader : 0}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Published</Tag>
            : 
            <Tag color="orange">Unpublished</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 20,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickDetail, onClickEdit, handleDelete, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}