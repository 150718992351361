import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountDataProfile = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_LECTURERS_PROFILE'})
}

export const dataProfile = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_LECTURERS_PROFILE' })
  return API.GET('/lecturer/getProfile').then((response) => {
    dispatch({
      type: 'LOAD_DATA_LECTURERS_PROFILE_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_LECTURERS_PROFILE_FAILED' }), 
    ))
  })
}

export const updateAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/lecturer/update-photo', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

// export const editMyProfile = (value, successCB, failedCB) => () => {
//   API.POST_FORM_DATA('/partner/update', value).then((response) => {
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return failedCB && failedCB(err)
//   })
// }