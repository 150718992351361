import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, handleActiveApplicant, showRejectReason) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Link to="#" onClick={() => onPriviewProfile(record.applicantId)} style={{ color: '#0076de' }}>
          {record.participantNumber ? record.participantNumber : "N/A"}
        </Link>
        // <Text style={{ color: '#0076de' }}>{record.participantNumber ? record.participantNumber : "N/A"}</Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text style={{ color: '#0076de' }}>{record.applicantName ? record.applicantName : "N/A"}</Text>
      )
    },
    {
      title: 'Gender',
      key: 'name',
      fixed: 'gender',
      width: 30,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.gender ? record.gender === "MALE" ? "Male" : "Female" : "N/A"}</Text>
      )
    },
    {
      title: 'Age',
      key: 'age',
      fixed: 'left',
      width: 20,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.age ? record.age : "N/A"}</Text>
      )
    },
    {
      title: 'University',
      key: 'university',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.institutionName ? record.institutionName : "N/A"}</Text>
      )
    },
    {
      title: 'Field of Study',
      key: 'fieldOfStudy',
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>{record.fieldOfStudyName ? record.fieldOfStudyName : "N/A"}</Text>
      )
    },
    // {
    //   title: 'Reason',
    //   key: 'reason',
    //   width: 80,
    //   render: (record) => (
    //     <Text onClick={() => showRejectReason(record.rejectNotes)} style={{ textDecoration: 'underline', color: '#0076de', cursor: 'pointer' }}>
    //       show
    //     </Text>
    //   )
    // },
    // {
    //   title: 'Move to last stage',
    //   key: 'moveToLastStage',
    //   align: 'center',
    //   width: 80,
    //   render: (record) => (
    //     <React.Fragment>
    //       <Button 
    //         className="button-process" 
    //         style={{ backgroundColor: 'rgba(0, 118, 222, 0.15)', color: '#0076de', border: 'none' }}
    //         onClick={() => handleActiveApplicant(record.id)}
    //       >
    //         Process
    //       </Button>
    //     </React.Fragment>
    //   )
    // },
  ]
}