import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Loading } from '../../../../components';
import { ReadOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Image, Breadcrumb } from 'antd';
import { detailAcademic, unmountDetailAcademic } from '../../../../redux/actions/admin/academic/academicAction';

const { Text } = Typography;

class DetailAcademic extends Component {

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id)
  }
  
  render() {
    const { getData: { data, loading } } = this.props;
  
    if(loading){
      return <Loading />
    }
  
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ReadOutlined /> Academic</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/academic">Academic List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.number ? data.number : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Academic Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
              <Col span={24}>
                <Text style={{fontSize : 17, color: '#242424'}}>User Account</Text>
                <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Mobile Number</Text>}>
                      {data.mobileNumber ? `+62${data.mobileNumber}` : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
              </Col>
                <Col span={24}>
                  <Text style={{fontSize : 17, color: '#242424'}}>Academic Information</Text>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name (with Title)</Text>}>
                      {`${data.frontTitle ? `${data.frontTitle} ` : ''}${data.firstName} ${data.lastName}${data.backTitle ? ` ${data.backTitle}` : ''}`}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {data.gender ? data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                      {data.birthPlace ? data.birthPlace : 'N/A'}, {data.birthDate ? moment(data.birthDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {data.nationality ? data.nationality : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="University">
                      {data.institutionName ? data.institutionName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Homebase">
                      {data.fieldOfStudyName ? data.fieldOfStudyName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.streetAddress ? data.streetAddress : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.postalCode ? data.postalCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Photo</Text>}>
                      {data.photoUrl ? <Image width={100} src={data.photoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.academic.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailAcademic,
  unmountGetDetail  : unmountDetailAcademic,
 
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailAcademic)
