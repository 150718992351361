export const programCategory = [
  {
    "value": "INTERNSHIP",
    "name": "Internship"
  },
  {
    "value": "STUDENT_EXCHANGE",
    "name": "Student Exchange"
  },
  {
    "value": "ENTREPRENEURSHIP",
    "name": "Entrepreneurship"
  },
  {
    "value": "HUMANITY",
    "name": "Humanity"
  },
  {
    "value": "COMMUNITY_SERVICE",
    "name": "Community Service"
  }
]