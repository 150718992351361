import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListApplicants = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_APPLICANTS'})
}

export const unmountDetailApplicants = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_APPLICANTS'})
}

export const unmountFollowedProgramApplicants = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_FOLLOWED_PROGRAM_APPLICANTS'})
}

export const listApplicants = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_APPLICANTS' })
  return API.GET(`/applicant/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_APPLICANTS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_APPLICANTS_FAILED' }), 
    ))
  })
}

export const detailApplicants = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_APPLICANTS' })
  const params = {
    'id': id
  }
  API.GET(`/applicant/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_APPLICANTS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_APPLICANTS_FAILED' }), 
      ))
    )
  })
}

export const detailFollowedProgram = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_FOLLOWED_PROGRAM' })
  const params = {
    'id': id
  }
  API.GET(`/participant/getByApplicantId`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_FOLLOWED_PROGRAM_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_FOLLOWED_PROGRAM_FAILED' }), 
      ))
    )
  })
}

export const updateAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/applicant/update/avatar', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateBiodata = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/update/biodata', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateContact = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/update/contact', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSocial = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/social-media/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSocial = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/social-media/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateJobPreference = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/update/job-preference', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/work-experience/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/work-experience/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteWorkExperience = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/work-experience/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/education/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/education/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteEducation = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/education/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/training/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/training/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/training/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSkill = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/skill/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSkill = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/skill/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addPortfolio = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/portfolio/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deletePortfolio = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/portfolio/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/achievement/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/achievement/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteAchievement = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/achievement/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/applicant/document/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/applicant/document/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteDocument = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/document/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updatePassword = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/applicant/update/password', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableApplicants = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableApplicants = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/applicant/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}