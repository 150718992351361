import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import svg from '../../../../assets/img/profile.svg';
import { Loading } from '../../../../components';
import { ContactsOutlined } from '@ant-design/icons';
import {PageHeader, Card, Row, Col, Space, Descriptions, Typography, Image, Breadcrumb } from 'antd';
// import { detailPartners, detailPartnersProgram, unmountDetailPartners, unmountDetailPartnersProgram } from '../../../../redux/actions/admin/partners/partnersAction';
import { detailParticipant, unmountDetailParticipant } from '../../../../redux/actions/partners/participant/participantAction';
import moment from 'moment';
const { Text } = Typography;

class DetailParticipant extends Component {

  componentDidMount() {
    
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id)
  }
  
  render() {
    const { getData: { data, loading }} = this.props;
  
    if(loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Participants</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/participant">Participant List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.number ? data.number : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Participant Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
              <Col span={24}>
                <Text style={{fontSize : 17, color: '#242424'}}>Personal Information</Text>
              </Col>

              <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
                      <Space direction="vertical">
                        <Image width={100} src={data.photoUrl ? data.photoUrl : svg} />
                        {/* <ImgCrop>
                          <Upload {...this.handleUpload()}>
                            <Button type="primary" size="small" ghost style={{ borderRadius: 6 }}>{data.photoUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                          </Upload>
                        </ImgCrop> */}
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name</Text>}>
                      {data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {data.nationality ? data.nationality : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                    {data.gender ? data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Marital Status</Text>}>
                      {
                        data.marital ?
                        <Text>
                          {data.marital === 'BELUM_KAWIN' && 'Belum Kawin'}
                          {data.marital === 'KAWIN' && 'Kawin'}
                          {data.marital === 'CERAI_HIDUP' && 'Cerai Hidup'}
                          {data.marital === 'CERAI_MATI' && 'Cerai Mati'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                    {data.birthplace ? data.birthplace : 'N/A'}, {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {
                        data.religion ?
                        <Text>
                          {data.religion === 'ISLAM' && 'Islam'}
                          {data.religion === 'CATHOLIC' && 'Catholic'}
                          {data.religion === 'PROTESTAN' && 'Protestan'}
                          {data.religion === 'BUDDHA' && 'Buddha'}
                          {data.religion === 'HINDU' && 'Hindu'}
                          {data.religion === 'OTHERS' && 'Others'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Height</Text>}>
                      {data.height ? `${data.height} cm` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">ID Card Number (KTP)</Text>}>
                      {data.nik ? data.nik : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Weight</Text>}>
                      {data.weight ? `${data.weight} kg` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Blood Type</Text>}>
                      {data.bloodType ? data.bloodType : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col span={24}>
                    <Text style={{fontSize : 17, color: '#242424'}}>Education Information </Text>
                </Col>

                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    
                    <Descriptions.Item label={<Text type="secondary">Origin University</Text>}>
                      {data.institutionName ? data.institutionName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Semester</Text>}>
                      {data.semester ? data.semester : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Field Of Study</Text>}>
                      {data.fieldOfStudyName ? data.fieldOfStudyName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">SKS Taken</Text>}>
                      {data.sksTaken ? data.sksTaken : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">NIM</Text>}>
                    {data.nim ? data.nim : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">GPA</Text>}>
                    {data.gpa ? data.gpa : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
          </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.partnersParticipant.detail,
})

const mapDispatchToProps = {
  actionGetDetail   : detailParticipant,
  unmountGetDetail  : unmountDetailParticipant,
 
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailParticipant)
