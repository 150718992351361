import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, ReadOutlined, FileProtectOutlined, ContainerOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
      <Menu.Item key="biodata" icon={<UserOutlined />}>
        <Link to={`/applicants/detail/${props.id}/biodata`}>
          Biodata
        </Link>
      </Menu.Item>
      <Menu.Item key="education" icon={<ReadOutlined />}>
        <Link to={`/applicants/detail/${props.id}/education`}>
          Education
        </Link>
      </Menu.Item>
      <Menu.Item key="supportingFile" icon={<FileProtectOutlined />}>
        <Link to={`/applicants/detail/${props.id}/supporting-file`}>
         Supporting File
        </Link>
      </Menu.Item>
      <Menu.Item key="followedProgram" icon={<ContainerOutlined />}>
        <Link to={`/applicants/detail/${props.id}/followed-program`}>
          Followed Program
        </Link>
      </Menu.Item>
    </Menu>
  )
}