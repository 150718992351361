import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Loading } from '../../../../components';
import { UploadOutlined, ContactsOutlined, DeleteOutlined } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { listInstitutions, unmountListInstitutions } from '../../../../redux/actions/admin/master/institutions/institutionsAction';
import { listFieldOfStudy, unmountMasterFieldOfStudy } from '../../../../redux/actions/admin/master/fieldOfStudy/fieldOfStudyAction';
import { detailAcademic, editAcademic, unmountDetailAcademic } from '../../../../redux/actions/admin/academic/academicAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Divider, Space, message, Breadcrumb, Image, Typography, DatePicker, Radio, Spin } from 'antd';

const { Option } = Select;

class EditAcademic extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      submitLoading: false,
      dataCity: [],
      dataProvince: [],
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      institutionId: null,
      fieldOfStudyId: null,
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ institutionId: response.data.institutionId, fieldOfStudyId: response.data.fieldOfStudyId })
      actionGetProvince(response.data.countryId, (response) => {
        this.setState({ dataProvince: response.data })
      })
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
    })
    actionGetCountry()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getData: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { actionCheckPhone, getData: { data } } = this.props;
    const valuePhone = value.replace(/^0+/, '')
    const oldMobilePhone = data.mobileNumber
    this.formRef.current.setFieldsValue({
      mobileNumber: value.replace(/^0+/, '')
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile Number already exist')
          this.formRef.current.setFieldsValue({
            mobileNumber: data.mobileNumber
          });
        }
      })
    }
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onClickInstitution = (val, e) => {
    this.setState({ institutionId: Number(e.key) })
  }

  onClickFieldOfStudy = (val, e) => {
    this.setState({ fieldOfStudyId: Number(e.key) })
  }

  onFinish = (values) => {
    const { editAvatar, avatar, institutionId, fieldOfStudyId } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    values.birthDate = values.birthDate ? moment(values.birthDate).format('DD/MM/YYYY') : ''
    values.institutionId = institutionId
    values.fieldOfStudyId = fieldOfStudyId
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    values.enabled = true
    values.id = data.id
    if(editAvatar){
      values.photoFile = avatar
    }
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/academic')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { getInstitution, getFieldOfStudy, getCountry, getData: { data, loading } } = this.props;
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity } = this.state;
    
    if(loading || getCountry.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Academic</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/academic">Academic List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Update Academic Data" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>User Account</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Email" 
                      name="email" 
                      tooltip="This email will be used for account login"
                      validateFirst
                      initialValue={data.email ? data.email : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'Your email address is invalid' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Mobile Number" 
                      name="mobileNumber"
                      tooltip="This mobile number will be used for account login"
                      validateFirst
                      initialValue={data.mobileNumber ? data.mobileNumber : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore="+62" onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Biodata</Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Form.Item 
                      label="First Name" 
                      name="firstName" 
                      validateFirst
                      initialValue={data.firstName ? data.firstName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Last Name" 
                      name="lastName" 
                      validateFirst
                      initialValue={data.lastName ? data.lastName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Front Title" 
                      name="frontTitle"
                      initialValue={data.frontTitle ? data.frontTitle : ''}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Back Title" 
                      name="backTitle"
                      initialValue={data.backTitle ? data.backTitle : ''}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Birth Place" 
                      name="birthPlace" 
                      validateFirst
                      initialValue={data.birthPlace ? data.birthPlace : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Birth Date" 
                      name="birthDate"
                      initialValue={data.birthDate ? moment(data.birthDate, 'DD/MM/YYYY') : ''}
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <DatePicker 
                        style={{ width: '100%' }} 
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Gender" 
                      name="gender"
                      initialValue={data.gender ? data.gender : ''}
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="MALE">Male</Radio>
                        <Radio value="FEMALE">Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Nationality" 
                      name="nationality"
                      initialValue={data.nationality ? data.nationality : ''}
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="University"
                      name="institutionId"
                      validateFirst
                      initialValue={data.institutionName ? data.institutionName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        onFocus={this.onFocusInstitution}
                        onSearch={this.onSearchInstitution}
                        notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        showSearch
                        placeholder="Select an university"
                        onSelect={this.onClickInstitution}
                      >
                        {
                          getInstitution?.data?.map((res) => (
                            <Option key={res.id} value={res.name}>
                              {res.name}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Home Base" 
                      name="fieldOfStudyId"
                      validateFirst
                      initialValue={data.fieldOfStudyName ? data.fieldOfStudyName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        onFocus={this.onFocusFieldOfStudy}
                        onSearch={this.onSearchFieldOfStudy}
                        notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        showSearch
                        placeholder="Select an field of study"
                        onSelect={this.onClickFieldOfStudy}
                      >
                        {
                          getFieldOfStudy?.data?.map((res) => (
                            <Option key={res.id} value={res.name}>
                              {res.name}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Country" 
                      name="countryId"
                      initialValue={data.countryId ? data.countryId : ''}
                    >
                      <Select
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Province" 
                      name="provinceId"
                      initialValue={data.provinceId ? data.provinceId : null}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="City" 
                      name="cityId"
                      initialValue={data.cityId ? data.cityId : null}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="streetAddress"
                      initialValue={data.streetAddress ? data.streetAddress : ''}
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="postalCode"
                      validateFirst
                      initialValue={data.postalCode ? data.postalCode : ''}
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Photo">
                      <Form.Item>
                        <Image width={100} src={editAvatar ? onPreviewAvatar : data.photoUrl} />
                      </Form.Item>
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editAvatar || data.photoUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Divider />

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetCountry, unmountGetDetail, unmountListInstitutions, unmountMasterFieldOfStudy } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountListInstitutions()
    unmountMasterFieldOfStudy()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.academic.detail,
  getInstitution    : state.institutions.list,
  getFieldOfStudy   : state.fieldOfStudy.list,
  getCountry        : state.countries.master
})

const mapDispatchToProps = {
  actionUpdate                : editAcademic,
  actionListInstitution       : listInstitutions,
  actionListFieldOfStudy      : listFieldOfStudy,
  actionCheckEmail            : checkEmail,
  actionCheckPhone            : checkPhone,
  actionGetDetail             : detailAcademic,
  actionGetCountry            : masterCountries,
  actionGetProvince           : provinceByCountry,
  actionGetCity               : cityByProvince,
  unmountGetCountry           : unmountMasterCountries,
  unmountGetDetail            : unmountDetailAcademic,
  unmountListInstitutions     : unmountListInstitutions,
  unmountMasterFieldOfStudy   : unmountMasterFieldOfStudy,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAcademic)