import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountDataProfile = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PARTNERS_PROFILE'})
}

export const dataProfile = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PARTNERS_PROFILE' })
  return API.GET('/partner/getProfile').then((response) => {
    dispatch({
      type: 'LOAD_DATA_PARTNERS_PROFILE_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_PARTNERS_PROFILE_FAILED' }), 
    ))
  })
}