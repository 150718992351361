import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListParticipant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PARTNERS_PARTICIPANT'})
}

export const unmountDetailParticipant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PARTNERS_PARTICIPANT'})
}

// export const unmountDetailPartnersProgram = () => (dispatch) => {
//   return dispatch({type: 'UNMOUNT_DETAIL_PARTNERS_PARTICIPANT_PROGRAM'})
// }

export const unmountMasterParticipant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_PARTICIPANT'})
}

export const listParticipant = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PARTNERS_PARTICIPANT' })
  return API.GET('/partner/listParticipant', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_PARTNERS_PARTICIPANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_PARTNERS_PARTICIPANT_FAILED' }), 
    ))
  })
}

export const detailParticipant = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_PARTNERS_PARTICIPANT' })
  const params = {
    'id': id
  }
  API.GET('/applicant/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_PARTNERS_PARTICIPANT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_PARTNERS_PARTICIPANT_FAILED' }), 
      ))
    )
  })
}

export const masterParticipant = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_PARTICIPANT' })
  return API.GET('/partner/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_PARTICIPANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_PARTICIPANT_FAILED' }), 
    ))
  })
}

export const createParticipant = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/partner/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editParticipant = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/partner/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableParticipant = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/partner/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableParticipant = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/partner/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}