import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';
import { UploadOutlined, FileTextFilled } from '@ant-design/icons';
import { createFile } from '../../../../redux/actions/admin/file/fileAction';
import { Card, Row, Col, PageHeader,Typography, Form, Input, Upload, Button, Divider, Space, message, Breadcrumb, DatePicker } from 'antd';
// const { Text } = Typography;

class CreateFile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      file: null,
      editFile: false,
      callingCode: "62",
      valuesArray : []
    }
  }
  
  componentDidMount() {}

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5 MB!');
          this.setState({ 
            editFile: false
          })
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            file: file, 
            editFile: true
          })
        }
        return false;
      },
    }
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { file } = this.state;
    const time = moment(values.publishedDate).format('DD/MM/YYYY HH:mm:ss')
    values.publishedDate = values.publishedDate ? moment(values.publishedDate).format('DD/MM/YYYY') : null
    values.publishedTime = values.publishedDate ? moment(time, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss') : null 
    values.file = file
    values.enabled = true
    this.setState({ submitLoading: true })
    actionCreate(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/file-management')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editFile } = this.state;

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><FileTextFilled /> File Management</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/file-management">File List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create File</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Create a File" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                <Col span={24} style={{marginBottom: 32}}>
                <Typography.Text style={{ fontSize: 20 }}>File Information</Typography.Text>
              </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="File Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'File Name must be at least 3 characters long' },
                        { max: 50, message: 'File Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="File Attachment"
                      name="file"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Space>
                        <Upload {...this.handleUpload()} showUploadList={editFile} maxCount={1}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editFile ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Published Date"
                      name="publishedDate"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        showTime 
                        format={'DD/MM/YYYY HH:mm:ss'} 
                        style={{ width: '100%' }} 
                        onChange={this.onChangeOpenDate}
                        allowClear={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="About" 
                      name="description"
                      initialValue=''
                      rules={[
                        { max: 1000, message: '1000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>

                 
                  <Divider />

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getFileMaster : state.file.master
})

const mapDispatchToProps = {
  actionCreate          : createFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFile)