import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { FileTextOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Space, Breadcrumb, Divider } from 'antd';
import { detailCourses, unmountDetailCourses } from '../../../../redux/actions/admin/courses/coursesAction';
const { Text } = Typography;

class DetailCourse extends Component {

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }
  
  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><FileTextOutlined /> Courses</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/academic/courses">Courses List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.code ? data.code : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Course Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Text style={{ fontSize: 20 }}>Course Information</Typography.Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Course Code</Text>}>
                      {data.code ? data.code : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Credit Weight (SKS)</Text>}>
                      {data.sks ? data.sks : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Course Name</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Semester</Text>}>
                      {data.semester ? data.semester : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Course Category</Text>}>
                      {data.category ? data.category === 'GENERAL_COURSE' ? 'General Course' : 'Major Course' : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">RPS Attachment</Text>}>
                      {
                        data.rpsAttachment ?
                          <a href={data.rpsAttachmentUrl} target="_blank" rel='noreferrer'>
                            {data.rpsAttachment}
                          </a>
                        :
                          'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Description</Text>}>
                      {data.description ? data.description : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                {
                  data.listCourseCpmk ?
                    <React.Fragment>
                      <Divider style={{ margin: 0 }} />
                      <Col span={24}>
                        <Typography.Text style={{ fontSize: 20 }}>CPMK</Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Space direction='vertical'>
                          {
                            data.listCourseCpmk.map((item,i) => 
                              <React.Fragment key={i}>
                                <Typography.Text>
                                  {i+1}. {item.component}
                                </Typography.Text>
                              </React.Fragment>
                            )
                          }
                        </Space>
                      </Col>
                    </React.Fragment>
                  :
                    null
                }
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.courses.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailCourses,
  unmountGetDetail  : unmountDetailCourses
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCourse)
