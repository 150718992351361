import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Result, Button } from 'antd';

export default class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM- 404</title>
        </Helmet>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" href="/">Back Dashboard</Button>}
          />
      </React.Fragment>
    )
  }
}
