import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import { Loading } from '../../../../../components';
import { Row, Col, Avatar, Typography, Space, Descriptions, Image, Tooltip, Divider } from 'antd';

const { Text } = Typography;
export class ViewProfile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { getProfile: { data, loading }, getDetailParticipant } = this.props;
    if(loading || getDetailParticipant?.loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        {/* Header */}
        <Row>
          <Col span={3} style={{ paddingLeft: 16 }}>
            <Avatar size={100} icon={<UserOutlined />} src={data.photoUrl ? <Image src={data.photoUrl} /> : null } />
          </Col>
          <Col span={12} style={{ paddingRight: 16, paddingLeft: 40 }}>
            <Space direction="vertical" size="small">
              <Text>{data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : 'N/A'}</Text>
              <Text>{data.email ? data.email : 'N/A'}</Text>
              <Text>{data.phoneNo ? `+62 ${data.phoneNo}` : 'N/A'}</Text>
              <Text>{data.currentCityName && data.currentCountryName ? `${data.currentCityName}, ${data.currentCountryName}` : null}</Text>
            </Space>
          </Col>
          <Col span={9}>
            <Descriptions layout="vertical" size="small">
              <Descriptions.Item label="About">
                {data.about ? data.about : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        {
          getDetailParticipant?.data ?
            <>
              <Row style={{ marginTop: 32, marginLeft: 16, marginRight: 16 }}>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Requirements</Text>
                {
                  getDetailParticipant?.data?.listDocument?.map((item,i) => 
                    <React.Fragment key={i}>
                      <Col span={23}>
                        <Text strong style={{ color: '#32AB6D' }}>{item.name}</Text>
                      </Col>
                      <Col span={1} className="float-right">
                        <Tooltip className="float-right" title="click to download file">
                          <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                        </Tooltip>
                      </Col>
                      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    </React.Fragment>
                  )
                }
              </Row>
              
              {
                getDetailParticipant.data.listCourse ?
                  <Row style={{ marginTop: 8, marginLeft: 16, marginRight: 16 }}>
                    <Text style={{ fontSize: 16, marginBottom: 8 }}>Conversion Courses</Text>
                    {
                      getDetailParticipant?.data?.listCourse?.map((item,i) => 
                        <Col key={i} span={24}>
                          <Text>{`${item.courseCode} - ${item.courseName} (${item.courseCategory === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.courseSks} SKS)`}</Text>
                        </Col>
                      )
                    }
                  </Row>
                : null
              }

              {
                getDetailParticipant.data.listLecturer ? 
                  <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                    <Text style={{ fontSize: 16, marginBottom: 8 }}>Supervising Lecturer</Text>
                    {
                      getDetailParticipant?.data?.listLecturer?.map((item,i) => 
                        <Col key={i} span={24}>
                          {/* <Text>{`${item.codeode} - ${item.courseName} (${item.courseCategory === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.courseSks} SKS)`}</Text> */}
                          <Text>{`${`${item.lecturerFirstName ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}`} (${item.role})`}</Text>
                        </Col>
                      )
                    }
                  </Row>
                : null
              }

              {
                getDetailParticipant.data.note ?
                  <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                    <Text style={{ fontSize: 16, marginBottom: 8 }}>Notes to Applicant</Text>
                      <Col span={24}>
                        <div dangerouslySetInnerHTML={{ __html: getDetailParticipant.data.note ? getDetailParticipant.data.note : 'N/A' }}></div>
                      </Col>
                  </Row>
                : null
              }
            </>
          : null
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile)