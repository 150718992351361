import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { columns } from './columns';
import { HeaderTable } from '../../../components';
import { ContactsOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, message, Modal } from 'antd';
// import { Result, Button } from 'antd';
import {  listParticipant, unmountDetailParticipant } from '../../../redux/actions/lecturers/participant/participantAction';
const { confirm } = Modal;

class Participant extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isMethod: true,
      isResult: true,
      checked: true,
      visible: false,
      visibleModalDetail: false,
      visibleModalEdit: false,
      valueModalEdit: null,
      listData: [],
      valueForm: null,
      idApplicant: null,
      dataParticipant: null,
      documentName: null,
      isModalNotes: false,
      meta: {
        page: 1,
        perpage: 10,
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }
  
  componentDidMount() {
    // const { checked, idApplicant, dataParticipant, listData, visible, visibleModalDetail, visibleModalEdit, valueModalEdit, submitLoading } = this.state;
    const { actionGetData } = this.props;
    const { meta } = this.state;
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.status = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  sorter = (pagination, filters, sorter) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.field = sorter.columnKey
    meta.sort = sorter.order ? sorter.order === "ascend" ? 'asc' : 'desc' : null
    return this.setState({ sortedInfo: sorter }, () => {
      return actionGetData(meta)
    })
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
     message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
     message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      // icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  onClickRow = (record) => {
    this.props.history.push(`/partners/detail/${record.participantId}`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/partners/edit/${id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  showModalEdit = (val) => {
    this.setState({ visibleModalEdit: true, valueModalEdit: val })
  }

  showModalDetail = (val) => {
    this.setState({ visibleModalDetail: true, valueModalEdit: val })
  }

  onFinishNotes = value => {
    const { meta, url, idApplicant } = this.state
    const { actionAddApplicantNotes, actionGetParticipant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetParticipant(meta, url, (response) => {
        this.setState({ listData: response.data, dataParticipant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }

  onFinish = (values) => {
    const { actionConfirmAssessment, actionDetailParticipant, actionUpdateAssessment, getDetailParticipant } = this.props;
    const { valueModalEdit } = this.state
    values.id = valueModalEdit.id
    values.letterGrade = values.score >= 85 ? 'A' : values.score >= 75 ? 'B' : values.score >= 60 ? 'C' : values.score >= 50 ? 'D' : 'E'
    this.setState({ submitLoading: true })
    if(valueModalEdit.status === "CONFIRMED"){
      return actionUpdateAssessment(values, () => {
        message.success('Success confirm assessment')
        this.setState({ visibleModalEdit: false, submitLoading: false })
        actionDetailParticipant(getDetailParticipant.data.id)
      }, 
      (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    }else{
      return actionConfirmAssessment(values, () => {
        message.success('Success confirm assessment')
        this.setState({ visibleModalEdit: false, submitLoading: false })
        actionDetailParticipant(getDetailParticipant.data.id)
      }, 
      (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    }
    
  }


  render() {
    // const { checked, idApplicant, dataParticipant, listData, visible, visibleModalDetail, visibleModalEdit, valueModalEdit, submitLoading } = this.state;
    const { getData: { data, loading, pagination } } = this.props;
    const { selectedRowKeys, sortedInfo, visibleModalDetail, visibleModalEdit } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: 'ACTIVE', name: 'Active' },
      { value: 'DONE', name: 'Done' }
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Participant</Breadcrumb.Item>
              <Breadcrumb.Item>Participant List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Participants List' 
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Created Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Name"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1200 }}
              rowSelection={rowSelection}
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(sortedInfo, this.onClickEdit, this.handleEnable, this.handleDisable, this.onClickRow)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
          <Col span={18}>
            {/* <Card>
              {
                dataParticipant ? 
                <ContentParticipant 
                  dataParticipant={dataParticipant}
                  detailParticipant={getDetailParticipant}
                  onPriviewProfile={this.onPriviewProfile}
                  showModalEdit={this.showModalEdit}
                  showModalDetail={this.showModalDetail}
                />
                : 
                <Empty description={false} />
              }
            </Card> */}
          </Col>
        </Row>
          <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleModalDetail}
          onCancel={this.onCancel}
          title="Detail Assessment"
          footer={false}
        >
          {/* <ModalDetail 
            valueModalEdit={valueModalEdit}
          /> */}
        </Modal>
        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleModalEdit}
          onCancel={this.onCancel}
          title="Input Assessment"
          footer={false}
        >
          {/* <ModalEdit 
            formRef={this.formRef} 
            valueModalEdit={valueModalEdit}
            onFinish={this.onFinish}
            submitLoading={submitLoading}
            // getDataLecture={getDataLecture}
            // {...this.props} 
            // {...this.state}
            // checkEmail={this.checkEmail}
            // checkPhone={this.checkPhone}
            // handleChangeCountry={this.handleChangeCountry}
            // handleChangeProvince={this.handleChangeProvince}
            // handleChangeCity={this.handleChangeCity}
            // handleChangeSubDistrict={this.handleChangeSubDistrict}
            // onSubmitInformation={this.onSubmitInformation}
            // handleChangeCallingCode={this.handleChangeCallingCode}
          /> */}
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.lecturersParticipant.list
})

const mapDispatchToProps = {
  actionGetData   : listParticipant,
  // actionEnable    : enablePartner,
  // actionDisable   : disablePartner,
  unmountGetData  : unmountDetailParticipant
}

export default connect(mapStateToProps, mapDispatchToProps)(Participant)
