import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { BankOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions,Space, Typography, Image, Breadcrumb, Divider, Empty } from 'antd';
import { detailPartners, detailPartnersProgram, unmountDetailPartners, unmountDetailPartnersProgram } from '../../../../redux/actions/admin/partners/partnersAction';
import moment from 'moment';
const { Text } = Typography;

class AcademicDetailPartner extends Component {

  componentDidMount() {
    const { actionGetDetail,actionGetDetailPartnerProgram, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetDetailPartnerProgram(params.id)
  }
  
  render() {
    const { getData: { data, loading }, getDataPartnersProgram} = this.props;
  
    if(loading || getDataPartnersProgram.loading){

      return <Loading />
    }
  
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Partners</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/academic/partners">Partners List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.number ? data.number : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Partner Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
              <Col span={24}>
                          <Text style={{fontSize : 17, color: '#242424'}}>Partner Information</Text>
              </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Partner Name</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Partner Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                      {data.contactPerson ? data.contactPerson : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                      {data.cpMobile ? `+62${data.cpMobile}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Industry</Text>}>
                      {data.industriesName ? data.industriesName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Specialties</Text>}>
                      {data.specialties ? data.specialties : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Office Phone Number</Text>}>
                      {data.officePhone ? data.officePhone : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.streetAddress ? data.streetAddress : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.postalCode ? data.postalCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Size</Text>}>
                      {
                        data.companySize ?
                          data.companySize === 'LARGE' ? 
                            'Large'
                          : data.companySize === 'MEDIUM' ? 
                            'Medium'
                          : data.companySize === 'SMALL' ? 
                            'Small'
                          : null
                        :
                          'N/A'
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Website</Text>}>
                      {data.website ? data.website : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Logo</Text>}>
                      {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col span={24}>
                    <Text style={{fontSize : 17, color: '#242424'}}>Partner Program </Text>
                </Col>
                
                <Col span={24}>
                {
                  getDataPartnersProgram?.data?.length > 0 ? 
                  getDataPartnersProgram.data.map(item => 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <Space direction="vertical">
                              <Text type='secondary' style={{color: '#3274D7'}}>{item.programNumber ? item.programNumber : 'N/A'}</Text>
                              <Text type='secondary' style={{color: '#000000'}}>{item.title ? item?.title : 'N/A'}</Text>
                            </Space>
                          </Col>

                          <Col span={8}>
                            <Space direction="vertical">
                                <Text type='secondary'>{item.programCategory ? item.programCategory : 'N/A'}</Text>
                                <Text type='secondary'>{item.partnerName ? item.partnerName : 'N/A'}</Text>
                            </Space>
                          </Col>

                          <Col span={8}>
                            <Space direction="vertical">
                                <Text type='secondary'>{item.cityName ? item.cityName : 'N/A'}</Text>
                                <Text type='secondary'>{item.startDate ? moment(item.startDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'} - {item.endDate ? moment(item.endDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}</Text>
                            </Space>
                          </Col>
                    </Row>
                  <Divider/>
                </React.Fragment>
              ) : <Empty/>
              }
            </Col>
          </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountDetailPartnersProgram } = this.props;
    unmountGetDetail()
    unmountDetailPartnersProgram()
  }
}

const mapStateToProps = (state) => ({
  getData: state.partners.detail,
  getDataPartnersProgram : state.partners.detailPartnersProgram
})

const mapDispatchToProps = {
  actionGetDetail   : detailPartners,
  actionGetDetailPartnerProgram : detailPartnersProgram,
  unmountDetailPartnersProgram : unmountDetailPartnersProgram,
  unmountGetDetail  : unmountDetailPartners,
 
}

export default connect(mapStateToProps, mapDispatchToProps)(AcademicDetailPartner)
