import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Collapse, Row, Col, Button, Checkbox, Select, Card, Typography } from 'antd';
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

class FilterSiderDetailJob extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      valueForm: null,
      clear: false,
      minAge: 17
    }
  }
  
  handleChangeValue = (value) => {
    value.gender = value.gender ? value.gender : ''
    value.minAge = value.minAge ? value.minAge : ''
    value.maxAge = value.maxAge ? value.maxAge : ''
    value.educationLevelId = value.educationLevelId ? value.educationLevelId : ''
    if(value.gender || value.minAge || value.maxAge || value.educationLevelId){
      this.setState({ valueForm: value })
    }else{
      this.setState({ valueForm: null })
    }
  }

  handleChangeAge = (e) => {
    this.setState({ minAge: e })
    this.formRef.current.setFieldsValue({
      maxAge: '',
    })
  }

  handleClearForm = () => {
    this.formRef.current.setFieldsValue({
      gender: [],
      minAge: '',
      maxAge: '',
      educationLevelId: '',
    });
    this.setState({ valueForm: null })
  }

  onFinish = (values) => {
    const { onFilter } = this.props;
    values.gender = values.gender.length > 0 ? values.gender.join() : ''
    values.minAge = values.minAge ? values.minAge : ''
    values.maxAge = values.maxAge ? values.maxAge : ''
    return onFilter(values)
  }
  
  render() {
    const { valueForm, minAge } = this.state;

    const minAgeOption = [];
    for (let i = 17; i < 36; i++) {
      minAgeOption.push(<Option key={i}>{i}</Option>);
    }

    const maxAgeOption = [];
    for (let i = minAge; i < 36; i++) {
      maxAgeOption.push(<Option key={i}>{i}</Option>);
    }

    return (
      <React.Fragment>
        <Form 
          onFinish={this.onFinish}
          ref={this.formRef} 
          onValuesChange={(_, value) => {
            this.handleChangeValue(value)
          }}
        >
          <Card 
            title="Filter" 
            extra={valueForm ? <Link to="#" style={{ color: '#cc5823' }} onClick={() => this.handleClearForm()}>Clear All</Link> : null}
          >
            <Row gutter={[8, 24]}>
              <Col span={24}>
                <Collapse
                  bordered={false}
                  expandIconPosition="right"
                  defaultActiveKey={['1', '2', '3']}
                  className="site-collapse-custom-collapse"
                >
                  <Panel header={<Text style={{ color: valueForm && valueForm.gender.length > 0 ? '#32ab6d' : 'black' }}>Gender</Text>} key="1">
                    <Form.Item name="gender" initialValue="">
                      <Checkbox.Group>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Checkbox value="MALE" style={{ lineHeight: '32px' }}>
                              Male
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="FEMALE" style={{ lineHeight: '32px' }}>
                              Female
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel>
                  <Panel header={<Text style={{ color: (valueForm && valueForm.minAge) || (valueForm && valueForm.maxAge) ? '#32ab6d' : 'black' }}>Age</Text>} key="2">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item name="minAge">
                          <Select placeholder="Min" showSearch onChange={this.handleChangeAge}>
                            {minAgeOption}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="maxAge">
                          <Select placeholder="Max" showSearch>
                            {maxAgeOption}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSiderDetailJob)
