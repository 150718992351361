import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Loading } from '../../../../components';
import { UploadOutlined, ContactsOutlined, DeleteOutlined } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/admin/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/admin/master/countries/countriesAction';
import { listInstitutions, unmountListInstitutions } from '../../../../redux/actions/admin/master/institutions/institutionsAction';
import { listFieldOfStudy, unmountMasterFieldOfStudy } from '../../../../redux/actions/admin/master/fieldOfStudy/fieldOfStudyAction';
import { createLecturer } from '../../../../redux/actions/admin/lecturers/lecturersAction';
import { Card, Row, Col, PageHeader, Form, Input, DatePicker, Radio, Select, Upload, Button, Divider, Space, message, Breadcrumb, Image, Typography, Spin } from 'antd';

const { Option } = Select;

class CreateLecturer extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      dataProvince: [],
      dataCity: [],
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      institutionId: null,
      fieldOfStudyId: null,
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetCountry, actionGetProvince } = this.props;
    actionGetProvince(1, (response) => {
      this.setState({ dataProvince: response.data })
    })
    actionGetCountry()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  checkEmail = (value) => {
    const { actionCheckEmail } = this.props;
    return actionCheckEmail(value, (response) => {
      if(response){
        message.error('Email already exist')
        this.formRef.current.setFieldsValue({
          email: null,
        });
      }
    })
  }

  checkPhone = (value) => {
    const { actionCheckPhone } = this.props;
    const valuePhone = value.replace(/^0+/, '')
    this.formRef.current.setFieldsValue({
      mobileNumber: value.replace(/^0+/, '')
    });
    return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
      if(response){
        message.error('Mobile Number already exist')
        this.formRef.current.setFieldsValue({
          mobileNumber: null,
        });
      }
    })
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onClickInstitution = (val, e) => {
    this.setState({ institutionId: Number(e.key) })
  }

  onClickFieldOfStudy = (val, e) => {
    this.setState({ fieldOfStudyId: Number(e.key) })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { editAvatar, avatar, institutionId, fieldOfStudyId } = this.state;
    values.birthDate = values.birthDate ? moment(values.birthDate).format('DD/MM/YYYY') : ''
    values.institutionId = institutionId
    values.fieldOfStudyId = fieldOfStudyId
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    values.enabled = true
    if(editAvatar){
      values.photoFile = avatar
    }
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/lecturers')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity } = this.state;
    const { getInstitution, getFieldOfStudy, getCountry } = this.props;

    if(getCountry.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ContactsOutlined /> Lecturers</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/lecturers">Lecturer List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create Lecturer</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Create a Lecturer" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>User Account</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Email" 
                      name="email" 
                      tooltip="This email will be used for account login"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'Your email address is invalid' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Mobile Number" 
                      name="mobileNumber"
                      tooltip="This mobile number will be used for account login"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore="+62" onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Biodata</Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Form.Item 
                      label="First Name" 
                      name="firstName" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Last Name" 
                      name="lastName" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Front Title" 
                      name="frontTitle"
                      initialValue=''
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Back Title" 
                      name="backTitle"
                      initialValue=''
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Birth Place" 
                      name="birthPlace" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Birth Date" 
                      name="birthDate"
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <DatePicker 
                        style={{ width: '100%' }} 
                        format="DD/MM/YYYY" 
                        defaultPickerValue={moment('2000-01-01')} 
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Gender" 
                      name="gender"
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="MALE">Male</Radio>
                        <Radio value="FEMALE">Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Religion"
                      name="religion"
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value="ISLAM">Islam</Select.Option>
                        <Select.Option key="2" value="CATHOLIC">Catholic</Select.Option>
                        <Select.Option key="3" value="PROTESTAN">Protestan</Select.Option>
                        <Select.Option key="4" value="BUDDHA">Buddha</Select.Option>
                        <Select.Option key="5" value="HINDU">Hindu</Select.Option>
                        <Select.Option key="6" value="OTHERS">Others</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Nationality" 
                      name="nationality"
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Photo">
                      {
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{editAvatar ? 'Change Photo' : 'Upload Photo'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Lecturer Information</Typography.Text>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="University"
                      name="institutionId"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        onFocus={this.onFocusInstitution}
                        onSearch={this.onSearchInstitution}
                        notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        showSearch
                        placeholder="Select an university"
                        onSelect={this.onClickInstitution}
                      >
                        {
                          getInstitution?.data?.map((res) => (
                            <Option key={res.id} value={res.name}>
                              {res.name}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Home Base" 
                      name="fieldOfStudyId"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        onFocus={this.onFocusFieldOfStudy}
                        onSearch={this.onSearchFieldOfStudy}
                        notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        showSearch
                        placeholder="Select an field of study"
                        onSelect={this.onClickFieldOfStudy}
                      >
                        {
                          getFieldOfStudy?.data?.map((res) => (
                            <Option key={res.id} value={res.name}>
                              {res.name}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="NIDN" 
                      name="nidn" 
                      initialValue=''
                      rules={[
                        { min: 3, message: 'At least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' },
                        { pattern: /^[0-9]*$/, message: 'Can only use numbers' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <Typography.Text style={{ fontSize: 20 }}>Address</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Country" name="countryId" initialValue={1}>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Province" name="provinceId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="City" name="cityId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="streetAddress"
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="postalCode"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Divider />

                  {/* Button */}
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetCountry, unmountListInstitutions, unmountMasterFieldOfStudy } = this.props;
    unmountGetCountry()
    unmountListInstitutions()
    unmountMasterFieldOfStudy()
  }
}

const mapStateToProps = (state) => ({
  getInstitution    : state.institutions.list,
  getFieldOfStudy   : state.fieldOfStudy.list,
  getCountry  : state.countries.master
})

const mapDispatchToProps = {
  actionCreate : createLecturer,
  actionListInstitution       : listInstitutions,
  actionListFieldOfStudy      : listFieldOfStudy,
  actionCheckEmail      : checkEmail,
  actionCheckPhone      : checkPhone,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  unmountListInstitutions     : unmountListInstitutions,
  unmountMasterFieldOfStudy   : unmountMasterFieldOfStudy,
  unmountGetCountry     : unmountMasterCountries,

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLecturer)