import React, { Component } from 'react';
import 'react-quill/dist/quill.snow.css';
import { UserOutlined, DownloadOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Space, Typography, Divider, Tooltip, Button, Tabs, Timeline, Empty, Tag } from 'antd';
const { Title, Text, Paragraph } = Typography

export default class ContentParticipant extends Component {
  render() {
    // const { dataParticipant, openModalProfile, formRef, isResult, isMethod, handleChangeAssessmentResult, handleChangeMethod, showModalNotes, getApplicantNotes } = this.props;
    const { dataParticipant, detailParticipant, onPriviewProfile, showModalDetail, showModalEdit } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={17}>
            <Space size="large">
              <Avatar size={64} src={dataParticipant.photoUrl ? dataParticipant.photoUrl : null } icon={!dataParticipant.photoUrl ? <UserOutlined /> : null} />
              <div>
                <Title level={4}>{dataParticipant.applicantName ? dataParticipant.applicantName : 'N/A'}</Title>
                <Text type="secondary">{dataParticipant.participantNumber ? dataParticipant.participantNumber : 'N/A'}</Text>
                <br />
                <Space direction='vertical' size={0}>
                  <Text>
                    {dataParticipant.institutionName ? dataParticipant.institutionName : 'N/A'} 
                  </Text>
                  <Text>
                    {dataParticipant.fieldOfStudyName ? dataParticipant.fieldOfStudyName : 'N/A'}
                  </Text>
                </Space>
              </div>
            </Space>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* {
                getApplicantNotes?.data?.length > 0 ?
                  <Badge dot offset={[-2, 2]} style={{ width: 10, height: 10 }}>
                    <Button 
                      className="btn-create-default" 
                      onClick={() => showModalNotes(dataParticipant.id, dataParticipant.applicantNotes)}
                    >
                      <FileDoneOutlined /> Applicant Notes
                    </Button>
                  </Badge>
                :
                  <Button 
                    className="btn-create-default" 
                    onClick={() => showModalNotes(dataParticipant.id)}
                  >
                    <FileDoneOutlined /> Applicant Notes
                  </Button>
              } */}
              <Button className="btn-color-blue" onClick={() => onPriviewProfile(dataParticipant.applicantId, dataParticipant.id)}>
                View Profile
              </Button>
            </Space>
          </Col>
        </Row>

        <Divider style={{ marginBottom: 8 }} />
        
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Detail" key="1">
            <Row style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 16, marginBottom: 8 }}>Requirements</Text>
              {
                detailParticipant?.data?.listDocument?.map((item,i) => 
                  <React.Fragment key={i}>
                    <Col span={23}>
                      <Text strong style={{ color: '#32AB6D' }}>{item.name}</Text>
                    </Col>
                    <Col span={1} className="float-right">
                      <Tooltip className="float-right" title="click to download file">
                        <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                      </Tooltip>
                    </Col>
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                  </React.Fragment>
                )
              }
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 16, marginBottom: 8 }}>Conversion Courses</Text>
              {
                detailParticipant?.data?.listCourse?.map((item,i) => 
                  <Col key={i} span={24}>
                    <Text>{`${item.courseCode} - ${item.courseName} (${item.courseCategory === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.courseSks} SKS)`}</Text>
                  </Col>
                )
              }
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 16, marginBottom: 8 }}>Supervising Lecturer</Text>
              {
                detailParticipant?.data?.listLecturer?.map((item,i) => 
                  <Col key={i} span={24}>
                    {/* <Text>{`${item.codeode} - ${item.courseName} (${item.courseCategory === 'MAJOR_COURSE' ? 'Mata Kuliah Jurusan' : 'Mata Kuliah Umum'} - ${item.courseSks} SKS)`}</Text> */}
                    <Text>{`${`${item.lecturerFirstName ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}`} (${item.role})`}</Text>
                  </Col>
                )
              }
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Text style={{ fontSize: 16, marginBottom: 8 }}>Notes to Applicant</Text>
                <Col span={24}>
                  <div dangerouslySetInnerHTML={{ __html: detailParticipant?.data?.note ? detailParticipant?.data?.note : 'N/A' }}></div>
                </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Log Activity" key="2">
            <Timeline style={{ marginTop: 16 }}>
              {
                detailParticipant?.data?.listActivity ? 
                  detailParticipant?.data?.listActivity?.map((item,i) =>
                    <Timeline.Item key={i}>
                      <Row>
                        <Col span={4}>
                          <Text style={{ color: '#32ab6d' }}>
                            {item.activityDate}
                          </Text>
                        </Col>
                        <Col span={18}>
                          <Paragraph ellipsis={true ? { rows: 3, expandable: true, symbol: 'View More'} : false}>
                            {item.summaryReport}
                          </Paragraph>
                        </Col>
                        <Col span={2}>
                          {
                            item.fileUrl ?
                              <a href={item.fileUrl} target="_blank" rel='noreferrer' style={{ float: 'right' }}>
                                <Tooltip title="Download">
                                  <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                </Tooltip>
                              </a>
                            : null
                          }
                        </Col>
                      </Row> 
                    </Timeline.Item>
                  )
                :
                  <Empty />
              }
            </Timeline>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assessment" key="3">
            {
              detailParticipant?.data?.listAssessment?.map((item,i) => 
                <React.Fragment key={i}>
                  <Row align="middle">
                    <Col span={11}>
                      <Space direction='vertical'>
                        <Text strong style={{ color: '#32AB6D' }}>{item.courseCode} - {item.courseName}</Text>
                        <Text>{item.category === 'GENERAL_COURSE' ? 'Mata Kuliah Umum' : 'Mata Kuliah Jurusan'} - {item.courseSks} SKS</Text>
                      </Space>
                    </Col>
                    <Col span={2}>
                      <Space direction='vertical'>
                        <Text strong style={{ color: '#32AB6D' }}>{item.letterGrade ? item.letterGrade : 'N/A'}</Text>
                        <Text>{item.score ? item.score : 'N/A'}</Text>
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Space direction='vertical'>
                        <Text strong style={{ color: '#32AB6D' }}>{item.lecturerFirstName ? `${item.lecturerFrontTitle ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}` : 'N/A'}</Text>
                        <Text>{item.assessmentDate ? `pada ${item.assessmentDate}` : 'N/A'}</Text>
                      </Space>
                    </Col>
                    <Col span={5}>
                      <Space style={{ float: 'right' }}>
                        {
                          item.status === 'CONFIRMED' ? 
                            <>
                              <Tag color="green">Confirmed</Tag>
                              <Tooltip title="view detail" onClick={() => showModalDetail(item)}>
                                <EyeOutlined style={{ color: '#32AB6D' }} />
                              </Tooltip>
                            </>
                          : 
                            <Tag color="orange">Unconfirmed</Tag>
                        }
                        <Tooltip title="edit assessment" onClick={() => showModalEdit(item)}>
                          <EditOutlined style={{ color: '#32AB6D' }} />
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                  <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                </React.Fragment>
              )
            }
          </Tabs.TabPane>
        </Tabs>
      </React.Fragment>
    )
  }
}