import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { IdcardOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Tag, Typography, Image, Breadcrumb, Switch, message, Space } from 'antd';
import { detailNews, enableNews, disableNews, unmountDetailNews } from '../../../../redux/actions/admin/news/newsAction';
import moment from 'moment';
const { Text } = Typography;

class DetailNews extends Component {

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }
  
  handleChangeStatus = (val) => {
    const { actionDisable, actionEnable, actionGetDetail, match: { params } } = this.props;
    if(val){
      return actionEnable(params.id, () => {
        message.success('Successfully changed to Published')
        return actionGetDetail(params.id)
      }) 
    }else{
      return actionDisable(params.id, () => {
        message.success('Successfully changed to Unpublished')
        return actionGetDetail(params.id)
      }) 
    }
  }

  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><IdcardOutlined /> News</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/news">News List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{data.title ? data.title : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='News Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Space>
                    {
                      data.status === "PUBLISHED" ? 
                        <Tag color="green">Status: Published</Tag> 
                      : 
                        <Tag color="orange">Status: Unpublished</Tag>
                    }
                    <Switch defaultChecked={data.status === "PUBLISHED"} onChange={this.handleChangeStatus} />
                  </Space>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label={<Text type="secondary">Title</Text>}>
                      {data.title ? data.title : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Post Slug</Text>}>
                      {data.slug ? data.slug : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Category</Text>}>
                      {data.newsCategory ? data.newsCategory : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Tags</Text>}>
                      {data.tag ? data.tag : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Published Date</Text>}>
                      {data.publishedDate ? moment(data.publishedDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}  {data.publishedTime ? moment(data.publishedTime, 'hh:mm:ss').format('hh:mm:ss') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Descriptions</Text>}>
                      <div dangerouslySetInnerHTML={{ __html: data.content ? data.content : 'N/A' }}></div>
                    </Descriptions.Item>
                  </Descriptions>
                  <Space size={16}>
                    <Space direction='vertical'>
                      <Text type='secondary'>Main Image :</Text>
                      <Image src={data.mainImageUrl} height={100} width={100} style={{ objectFit: 'cover' }} />
                    </Space>
                    {
                      data.image2Url ?
                        <Space direction='vertical'>
                          <Text type='secondary'>Image 2 :</Text>
                          <Image src={data.image2Url} height={100} width={100} style={{ objectFit: 'cover' }} />
                        </Space>
                      : null
                    }
                    {
                      data.image3Url ?
                        <Space direction='vertical'>
                          <Text type='secondary'>Image 3 :</Text>
                          <Image src={data.image3Url} height={100} width={100} style={{ objectFit: 'cover' }} />
                        </Space>
                      : null
                    }
                    {
                      data.image4Url ?
                        <Space direction='vertical'>
                          <Text type='secondary'>Image 4 :</Text>
                          <Image src={data.image4Url} height={100} width={100} style={{ objectFit: 'cover' }} />
                        </Space>
                      : null
                    }
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.news.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailNews,
  actionEnable   : enableNews,
  actionDisable  : disableNews,
  unmountGetDetail  : unmountDetailNews
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailNews)
