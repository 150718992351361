import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { createCity } from '../../../../../redux/actions/admin/master/cities/citiesAction';
import { provinceByCountry } from '../../../../../redux/actions/admin/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/admin/master/countries/countriesAction';
import { Col, Form, Row, Input, Button, Space, Select, Divider, PageHeader, message, Card, Breadcrumb } from 'antd';

class CreateMasterCity extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      dataProvince: [],
    }
  }

  componentDidMount() {
    const { actionGetCountry } = this.props;
    return actionGetCountry()
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
    });
    this.setState({ callingCode: value })
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }
  
  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/cities')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, dataProvince } = this.state;
    const { getCountry: { loading, data } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} ref={this.formRef} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/cities">
                    Master Data List Cities
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Cities</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Create a City" />
            </Col>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Country" 
                      name='countryId'
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Province" 
                      name="provinceId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="City Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'City name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this city isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description" 
                      initialValue=''
                      rules={[
                        { max: 50, message: 'Description is too long. Maximum is 50 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                      <Button className="btn-save-primary" type="default"onClick={() => window.history.back()}>Cancel</Button>
                      <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetCountry } = this.props;
    return unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countries.master
})

const mapDispatchToProps = {
  actionCreate      : createCity,
  actionGetCountry  : masterCountries,
  actionGetProvince : provinceByCountry,
  unmountGetCountry : unmountMasterCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterCity)