import jwt from 'jsonwebtoken';
import Cookie from "js-cookie";
import { API } from '../../../config';
import { errorHandler } from './errorAction';
import { ADMIN, ACADEMIC, LECTURER, PARTNER, NOT_FOUND } from '../../../permissions';

export const checkAuth = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_AUTH' })
  const getToken = Cookie.get('user')
  if(getToken) {
    const userData = jwt.decode(getToken)
    const permission = getPermission(userData.role)
    if(getToken !== null) {
      return dispatch({ 
        type: 'LOAD_AUTH_SUCCESS', 
        payload: { 
          user: userData,
          role: userData.role,
          permission: permission
        } 
      })
    }
  }else {
    return dispatch({ type: 'LOAD_AUTH_FAILED' })
  }
}

export const checkToken = (successCB, failedCB) => async (dispatch) => {
  const params = {
    'token': Cookie.get('user')
  }
  API.POST_LOGIN_NO_WITH_BEARER('/auth/check', params).then((response) => {
    if(response.code === "2222"){
      dispatch(removedata(successCB))
    }
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const setLogin = (value, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_AUTH' })
  API.POST_LOGIN('/auth/login', value).then((response) => {
    const token = response.data.token
    // const refreshToken = response.refreshToken
    const userData = jwt.decode(token)
    const permission = getPermission(userData.role)
    Cookie.set('user', token)
    // Cookie.set('userRefreshTkn', refreshToken)
    dispatch({ type: 'LOAD_AUTH_SUCCESS', payload: { 
      userData: userData,
      role: userData.role,
      permission: permission
    } 
  })
    return successCB && successCB(response)
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_AUTH_FAILED' })
    return failedCB && failedCB(err)
  })
}

export const setLogout = (successCB, failedCB) => async dispatch => {
  const getToken = Cookie.get('user');
  const params = {
    token: getToken
  }
  return API.POST_WITH_PARAMS('/auth/logout', params).then(() => {
    return dispatch(removedata(() => {
      dispatch({ type: 'SET_LOGOUT_SUCCESS'})
      return successCB && successCB();
    }))
  }).catch((err) => {
    return (
      failedCB && failedCB(),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'SET_LOGOUT_FAILED'})
      ))
    )
  })
}

export const getPermission = (type) => {
  switch (type) {
    case 'ROLE_ADMIN':
      return ADMIN;
    case 'ROLE_ACADEMIC':
      return ACADEMIC;
    case 'ROLE_LECTURER':
      return LECTURER;
    case 'ROLE_PARTNER':
      return PARTNER;
    default:
      return NOT_FOUND;
    
  }
}

export const removedata = (cb) => async (dispatch) => {
  await Cookie.remove('user')
  await Cookie.remove('userRefreshTkn')
  await dispatch({ type: 'UNMOUNT_DATA_CITIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_CITIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_CITIES' })
  await dispatch({ type: 'UNMOUNT_DATA_ACADEMIC' })
  await dispatch({ type: 'UNMOUNT_DETAIL_ACADEMIC' })
  await dispatch({ type: 'UNMOUNT_DATA_APPLICANTS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_APPLICANTS' })
  await dispatch({ type: 'UNMOUNT_FOLLOWED_PROGRAM_APPLICANTS' })
  await dispatch({ type: 'UNMOUNT_DATA_COURSES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_COURSES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_COURSES' })
  await dispatch({ type: 'UNMOUNT_DATA_FILE' })
  await dispatch({ type: 'UNMOUNT_DETAIL_FILE' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_FILE' })
  await dispatch({ type: 'UNMOUNT_DATA_LECTURERS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_LECTURERS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_LECTURERS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_SUPERVISING_LECTURERS' })
  await dispatch({ type: 'UNMOUNT_DATA_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_DOCUMENT_TYPE' })
  await dispatch({ type: 'UNMOUNT_DETAIL_DOCUMENT_TYPE' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_DOCUMENT_TYPE' })
  await dispatch({ type: 'UNMOUNT_DATA_FIELD_OF_STUDY' })
  await dispatch({ type: 'UNMOUNT_DETAIL_FIELD_OF_STUDY' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_FIELD_OF_STUDY' })
  await dispatch({ type: 'UNMOUNT_DATA_INDUSTRIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_INDUSTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_INDUSTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_INSTITUTIONS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_INSTITUTIONS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_INSTITUTIONS' })
  await dispatch({ type: 'UNMOUNT_DATA_POSITIONS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_POSITIONS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_POSITIONS' })
  await dispatch({ type: 'UNMOUNT_DATA_PROVINCES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PROVINCES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_PROVINCES' })
  await dispatch({ type: 'UNMOUNT_DATA_SUB_DISTRICT' })
  await dispatch({ type: 'UNMOUNT_DETAIL_SUB_DISTRICT' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_SUB_DISTRICT' })
  await dispatch({ type: 'UNMOUNT_DATA_VILLAGE' })
  await dispatch({ type: 'UNMOUNT_DETAIL_VILLAGE' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_VILLAGE' })
  await dispatch({ type: 'UNMOUNT_DATA_NEWS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_NEWS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_NEWS' })
  await dispatch({ type: 'UNMOUNT_DATA_PARTICIPANTS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PARTICIPANTS' })
  await dispatch({ type: 'UNMOUNT_DATA_PARTNERS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PARTNERS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PARTNERS_PROGRAM' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_PARTNERS' })
  await dispatch({ type: 'UNMOUNT_DATA_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_PROGRAMS_LIST_APPLICANT' })
  await dispatch({ type: 'UNMOUNT_PROGRAMS_LIST_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_PROGRAMS_LIST_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DATA_LECTURERS_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DETAIL_LECTURERS_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DETAIL_LECTURERS_PARTICIPANT_INFORMATION' })
  await dispatch({ type: 'UNMOUNT_DETAIL_LECTURERS_PARTICIPANT_FOLLOWED' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DATA_LECTURERS_PROFILE' })
  await dispatch({ type: 'UNMOUNT_DATA_LECTURERS_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_LECTURERS_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_LECTURERS_PROGRAMS_LIST_APPLICANT' })
  await dispatch({ type: 'UNMOUNT_LECTURERS_PROGRAMS_DETAIL_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DATA_PARTNERS_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PARTNERS_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_DATA_PARTNERS_PROFILE' })
  await dispatch({ type: 'UNMOUNT_DATA_PARTNERS_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_PARTNERS_PROGRAMS' })
  await dispatch({ type: 'UNMOUNT_PARTNERS_PROGRAMS_LIST_APPLICANT' })
  await dispatch({ type: 'UNMOUNT_PARTNERS_PROGRAMS_LIST_PARTICIPANT' })
  await dispatch({ type: 'UNMOUNT_PARTNERS_PROGRAMS_DETAIL_PARTICIPANT' })
  return cb()
}
