import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Divider, Modal, message, Empty } from 'antd';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import Menu from '../Menu';
import svg from '../../../../../assets/img/profile.svg';
import { detailLecturers, detailSupervisingLecturers, unmountDetailLecturers, unmountDetailSupervisingLecturers } from '../../../../../redux/actions/admin/lecturers/lecturersAction';
import { SolutionOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;


class LecturerDetailSupervisingLecturer extends Component {
    formRef = React.createRef();
    constructor(props) {
      super(props)
    
      this.state = {
        dataEducation: null,
        submitLoading: false,
        visibleAdd: false,
        visibleEdit: false,
        startYear: null,
        endYear: null,
        isYear: false,
        metaInstitution: {
          page: 1,
          perpage: 10,
          search: ''
        },
        metaFieldOfStudy: {
          page: 1,
          perpage: 10,
          search: ''
        }
      }
      this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
      this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
    }
    
  
    componentDidMount() {
      const { actionGetDetail,actionGetSupervisingLecturers, match: { params } } = this.props;
      actionGetDetail(params.id)
      actionGetSupervisingLecturers(params.id)
    }
  
    showModal = (type, item) => {
      if(type === 'add'){
        return this.setState({ visibleAdd: true })
      }
      if(type === 'edit'){
        const { metaInstitution, metaFieldOfStudy } = this.state;
        const { actionGetInstitution, actionGetFieldOfStudy } = this.props;
        metaInstitution.search = item.name
        metaFieldOfStudy.search = item.fieldOfStudy
        return this.setState({ 
          visibleEdit: true, 
          dataEducation: item,
        }, () => {
          actionGetInstitution(metaInstitution)
          actionGetFieldOfStudy(metaFieldOfStudy)
        })
      }
    }
  
    onCancel = (type) => {
      const { metaInstitution, metaFieldOfStudy } = this.state;
      if(type === 'add'){
        metaInstitution.page = 1
        metaInstitution.perpage = 10
        metaInstitution.search = ''
        metaFieldOfStudy.page = 1
        metaFieldOfStudy.perpage = 10
        metaFieldOfStudy.search = ''
        return this.setState({ 
          visibleAdd: false,
          startYear: null,
          endYear: null,
          isYear: false,
        })
      }
      if(type === 'edit'){
        metaInstitution.page = 1
        metaInstitution.perpage = 10
        metaInstitution.search = ''
        metaFieldOfStudy.page = 1
        metaFieldOfStudy.perpage = 10
        metaFieldOfStudy.search = ''
        return this.setState({ 
          dataEducation: null,
          visibleEdit: false,
          startYear: null,
          endYear: null,
          isYear: false,
        })
      }
    }
  
    onSearchInstitution = (value) => {
      const { metaInstitution } = this.state;
      const { actionGetInstitution } = this.props;
      metaInstitution.page = 1
      metaInstitution.perpage = 10
      metaInstitution.search = value
      return actionGetInstitution(metaInstitution)
    }
  
    onFocusInstitution = () => {
      const { metaInstitution } = this.state;
      const { actionGetInstitution } = this.props;
      return actionGetInstitution(metaInstitution)
    }
  
    onSearchFieldOfStudy = (value) => {
      const { metaFieldOfStudy } = this.state;
      const { actionGetFieldOfStudy } = this.props;
      metaFieldOfStudy.page = 1
      metaFieldOfStudy.perpage = 10
      metaFieldOfStudy.search = value
      return actionGetFieldOfStudy(metaFieldOfStudy)
    }
  
    onFocusFieldOfStudy = () => {
      const { metaFieldOfStudy } = this.state;
      const { actionGetFieldOfStudy } = this.props;
      return actionGetFieldOfStudy(metaFieldOfStudy)
    }
  
    onChangeStartYear = (date, dateString) => {
      const { endYear } = this.state
      this.setState({ startYear: dateString })
      if(endYear <= dateString){
        this.setState({ isYear: true })
      }else{
        this.setState({ isYear: false })
      }
    }
  
    onChangeEndYear = (date, dateString) => {
      const { startYear } = this.state
      this.setState({ endYear: dateString })
      if(dateString <= startYear){
        this.setState({ isYear: true })
      }else{
        this.setState({ isYear: false })
      }
    }
  
    handleDelete = (id) => {
      const { actionDelete, actionGetDetail, match: { params } } = this.props;
      confirm({
        title: 'Remove Data',
        okText: 'Yes',
        cancelText: 'No',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure want to remove this data?',
        onOk() {
          return actionDelete(id, () => {
            message.success('Data deleted successfully')
            return actionGetDetail(params.id)
          }, (err) => message.error(err))
        },
      });
    }
  
    onSubmitAdd = () => {
      const { actionAdd, actionGetDetail, match: { params } } = this.props;
      this.setState({ submitLoading: true })
      this.formRef.current.validateFields().then(values => {
        values.candidateId = Number(params.id)
        values.startYear = values.startYear ? moment(values.startYear).format('YYYY') : null
        values.endYear = values.endYear ? moment(values.endYear).format('YYYY') : null
        return actionAdd(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleAdd: false }, () => {
              message.success('Data created successfully')
              actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      })
    }
  
    onSubmitEdit = () => {
      const { dataEducation } = this.state;
      const { actionUpdate, actionGetDetail, match: { params } } = this.props;
      this.setState({ submitLoading: true })
      this.formRef.current.validateFields().then(values => {
        values.id = dataEducation.id
        values.candidateId = Number(params.id)
        values.startYear = values.startYear ? moment(values.startYear).format('YYYY') : null
        values.endYear = values.endYear ? moment(values.endYear).format('YYYY') : null
        return actionUpdate(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleEdit: false }, () => {
              message.success('Data updated successfully')
              return actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      })
    }

    render() {
      // const { visibleAdd, visibleEdit } = this.state;
      const { getDetailLecturers: { data, loading }, getDetailSupervisingLecturers } = this.props;
      
      if(loading || getDetailSupervisingLecturers.loading){
        return <Skeleton avatar />
      }
    
      return (
        <React.Fragment>
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Breadcrumb>
                    <Breadcrumb.Item><SolutionOutlined/>Lecturer</Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <Link className="link" to="supervising-lecturer">
                        Lecturer List
                    </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Lecturer Detail</Breadcrumb.Item>
                </Breadcrumb>
            </Col>

            <Col span={24}>
                <PageHeader className="site-page-header" title='Lecturer Detail'/>
            </Col>
            <Col span={7}>
                <Card style={{border: 'none'}}>
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Space direction="horizontal">
                                <Avatar size={100} src={data.photoUrl ? data.photoUrl : svg }/>
                                <Space direction="vertical" size="small">
                                        <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.firstName} {data.lastName}</Text>
                                        <Text type="secondary">{data.applicantNumber}</Text>
                                </Space>
                            </Space>
                        </Col>

                        <Col span={24}>
                          <Menu rowKey="supervisingLecturer"id={data.id} />
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={17}>
              <Card style={{border: 'none'}}
                title={<Text strong>Supervising Lecturer</Text>}
                >
                  {
                    getDetailSupervisingLecturers.data.length > 0 ?
                    getDetailSupervisingLecturers.data.map(item =>
                      <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Space direction="vertical">
                                    <Text type='secondary' style={{color: '#3274D7'}}>{item.fullName ? item.fullName : 'N/A'}</Text>
                                    <Text type='secondary'>{item.institutionName ? item.institutionName :'N/A'} - {item.fieldOfStudyName ? item.fieldOfStudyName :'N/A'}</Text>
                                </Space>
                            </Col>
                            <Col span={6}>
                                <Space direction="vertical">
                                    <Text type='secondary' style={{color: '#3274D7'}}>{item.programNumber ? item.programNumber : 'N/A'}</Text>
                                    <Text type='secondary'>{item.title ? item.title : 'N/A'}</Text>
                                </Space>
                            </Col>
                                <Col span={6}>
                                    <Space direction="vertical">
                                      <Text type='secondary'>{item.programCategory ? item.programCategory : 'N/A'}</Text>
                                      <Text type='secondary'>{item.partnerName ? item.partnerName : 'N/A'}</Text>
                                    </Space>
                                </Col>
                                <Col span={6}>
                                    <Space direction="vertical">
                                      <Text type='secondary'>{item.cityName ? item.cityName : 'N/A'}</Text>
                                      <Text type='secondary'>{item.startDate ? moment(item.startDate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'} - {item.endDate ? moment(item.endDate, 'DD/MMM/YYYY').format('DD MMM YYYY') : 'N/A'}</Text>
                                    </Space>
                                </Col>
                             </Row>
                        <Divider />
                    </React.Fragment>
                      ) : <Empty/>
                  }
              </Card>
            </Col>
        </Row>
    </React.Fragment>
      )
    }
    componentWillUnmount() {
        const { unmountGetDetail, unmountGetDetailSupervising } = this.props;
        unmountGetDetail()
        unmountGetDetailSupervising()
      }
    }
    
    const mapStateToProps = (state) => ({
      getDetailLecturers  : state.lecturers.detail,
      getDetailSupervisingLecturers : state.lecturers.detailSupervisingLecturers
    })
    
    const mapDispatchToProps = {
      actionGetDetail         : detailLecturers,
      unmountGetDetail        : unmountDetailLecturers,
      actionGetSupervisingLecturers : detailSupervisingLecturers,
      unmountGetDetailSupervising : unmountDetailSupervisingLecturers
    }

export default connect(mapStateToProps, mapDispatchToProps)(LecturerDetailSupervisingLecturer)