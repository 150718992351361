import React, { Component } from 'react';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../../../../components/Loading';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Typography, Descriptions} from 'antd';
import moment from 'moment';
const {Text } = Typography;

export default class ContentParticipant extends Component {

  render() {
    // const { dataParticipant, openModalProfile, formRef, isResult, isMethod, handleChangeAssessmentResult, handleChangeMethod, showModalNotes, getApplicantNotes } = this.props;
    const { detailParticipant} = this.props;
    if(detailParticipant.loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
        <Col span={24}>
            <Text style={{fontSize : 17, color: '#242424'}}>Personal Information</Text>
        </Col>

        <Col span={24}>
        <Descriptions layout='vertical' column={1} size="small">
        <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
        <Avatar size={80} src={detailParticipant.data.photoUrl ? detailParticipant.data.photoUrl : null } icon={!detailParticipant.data.photoUrl ? <UserOutlined /> : null} />
        </Descriptions.Item>
        </Descriptions>
        </Col>

        <Col span={24}>
          <Descriptions layout='vertical' column={2} size="small">
          <Descriptions.Item label={<Text type="secondary">Full Name</Text>}>
              {detailParticipant.data.firstName && detailParticipant.data.lastName ? `${detailParticipant.data.firstName} ${detailParticipant.data.lastName}` : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {detailParticipant.data.nationality ? detailParticipant.data.nationality : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                    {detailParticipant.data.gender ? detailParticipant.data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Marital Status</Text>}>
                      {
                        detailParticipant.data.marital ?
                        <Text>
                          {detailParticipant.data.marital === 'BELUM_KAWIN' && 'Belum Kawin'}
                          {detailParticipant.data.marital === 'KAWIN' && 'Kawin'}
                          {detailParticipant.data.marital === 'CERAI_HIDUP' && 'Cerai Hidup'}
                          {detailParticipant.data.marital === 'CERAI_MATI' && 'Cerai Mati'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                    {detailParticipant.data.birthplace ? detailParticipant.data.birthplace : 'N/A'}, {detailParticipant.data.birthdate ? moment(detailParticipant.data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {
                        detailParticipant.data.religion ?
                        <Text>
                          {detailParticipant.data.religion === 'ISLAM' && 'Islam'}
                          {detailParticipant.data.religion === 'CATHOLIC' && 'Catholic'}
                          {detailParticipant.data.religion === 'PROTESTAN' && 'Protestan'}
                          {detailParticipant.data.religion === 'BUDDHA' && 'Buddha'}
                          {detailParticipant.data.religion === 'HINDU' && 'Hindu'}
                          {detailParticipant.data.religion === 'OTHERS' && 'Others'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Height</Text>}>
                      {detailParticipant.data.height ? `${detailParticipant.data.height} cm` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">ID Card Number (KTP)</Text>}>
                      {detailParticipant.data.nik ? detailParticipant.data.nik : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Weight</Text>}>
                      {detailParticipant.data.weight ? `${detailParticipant.data.weight} kg` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Blood Type</Text>}>
                      {detailParticipant.data.bloodType ? detailParticipant.data.bloodType : 'N/A'}
                    </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={24}>
                    <Text style={{fontSize : 17, color: '#242424'}}>Education Information </Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    
                    <Descriptions.Item label={<Text type="secondary">Origin University</Text>}>
                      {detailParticipant.data.institutionName ? detailParticipant.data.institutionName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Semester</Text>}>
                      {detailParticipant.data.semester ? detailParticipant.data.semester : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Field Of Study</Text>}>
                      {detailParticipant.data.fieldOfStudyName ? detailParticipant.data.fieldOfStudyName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">SKS Taken</Text>}>
                      {detailParticipant.data.sksTaken ? detailParticipant.data.sksTaken : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">NIM</Text>}>
                    {detailParticipant.data.nim ? detailParticipant.data.nim : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">GPA</Text>}>
                    {detailParticipant.data.gpa ? detailParticipant.data.gpa : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
        </Row>

        

        
      </React.Fragment>
    )
  }
}