import React, { Component } from 'react';
// import ReactGA from "react-ga";
import logo from '../../assets/img/Logo-MBKM.png';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { setLogin } from '../../redux/actions/auth/authAction';
import { Form, Input, Button, Row, Col, Card, Typography, message } from 'antd';
const { Text } = Typography;

// if(process.env.REACT_APP_ENV === "production"){
//   ReactGA.initialize("G-YQK491RBH8");
// }

class Login extends Component {
  
  componentDidMount(){
    // if(process.env.REACT_APP_ENV === "production"){
    //   ReactGA.pageview(this.props.path, [], 'Login');
    // }
    this.nameInput.focus();
  }

  onFinish =  (values) => {
    const { actionSetLogin } = this.props;
    return actionSetLogin(values, null, (err) => message.error(err))
  }

  render() {
    const { authReducer: { loading } } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Login</title>
        </Helmet>
        <Row align="middle" justify="center" style={{ height: '100vh' }}>
          <Col>
            <Card className="card-login">
              <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={this.onFinish}>
                <Row gutter={[16, 8]}>

                  <Col span={24}>
                    <img src={logo} alt="" style={{ width: '40%' }} />
                  </Col>

                  <Col span={24}>
                    <Text type="secondary">Welcome Back</Text>
                  </Col>

                  <Col span={24}>
                    <Text style={{ color: '#848484', fontSize: 25 }}>Login to your account</Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="username" rules={[ { type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail' } ]}>
                      <Input type="email" placeholder="Email" size="large" ref={(input) => { this.nameInput = input; }} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password' }]}>
                      <Input.Password type="password" placeholder="Password" size="large"/>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item>
                      <Button 
                        block
                        size="large" 
                        type="primary" 
                        htmlType="submit" 
                        loading={loading} 
                        className="btn-radius"
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer
})

const mapDispatchToProps = {
  actionSetLogin : setLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
